/* eslint-disable */  
/// <reference path="./custom.d.ts" />
// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import url from 'url'
import * as isomorphicFetch from 'isomorphic-fetch'
import { Configuration } from './configuration'

const BASE_PATH = "http://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccountBean
 */
export interface AccountBean {
    /**
     * 
     * @type {number}
     * @memberof AccountBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    role: AccountBean.RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountBean
     */
    agencyPageUrlEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountBean
     */
    pageUrlEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountBean
     */
    activeSites?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    lastLoginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    lastTrafficDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    status?: AccountBean.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AccountBean
     */
    last30DaysRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBean
     */
    percentToPrevious30Days?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountBean
     */
    accessRights: AccountBean.AccessRightsEnum;
}

/**
 * @export
 * @namespace AccountBean
 */
export namespace AccountBean {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Customer = <any> 'customer',
        TeamOwner = <any> 'team_owner',
        TeamAdmin = <any> 'team_admin',
        TeamMember = <any> 'team_member'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACTIVE = <any> 'ACTIVE',
        HOLD = <any> 'HOLD'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccessRightsEnum {
        FULL = <any> 'FULL',
        READONLY = <any> 'READ_ONLY'
    }
}
/**
 * 
 * @export
 * @interface AdRequestsBean
 */
export interface AdRequestsBean {
    /**
     * 
     * @type {number}
     * @memberof AdRequestsBean
     */
    requests?: number;
}
/**
 * 
 * @export
 * @interface AdTypeChartData
 */
export interface AdTypeChartData {
    /**
     * 
     * @type {Array<AdTypeHistoryBean>}
     * @memberof AdTypeChartData
     */
    adTypeStats: Array<AdTypeHistoryBean>;
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof AdTypeChartData
     */
    axisX: Array<AxisLabel>;
}
/**
 * 
 * @export
 * @interface AdTypeHistoryBean
 */
export interface AdTypeHistoryBean {
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    bidsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    winsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    impressionsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    revenueHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    eCPMHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    bidPriceHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    winRateHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {string}
     * @memberof AdTypeHistoryBean
     */
    mediaType: string;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof AdTypeHistoryBean
     */
    ecpmhistory?: Array<StatValueSnapshot>;
}
/**
 * 
 * @export
 * @interface AdUnitDictBean
 */
export interface AdUnitDictBean {
    /**
     * 
     * @type {number}
     * @memberof AdUnitDictBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdUnitDictBean
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof AdUnitDictBean
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitDictBean
     */
    siteName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitDictBean
     */
    adUnitCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitDictBean
     */
    adUnitName?: string;
}
/**
 * 
 * @export
 * @interface AdUnitLayoutBean
 */
export interface AdUnitLayoutBean {
    /**
     * 
     * @type {string}
     * @memberof AdUnitLayoutBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitLayoutBean
     */
    description: string;
    /**
     * 
     * @type {UIXRect}
     * @memberof AdUnitLayoutBean
     */
    rect: UIXRect;
}
/**
 * 
 * @export
 * @interface AdUnitPreview
 */
export interface AdUnitPreview {
    /**
     * 
     * @type {string}
     * @memberof AdUnitPreview
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitPreview
     */
    help: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitPreview
     */
    placementCode: string;
}
/**
 * 
 * @export
 * @interface AddBidderRequest
 */
export interface AddBidderRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBidderRequest
     */
    key: AddBidderRequest.KeyEnum;
}

/**
 * @export
 * @namespace AddBidderRequest
 */
export namespace AddBidderRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum KeyEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
}
/**
 * 
 * @export
 * @interface AdminBean
 */
export interface AdminBean {
    /**
     * 
     * @type {number}
     * @memberof AdminBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBean
     */
    adminType: AdminBean.AdminTypeEnum;
}

/**
 * @export
 * @namespace AdminBean
 */
export namespace AdminBean {
    /**
     * @export
     * @enum {string}
     */
    export enum AdminTypeEnum {
        PRIMARY = <any> 'ADMIN_PRIMARY',
        SECONDARY = <any> 'ADMIN_SECONDARY'
    }
}
/**
 * 
 * @export
 * @interface AgencyBean
 */
export interface AgencyBean {
    /**
     * 
     * @type {number}
     * @memberof AgencyBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    stringId: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    status: AgencyBean.StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AgencyBean
     */
    advancedDashboardFilters: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgencyBean
     */
    pageUrlEnabled: boolean;
    /**
     * 
     * @type {ManagerBean}
     * @memberof AgencyBean
     */
    manager?: ManagerBean;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    agencyDomains?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    publisherDomains?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBean
     */
    endpointDomains?: string;
}

/**
 * @export
 * @namespace AgencyBean
 */
export namespace AgencyBean {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACTIVE = <any> 'ACTIVE',
        HOLD = <any> 'HOLD'
    }
}
/**
 * 
 * @export
 * @interface AlertBean
 */
export interface AlertBean {
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    alertDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    alertConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    alertConfigName?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    granularity?: AlertBean.GranularityEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    siteName?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    adUnitDictName?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    previousDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    previousValue?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertBean
     */
    currentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    currentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    threshold?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertBean
     */
    deviation?: number;
}

/**
 * @export
 * @namespace AlertBean
 */
export namespace AlertBean {
    /**
     * @export
     * @enum {string}
     */
    export enum GranularityEnum {
        Total = <any> 'Total',
        PerSite = <any> 'PerSite',
        PerAdUnit = <any> 'PerAdUnit'
    }
}
/**
 * 
 * @export
 * @interface AlertConfigBean
 */
export interface AlertConfigBean {
    /**
     * 
     * @type {number}
     * @memberof AlertConfigBean
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigBean
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigBean
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigBean
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigBean
     */
    alertType?: AlertConfigBean.AlertTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigBean
     */
    granularity?: AlertConfigBean.GranularityEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof AlertConfigBean
     */
    siteIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertConfigBean
     */
    siteNames?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AlertConfigBean
     */
    adUnitIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertConfigBean
     */
    adUnitNames?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigBean
     */
    thresholdValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigBean
     */
    thresholdPercent?: number;
}

/**
 * @export
 * @namespace AlertConfigBean
 */
export namespace AlertConfigBean {
    /**
     * @export
     * @enum {string}
     */
    export enum AlertTypeEnum {
        PrebidRevenueDropped = <any> 'PrebidRevenueDropped',
        AdRequestEcpmDropped = <any> 'AdRequestEcpmDropped',
        TimeOutRateChanges = <any> 'TimeOutRateChanges'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum GranularityEnum {
        Total = <any> 'Total',
        PerSite = <any> 'PerSite',
        PerAdUnit = <any> 'PerAdUnit'
    }
}
/**
 * 
 * @export
 * @interface AlertNotificationBean
 */
export interface AlertNotificationBean {
    /**
     * 
     * @type {number}
     * @memberof AlertNotificationBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AlertNotificationBean
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AlertNotificationBean
     */
    alertConfigIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertNotificationBean
     */
    alertConfigNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AlertNotificationBean
     */
    emails?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertNotificationBean
     */
    lastSentDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertNotificationBean
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
    /**
     * 
     * @type {ApplicationContext}
     * @memberof ApplicationContext
     */
    parent?: ApplicationContext;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    displayName?: string;
    /**
     * 
     * @type {AutowireCapableBeanFactory}
     * @memberof ApplicationContext
     */
    autowireCapableBeanFactory?: AutowireCapableBeanFactory;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    startupDate?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    applicationName?: string;
    /**
     * 
     * @type {Environment}
     * @memberof ApplicationContext
     */
    environment?: Environment;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    beanDefinitionCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationContext
     */
    beanDefinitionNames?: Array<string>;
    /**
     * 
     * @type {BeanFactory}
     * @memberof ApplicationContext
     */
    parentBeanFactory?: BeanFactory;
    /**
     * 
     * @type {ApplicationContextClassLoader}
     * @memberof ApplicationContext
     */
    classLoader?: ApplicationContextClassLoader;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoader
 */
export interface ApplicationContextClassLoader {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoader
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoader
     */
    registeredAsParallelCapable?: boolean;
    /**
     * 
     * @type {ApplicationContextClassLoaderParent}
     * @memberof ApplicationContextClassLoader
     */
    parent?: ApplicationContextClassLoaderParent;
    /**
     * 
     * @type {ApplicationContextClassLoaderParentUnnamedModule}
     * @memberof ApplicationContextClassLoader
     */
    unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
    /**
     * 
     * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>}
     * @memberof ApplicationContextClassLoader
     */
    definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoader
     */
    defaultAssertionStatus?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoaderParent
 */
export interface ApplicationContextClassLoaderParent {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParent
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParent
     */
    registeredAsParallelCapable?: boolean;
    /**
     * 
     * @type {ApplicationContextClassLoaderParentUnnamedModule}
     * @memberof ApplicationContextClassLoaderParent
     */
    unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
    /**
     * 
     * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>}
     * @memberof ApplicationContextClassLoaderParent
     */
    definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParent
     */
    defaultAssertionStatus?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModule
 */
export interface ApplicationContextClassLoaderParentUnnamedModule {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    name?: string;
    /**
     * 
     * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
    /**
     * 
     * @type {ApplicationContextClassLoaderParentUnnamedModuleDescriptor}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    descriptor?: ApplicationContextClassLoaderParentUnnamedModuleDescriptor;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    named?: boolean;
    /**
     * 
     * @type {Array<any>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    annotations?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    declaredAnnotations?: Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    packages?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof ApplicationContextClassLoaderParentUnnamedModule
     */
    layer?: any;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
     */
    registeredAsParallelCapable?: boolean;
    /**
     * 
     * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
     */
    definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
     */
    defaultAssertionStatus?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    name?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    annotations?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    declaredAnnotations?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    sealed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    specificationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    specificationVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    specificationVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    implementationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    implementationVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackages
     */
    implementationVendor?: string;
}
/**
 * 
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
     */
    automatic?: boolean;
}
/**
 * 
 * @export
 * @interface AutowireCapableBeanFactory
 */
export interface AutowireCapableBeanFactory {
}
/**
 * 
 * @export
 * @interface AxisLabel
 */
export interface AxisLabel {
    /**
     * 
     * @type {string}
     * @memberof AxisLabel
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof AxisLabel
     */
    formattedValue: string;
    /**
     * 
     * @type {number}
     * @memberof AxisLabel
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof AxisLabel
     */
    position: number;
}
/**
 * 
 * @export
 * @interface BasicReportQuery
 */
export interface BasicReportQuery {
    /**
     * 
     * @type {Date}
     * @memberof BasicReportQuery
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasicReportQuery
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof BasicReportQuery
     */
    timeGrouping: BasicReportQuery.TimeGroupingEnum;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByAccountId?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupBySite?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByBidder?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByAdUnit?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByMediaType?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByDevice?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCountry?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByUtmSource?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByUtmMedium?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByUtmCampaign?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByUtmTerm?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByUtmContent?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByHbSource?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByPageUrl?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByRefererUrl?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByPlatform?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByBrowser?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByVersion?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByTcfCompliant?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupBySamplingRate?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupBySiteName?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByWorkspace?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCrmId?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCustomField1?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCustomField2?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCustomField3?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCustomField4?: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BasicReportQuery
     */
    groupByCustomField5?: UIXValueBoolean;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BasicReportQuery
     */
    sites?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    workspaces?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    crmIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    bidders?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BasicReportQuery
     */
    adUnits?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    mediaTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    sizes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    versions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    tcfCompliants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    samplingRates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    accountIds?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BasicReportQuery
     */
    devices?: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BasicReportQuery
     */
    countries?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    customFilter1?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    customFilter2?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    customFilter3?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    customFilter4?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    customFilter5?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicReportQuery
     */
    metrics?: Array<BasicReportQuery.MetricsEnum>;
}

/**
 * @export
 * @namespace BasicReportQuery
 */
export namespace BasicReportQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum TimeGroupingEnum {
        Total = <any> 'Total',
        Daily = <any> 'Daily',
        Hourly = <any> 'Hourly'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MetricsEnum {
        SiteRequests = <any> 'siteRequests',
        AdUnitRequests = <any> 'adUnitRequests',
        Impressions = <any> 'impressions',
        Bids = <any> 'bids',
        Wins = <any> 'wins',
        Timeouts = <any> 'timeouts',
        Auctions = <any> 'auctions',
        BidRequests = <any> 'bidRequests',
        UsdRevenue = <any> 'usdRevenue',
        UsdUnaffectedRevenue = <any> 'usdUnaffectedRevenue',
        UsdEcpm = <any> 'usdEcpm',
        UsdBidPrice = <any> 'usdBidPrice',
        EurRevenue = <any> 'eurRevenue',
        EurUnaffectedRevenue = <any> 'eurUnaffectedRevenue',
        EurEcpm = <any> 'eurEcpm',
        EurBidPrice = <any> 'eurBidPrice',
        Revenue = <any> 'revenue',
        ECPM = <any> 'eCPM',
        BidPrice = <any> 'bidPrice',
        BidRate = <any> 'bidRate',
        TimeoutRate = <any> 'timeoutRate',
        WinRate = <any> 'winRate',
        AuctionWins = <any> 'auctionWins',
        WinCpmUsd = <any> 'winCpmUsd',
        WinCpmEur = <any> 'winCpmEur',
        SecondWinCpmUsd = <any> 'secondWinCpmUsd',
        SecondWinCpmEur = <any> 'secondWinCpmEur'
    }
}
/**
 * 
 * @export
 * @interface BeanFactory
 */
export interface BeanFactory {
}
/**
 * 
 * @export
 * @interface BidderEditForm
 */
export interface BidderEditForm {
    /**
     * 
     * @type {number}
     * @memberof BidderEditForm
     */
    id: number;
    /**
     * 
     * @type {UIBidderConfigSet}
     * @memberof BidderEditForm
     */
    formSet: UIBidderConfigSet;
    /**
     * 
     * @type {string}
     * @memberof BidderEditForm
     */
    bidderKey?: BidderEditForm.BidderKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof BidderEditForm
     */
    bidderName: string;
    /**
     * 
     * @type {string}
     * @memberof BidderEditForm
     */
    status: BidderEditForm.StatusEnum;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof BidderEditForm
     */
    approved: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueString}
     * @memberof BidderEditForm
     */
    comments: UIXValueString;
    /**
     * 
     * @type {number}
     * @memberof BidderEditForm
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof BidderEditForm
     */
    bundleId?: number;
    /**
     * 
     * @type {number}
     * @memberof BidderEditForm
     */
    teamId?: number;
    /**
     * 
     * @type {string}
     * @memberof BidderEditForm
     */
    adUnitId?: string;
}

/**
 * @export
 * @namespace BidderEditForm
 */
export namespace BidderEditForm {
    /**
     * @export
     * @enum {string}
     */
    export enum BidderKeyEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Registering = <any> 'registering',
        Approved = <any> 'approved',
        Paused = <any> 'paused'
    }
}
/**
 * 
 * @export
 * @interface BidderEditResponse
 */
export interface BidderEditResponse {
    /**
     * 
     * @type {number}
     * @memberof BidderEditResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BidderEditResponse
     */
    bidderId?: number;
    /**
     * 
     * @type {BidderEditForm}
     * @memberof BidderEditResponse
     */
    form: BidderEditForm;
}
/**
 * 
 * @export
 * @interface BidderHistoryBean
 */
export interface BidderHistoryBean {
    /**
     * 
     * @type {string}
     * @memberof BidderHistoryBean
     */
    bidderName: string;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    usdRevenueHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    eurRevenueHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    usdBidPriceHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    eurBidPriceHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    usdEcpmHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    eurEcpmHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    bidRateHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    winRateHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    timeoutsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof BidderHistoryBean
     */
    timeoutRateHistory: Array<StatValueSnapshot>;
}
/**
 * 
 * @export
 * @interface BidderInfo
 */
export interface BidderInfo {
    /**
     * 
     * @type {string}
     * @memberof BidderInfo
     */
    key: BidderInfo.KeyEnum;
    /**
     * 
     * @type {string}
     * @memberof BidderInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BidderInfo
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BidderInfo
     */
    eCPM: string;
    /**
     * 
     * @type {string}
     * @memberof BidderInfo
     */
    ecpm?: string;
}

/**
 * @export
 * @namespace BidderInfo
 */
export namespace BidderInfo {
    /**
     * @export
     * @enum {string}
     */
    export enum KeyEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
}
/**
 * 
 * @export
 * @interface BidderList
 */
export interface BidderList {
    /**
     * 
     * @type {Array<ItemResponseBidderRowBean>}
     * @memberof BidderList
     */
    data: Array<ItemResponseBidderRowBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof BidderList
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BidderList
     */
    permissions: { [key: string]: boolean; };
    /**
     * 
     * @type {BidderListQuery}
     * @memberof BidderList
     */
    query: BidderListQuery;
    /**
     * 
     * @type {Array<BidderInfo>}
     * @memberof BidderList
     */
    available: Array<BidderInfo>;
}
/**
 * 
 * @export
 * @interface BidderListQuery
 */
export interface BidderListQuery {
    /**
     * 
     * @type {number}
     * @memberof BidderListQuery
     */
    page: number;
    /**
     * 
     * @type {string}
     * @memberof BidderListQuery
     */
    search: string;
}
/**
 * 
 * @export
 * @interface BidderMetricsQuery
 */
export interface BidderMetricsQuery {
    /**
     * 
     * @type {Date}
     * @memberof BidderMetricsQuery
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BidderMetricsQuery
     */
    endDate: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    bidders?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BidderMetricsQuery
     */
    sites?: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BidderMetricsQuery
     */
    adUnits?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    mediaTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    sizes?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BidderMetricsQuery
     */
    devices?: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof BidderMetricsQuery
     */
    countries?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    browsers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    utmSources?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    utmMediums?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    utmCampaigns?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    versions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    tcfCompliants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidderMetricsQuery
     */
    samplingRates?: Array<string>;
}
/**
 * 
 * @export
 * @interface BidderRowBean
 */
export interface BidderRowBean {
    /**
     * 
     * @type {number}
     * @memberof BidderRowBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BidderRowBean
     */
    name: string;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof BidderRowBean
     */
    status: BootstrapStatusLabel;
    /**
     * 
     * @type {string}
     * @memberof BidderRowBean
     */
    key: BidderRowBean.KeyEnum;
}

/**
 * @export
 * @namespace BidderRowBean
 */
export namespace BidderRowBean {
    /**
     * @export
     * @enum {string}
     */
    export enum KeyEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
}
/**
 * 
 * @export
 * @interface BiddersMetricByTime
 */
export interface BiddersMetricByTime {
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof BiddersMetricByTime
     */
    axisX: Array<AxisLabel>;
    /**
     * 
     * @type {Array<BidderHistoryBean>}
     * @memberof BiddersMetricByTime
     */
    biddersData: Array<BidderHistoryBean>;
}
/**
 * 
 * @export
 * @interface BootstrapStatusLabel
 */
export interface BootstrapStatusLabel {
    /**
     * 
     * @type {string}
     * @memberof BootstrapStatusLabel
     */
    status: BootstrapStatusLabel.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BootstrapStatusLabel
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof BootstrapStatusLabel
     */
    tooltip: string;
    /**
     * 
     * @type {number}
     * @memberof BootstrapStatusLabel
     */
    progress: number;
}

/**
 * @export
 * @namespace BootstrapStatusLabel
 */
export namespace BootstrapStatusLabel {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface BundleBean
 */
export interface BundleBean {
    /**
     * 
     * @type {number}
     * @memberof BundleBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BundleBean
     */
    bundleKey?: string;
    /**
     * 
     * @type {string}
     * @memberof BundleBean
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BundleBean
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof BundleBean
     */
    description?: string;
    /**
     * 
     * @type {SiteHealth}
     * @memberof BundleBean
     */
    siteHealth?: SiteHealth;
    /**
     * 
     * @type {string}
     * @memberof BundleBean
     */
    lastStatsTime?: string;
}
/**
 * 
 * @export
 * @interface BundleBidderInfo
 */
export interface BundleBidderInfo {
    /**
     * 
     * @type {string}
     * @memberof BundleBidderInfo
     */
    id: BundleBidderInfo.IdEnum;
    /**
     * 
     * @type {string}
     * @memberof BundleBidderInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BundleBidderInfo
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof BundleBidderInfo
     */
    hasConfiguredAdUnit: boolean;
}

/**
 * @export
 * @namespace BundleBidderInfo
 */
export namespace BundleBidderInfo {
    /**
     * @export
     * @enum {string}
     */
    export enum IdEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
}
/**
 * 
 * @export
 * @interface BundleCreateForm
 */
export interface BundleCreateForm {
    /**
     * 
     * @type {number}
     * @memberof BundleCreateForm
     */
    teamId?: number;
    /**
     * 
     * @type {number}
     * @memberof BundleCreateForm
     */
    siteId?: number;
    /**
     * 
     * @type {UIXValueString}
     * @memberof BundleCreateForm
     */
    name: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof BundleCreateForm
     */
    domain: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof BundleCreateForm
     */
    tags: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof BundleCreateForm
     */
    description: UIXValueString;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof BundleCreateForm
     */
    pageLayoutId: UIXValueInteger;
    /**
     * 
     * @type {string}
     * @memberof BundleCreateForm
     */
    prebidVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof BundleCreateForm
     */
    hasApprovedBidders: boolean;
    /**
     * 
     * @type {number}
     * @memberof BundleCreateForm
     */
    bundleId?: number;
    /**
     * 
     * @type {string}
     * @memberof BundleCreateForm
     */
    adUnitId?: string;
}
/**
 * 
 * @export
 * @interface BundleCreateResponse
 */
export interface BundleCreateResponse {
    /**
     * 
     * @type {BundleCreateForm}
     * @memberof BundleCreateResponse
     */
    form?: BundleCreateForm;
    /**
     * 
     * @type {number}
     * @memberof BundleCreateResponse
     */
    bundleId?: number;
}
/**
 * 
 * @export
 * @interface BundleCreateResponseAdmin
 */
export interface BundleCreateResponseAdmin {
    /**
     * 
     * @type {BundleCreateForm}
     * @memberof BundleCreateResponseAdmin
     */
    form?: BundleCreateForm;
    /**
     * 
     * @type {number}
     * @memberof BundleCreateResponseAdmin
     */
    bundleId?: number;
    /**
     * 
     * @type {string}
     * @memberof BundleCreateResponseAdmin
     */
    bundleKey?: string;
}
/**
 * 
 * @export
 * @interface BundleDraftRowBean
 */
export interface BundleDraftRowBean {
    /**
     * 
     * @type {number}
     * @memberof BundleDraftRowBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BundleDraftRowBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BundleDraftRowBean
     */
    domain: string;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof BundleDraftRowBean
     */
    progress: BootstrapStatusLabel;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof BundleDraftRowBean
     */
    progressStatus: BootstrapStatusLabel;
    /**
     * 
     * @type {SiteHealth}
     * @memberof BundleDraftRowBean
     */
    siteHealth: SiteHealth;
}
/**
 * 
 * @export
 * @interface BundleEditResponse
 */
export interface BundleEditResponse {
    /**
     * 
     * @type {number}
     * @memberof BundleEditResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BundleEditResponse
     */
    bundleId?: number;
    /**
     * 
     * @type {number}
     * @memberof BundleEditResponse
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof BundleEditResponse
     */
    siteId?: number;
    /**
     * 
     * @type {UIBundle}
     * @memberof BundleEditResponse
     */
    form: UIBundle;
    /**
     * 
     * @type {Array<BundleBidderInfo>}
     * @memberof BundleEditResponse
     */
    allBidders: Array<BundleBidderInfo>;
}
/**
 * 
 * @export
 * @interface BundleInitCreateForm
 */
export interface BundleInitCreateForm {
    /**
     * 
     * @type {BundleCreateForm}
     * @memberof BundleInitCreateForm
     */
    form: BundleCreateForm;
    /**
     * 
     * @type {Array<PageLayoutBean>}
     * @memberof BundleInitCreateForm
     */
    layouts: Array<PageLayoutBean>;
    /**
     * 
     * @type {number}
     * @memberof BundleInitCreateForm
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof BundleInitCreateForm
     */
    bundleId?: number;
    /**
     * 
     * @type {number}
     * @memberof BundleInitCreateForm
     */
    teamId?: number;
    /**
     * 
     * @type {string}
     * @memberof BundleInitCreateForm
     */
    adUnitId?: string;
}
/**
 * 
 * @export
 * @interface BundleList
 */
export interface BundleList {
    /**
     * 
     * @type {Array<ItemResponseBundleRowBean>}
     * @memberof BundleList
     */
    data: Array<ItemResponseBundleRowBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof BundleList
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BundleList
     */
    permissions: { [key: string]: boolean; };
    /**
     * 
     * @type {BundleListQuery}
     * @memberof BundleList
     */
    query: BundleListQuery;
    /**
     * 
     * @type {number}
     * @memberof BundleList
     */
    bundleLimitBalance: number;
    /**
     * 
     * @type {number}
     * @memberof BundleList
     */
    bundleLimitUsage: number;
    /**
     * 
     * @type {Statistics}
     * @memberof BundleList
     */
    statistics: Statistics;
    /**
     * 
     * @type {Array<NamedFilter>}
     * @memberof BundleList
     */
    namedFilters: Array<NamedFilter>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof BundleList
     */
    availableTags: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof BundleList
     */
    statisticsTitle: string;
    /**
     * 
     * @type {string}
     * @memberof BundleList
     */
    statisticsSubtitle: string;
    /**
     * 
     * @type {string}
     * @memberof BundleList
     */
    listTitle: string;
    /**
     * 
     * @type {string}
     * @memberof BundleList
     */
    listSubtitle: string;
    /**
     * 
     * @type {boolean}
     * @memberof BundleList
     */
    showFilterReset: boolean;
}
/**
 * 
 * @export
 * @interface BundleListQuery
 */
export interface BundleListQuery {
    /**
     * 
     * @type {string}
     * @memberof BundleListQuery
     */
    period: BundleListQuery.PeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof BundleListQuery
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof BundleListQuery
     */
    to: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BundleListQuery
     */
    showValue: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof BundleListQuery
     */
    page: number;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof BundleListQuery
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof BundleListQuery
     */
    search: string;
    /**
     * 
     * @type {number}
     * @memberof BundleListQuery
     */
    namedFilter?: number;
    /**
     * 
     * @type {string}
     * @memberof BundleListQuery
     */
    newNamedFilter?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BundleListQuery
     */
    archive: boolean;
}

/**
 * @export
 * @namespace BundleListQuery
 */
export namespace BundleListQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodEnum {
        Last30Days = <any> 'last30Days',
        Last7Days = <any> 'last7Days',
        Day = <any> 'day',
        Year = <any> 'year',
        Realtime = <any> 'realtime',
        Custom = <any> 'custom'
    }
}
/**
 * 
 * @export
 * @interface BundleRowBean
 */
export interface BundleRowBean {
    /**
     * 
     * @type {number}
     * @memberof BundleRowBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BundleRowBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BundleRowBean
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof BundleRowBean
     */
    published: boolean;
    /**
     * 
     * @type {SmallStat}
     * @memberof BundleRowBean
     */
    requestsStat: SmallStat;
    /**
     * 
     * @type {SmallStat}
     * @memberof BundleRowBean
     */
    revenueStat: SmallStat;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof BundleRowBean
     */
    status: BootstrapStatusLabel;
}
/**
 * 
 * @export
 * @interface BundleTrafficHealth
 */
export interface BundleTrafficHealth {
    /**
     * 
     * @type {number}
     * @memberof BundleTrafficHealth
     */
    activeBundlesNum: number;
    /**
     * 
     * @type {number}
     * @memberof BundleTrafficHealth
     */
    bundlesNum: number;
    /**
     * 
     * @type {string}
     * @memberof BundleTrafficHealth
     */
    activeBundlesPercentFormatted: string;
    /**
     * 
     * @type {string}
     * @memberof BundleTrafficHealth
     */
    bundlesHealthState: BundleTrafficHealth.BundlesHealthStateEnum;
}

/**
 * @export
 * @namespace BundleTrafficHealth
 */
export namespace BundleTrafficHealth {
    /**
     * @export
     * @enum {string}
     */
    export enum BundlesHealthStateEnum {
        Ok = <any> 'ok',
        Normal = <any> 'normal',
        Bad = <any> 'bad',
        NotStarted = <any> 'notStarted'
    }
}
/**
 * 
 * @export
 * @interface ChangePasswordBean
 */
export interface ChangePasswordBean {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBean
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBean
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface ClientBlockBean
 */
export interface ClientBlockBean {
    /**
     * 
     * @type {string}
     * @memberof ClientBlockBean
     */
    cancelReason?: ClientBlockBean.CancelReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientBlockBean
     */
    cancelComments?: string;
}

/**
 * @export
 * @namespace ClientBlockBean
 */
export namespace ClientBlockBean {
    /**
     * @export
     * @enum {string}
     */
    export enum CancelReasonEnum {
        HIGHPRICES = <any> 'HIGH_PRICES',
        INSUFFICIENTFUNCTIONALITY = <any> 'INSUFFICIENT_FUNCTIONALITY',
        BADUI = <any> 'BAD_UI',
        LOWCALCULATIONACCURACY = <any> 'LOW_CALCULATION_ACCURACY',
        LONGLOAD = <any> 'LONG_LOAD',
        DIFFICULTSETUP = <any> 'DIFFICULT_SETUP',
        NOTWHATISNEEDED = <any> 'NOT_WHAT_IS_NEEDED',
        OTHER = <any> 'OTHER'
    }
}
/**
 * 
 * @export
 * @interface ContactSupportForm
 */
export interface ContactSupportForm {
    /**
     * 
     * @type {string}
     * @memberof ContactSupportForm
     */
    issueType: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportForm
     */
    description: string;
}
/**
 * 
 * @export
 * @interface CustomFilterBean
 */
export interface CustomFilterBean {
    /**
     * 
     * @type {number}
     * @memberof CustomFilterBean
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CustomFilterBean
     */
    fieldId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterBean
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomFilterBean
     */
    dashboardFilter: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterBean
     */
    adapterConfigField: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {Array<CustomFilterValueBean>}
     * @memberof CustomFilterBean
     */
    customFilterValues?: Array<CustomFilterValueBean>;
}
/**
 * 
 * @export
 * @interface CustomFilterValueBean
 */
export interface CustomFilterValueBean {
    /**
     * 
     * @type {number}
     * @memberof CustomFilterValueBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterValueBean
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterValueBean
     */
    value: string;
}
/**
 * 
 * @export
 * @interface CustomFiltersBean
 */
export interface CustomFiltersBean {
    /**
     * 
     * @type {Array<CustomFilterBean>}
     * @memberof CustomFiltersBean
     */
    customFilters: Array<CustomFilterBean>;
}
/**
 * 
 * @export
 * @interface CustomLogoBody
 */
export interface CustomLogoBody {
    /**
     * 
     * @type {Blob}
     * @memberof CustomLogoBody
     */
    logo: Blob;
}
/**
 * 
 * @export
 * @interface CustomerBean
 */
export interface CustomerBean {
    /**
     * 
     * @type {number}
     * @memberof CustomerBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerBean
     */
    emailIsConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    emailConfirmationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    cardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    lastCard4Digits?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerBean
     */
    inTrial: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    billingProblem?: CustomerBean.BillingProblemEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    billingProblemDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    billingStartDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerBean
     */
    paidUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerBean
     */
    failedPayUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerBean
     */
    lastTrafficHoursAgo?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    lastLoginDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerBean
     */
    logins?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    billingPlan?: CustomerBean.BillingPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    cancelReason?: CustomerBean.CancelReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerBean
     */
    cancelComments?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerBean
     */
    customPaymentEnabled: boolean;
}

/**
 * @export
 * @namespace CustomerBean
 */
export namespace CustomerBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BillingProblemEnum {
        NoRegisteredCard = <any> 'NoRegisteredCard',
        UnexpectedError = <any> 'UnexpectedError',
        CardDeclined = <any> 'CardDeclined',
        AdminBlock = <any> 'AdminBlock',
        DailyImprsExceeded = <any> 'DailyImprsExceeded',
        ClientBlock = <any> 'ClientBlock'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BillingPlanEnum {
        FIXEDDAYIMPRS = <any> 'FIXED_DAY_IMPRS',
        FIXEDDAYIMPRS20 = <any> 'FIXED_DAY_IMPRS_20',
        CPM = <any> 'CPM',
        CUSTOM = <any> 'CUSTOM'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CancelReasonEnum {
        HIGHPRICES = <any> 'HIGH_PRICES',
        INSUFFICIENTFUNCTIONALITY = <any> 'INSUFFICIENT_FUNCTIONALITY',
        BADUI = <any> 'BAD_UI',
        LOWCALCULATIONACCURACY = <any> 'LOW_CALCULATION_ACCURACY',
        LONGLOAD = <any> 'LONG_LOAD',
        DIFFICULTSETUP = <any> 'DIFFICULT_SETUP',
        NOTWHATISNEEDED = <any> 'NOT_WHAT_IS_NEEDED',
        OTHER = <any> 'OTHER'
    }
}
/**
 * 
 * @export
 * @interface CustomersSearchQueryBean
 */
export interface CustomersSearchQueryBean {
    /**
     * 
     * @type {string}
     * @memberof CustomersSearchQueryBean
     */
    customerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomersSearchQueryBean
     */
    billingProblem?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomersSearchQueryBean
     */
    billingPlan?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomersSearchQueryBean
     */
    defaultAgency?: boolean;
}
/**
 * 
 * @export
 * @interface CustomersStatBean
 */
export interface CustomersStatBean {
    /**
     * 
     * @type {Date}
     * @memberof CustomersStatBean
     */
    eventDate: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomersStatBean
     */
    registered: number;
    /**
     * 
     * @type {number}
     * @memberof CustomersStatBean
     */
    confirmed: number;
    /**
     * 
     * @type {number}
     * @memberof CustomersStatBean
     */
    billingStarted: number;
}
/**
 * 
 * @export
 * @interface CustomersStatQuery
 */
export interface CustomersStatQuery {
    /**
     * 
     * @type {Date}
     * @memberof CustomersStatQuery
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomersStatQuery
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface DashboardBean
 */
export interface DashboardBean {
    /**
     * 
     * @type {boolean}
     * @memberof DashboardBean
     */
    partnerInstallation?: boolean;
    /**
     * 
     * @type {Statistics}
     * @memberof DashboardBean
     */
    statistics: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof DashboardBean
     */
    previousPeriodStatistics: Statistics;
}
/**
 * 
 * @export
 * @interface DashboardQuery
 */
export interface DashboardQuery {
    /**
     * 
     * @type {Date}
     * @memberof DashboardQuery
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardQuery
     */
    endDate: Date;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof DashboardQuery
     */
    sites?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    bidders?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof DashboardQuery
     */
    adUnits?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    mediaTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    sizes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    browsers?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof DashboardQuery
     */
    devices?: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof DashboardQuery
     */
    countries?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    versions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    tcfCompliants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    samplingRates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    customFilter1?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    customFilter2?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    customFilter3?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    customFilter4?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardQuery
     */
    customFilter5?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DashboardQuery
     */
    sumMetric?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardQuery
     */
    dashboardCurrency?: string;
}
/**
 * 
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    defaultProfiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    activeProfiles?: Array<string>;
}
/**
 * 
 * @export
 * @interface FieldChartQuery
 */
export interface FieldChartQuery {
    /**
     * 
     * @type {Date}
     * @memberof FieldChartQuery
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof FieldChartQuery
     */
    endDate: Date;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof FieldChartQuery
     */
    sites?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    bidders?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof FieldChartQuery
     */
    adUnits?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    mediaTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    sizes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    browsers?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof FieldChartQuery
     */
    devices?: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof FieldChartQuery
     */
    countries?: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    versions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    tcfCompliants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    samplingRates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    customFilter1?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    customFilter2?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    customFilter3?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    customFilter4?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    customFilter5?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FieldChartQuery
     */
    sumMetric?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldChartQuery
     */
    dashboardCurrency?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldChartQuery
     */
    metrics?: Array<string>;
}
/**
 * 
 * @export
 * @interface FilterBean
 */
export interface FilterBean {
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    value: string;
}
/**
 * 
 * @export
 * @interface FilterRegistration
 */
export interface FilterRegistration {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterRegistration
     */
    urlPatternMappings?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterRegistration
     */
    servletNameMappings?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FilterRegistration
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRegistration
     */
    className?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FilterRegistration
     */
    initParameters?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface FilterSiteBean
 */
export interface FilterSiteBean {
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterSiteBean
     */
    siteIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FilterSiteBean
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSiteBean
     */
    label: string;
}
/**
 * 
 * @export
 * @interface FunnelChartData
 */
export interface FunnelChartData {
    /**
     * 
     * @type {Array<FunnelChartDataBean>}
     * @memberof FunnelChartData
     */
    dataBeans: Array<FunnelChartDataBean>;
}
/**
 * 
 * @export
 * @interface FunnelChartDataBean
 */
export interface FunnelChartDataBean {
    /**
     * 
     * @type {string}
     * @memberof FunnelChartDataBean
     */
    parameterName: string;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof FunnelChartDataBean
     */
    parameterStats: Array<OverviewStatisticsBean>;
}
/**
 * 
 * @export
 * @interface HealthMonitorBean
 */
export interface HealthMonitorBean {
    /**
     * 
     * @type {BundleTrafficHealth}
     * @memberof HealthMonitorBean
     */
    bundleTrafficHealth: BundleTrafficHealth;
}
/**
 * 
 * @export
 * @interface HttpStatusCode
 */
export interface HttpStatusCode {
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    error?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    is5xxServerError?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    is4xxClientError?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    is3xxRedirection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    is2xxSuccessful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    is1xxInformational?: boolean;
}
/**
 * 
 * @export
 * @interface IncrementalRevenueChartData
 */
export interface IncrementalRevenueChartData {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncrementalRevenueChartData
     */
    labels: Array<string>;
    /**
     * 
     * @type {Array<YValuesDouble>}
     * @memberof IncrementalRevenueChartData
     */
    usdValues: Array<YValuesDouble>;
    /**
     * 
     * @type {Array<YValuesDouble>}
     * @memberof IncrementalRevenueChartData
     */
    eurValues: Array<YValuesDouble>;
}
/**
 * 
 * @export
 * @interface ItemResponseAccountBean
 */
export interface ItemResponseAccountBean {
    /**
     * 
     * @type {AccountBean}
     * @memberof ItemResponseAccountBean
     */
    data: AccountBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAccountBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAdUnitDictBean
 */
export interface ItemResponseAdUnitDictBean {
    /**
     * 
     * @type {AdUnitDictBean}
     * @memberof ItemResponseAdUnitDictBean
     */
    data: AdUnitDictBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAdUnitDictBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAdminBean
 */
export interface ItemResponseAdminBean {
    /**
     * 
     * @type {AdminBean}
     * @memberof ItemResponseAdminBean
     */
    data: AdminBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAdminBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAgencyBean
 */
export interface ItemResponseAgencyBean {
    /**
     * 
     * @type {AgencyBean}
     * @memberof ItemResponseAgencyBean
     */
    data: AgencyBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAgencyBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAlertBean
 */
export interface ItemResponseAlertBean {
    /**
     * 
     * @type {AlertBean}
     * @memberof ItemResponseAlertBean
     */
    data: AlertBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAlertBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAlertConfigBean
 */
export interface ItemResponseAlertConfigBean {
    /**
     * 
     * @type {AlertConfigBean}
     * @memberof ItemResponseAlertConfigBean
     */
    data: AlertConfigBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAlertConfigBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseAlertNotificationBean
 */
export interface ItemResponseAlertNotificationBean {
    /**
     * 
     * @type {AlertNotificationBean}
     * @memberof ItemResponseAlertNotificationBean
     */
    data: AlertNotificationBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAlertNotificationBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseBidderRowBean
 */
export interface ItemResponseBidderRowBean {
    /**
     * 
     * @type {BidderRowBean}
     * @memberof ItemResponseBidderRowBean
     */
    data: BidderRowBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseBidderRowBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseBundleBean
 */
export interface ItemResponseBundleBean {
    /**
     * 
     * @type {BundleBean}
     * @memberof ItemResponseBundleBean
     */
    data: BundleBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseBundleBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseBundleDraftRowBean
 */
export interface ItemResponseBundleDraftRowBean {
    /**
     * 
     * @type {BundleDraftRowBean}
     * @memberof ItemResponseBundleDraftRowBean
     */
    data: BundleDraftRowBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseBundleDraftRowBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseBundleRowBean
 */
export interface ItemResponseBundleRowBean {
    /**
     * 
     * @type {BundleRowBean}
     * @memberof ItemResponseBundleRowBean
     */
    data: BundleRowBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseBundleRowBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseCustomFilterBean
 */
export interface ItemResponseCustomFilterBean {
    /**
     * 
     * @type {CustomFilterBean}
     * @memberof ItemResponseCustomFilterBean
     */
    data: CustomFilterBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseCustomFilterBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseCustomerBean
 */
export interface ItemResponseCustomerBean {
    /**
     * 
     * @type {CustomerBean}
     * @memberof ItemResponseCustomerBean
     */
    data: CustomerBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseCustomerBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseCustomersStatBean
 */
export interface ItemResponseCustomersStatBean {
    /**
     * 
     * @type {CustomersStatBean}
     * @memberof ItemResponseCustomersStatBean
     */
    data: CustomersStatBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseCustomersStatBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseManagerBean
 */
export interface ItemResponseManagerBean {
    /**
     * 
     * @type {ManagerBean}
     * @memberof ItemResponseManagerBean
     */
    data: ManagerBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseManagerBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseString
 */
export interface ItemResponseString {
    /**
     * 
     * @type {string}
     * @memberof ItemResponseString
     */
    data: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseString
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseTeamDetailsBean
 */
export interface ItemResponseTeamDetailsBean {
    /**
     * 
     * @type {TeamDetailsBean}
     * @memberof ItemResponseTeamDetailsBean
     */
    data: TeamDetailsBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseTeamDetailsBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseTeamRowBean
 */
export interface ItemResponseTeamRowBean {
    /**
     * 
     * @type {TeamRowBean}
     * @memberof ItemResponseTeamRowBean
     */
    data: TeamRowBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseTeamRowBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseTransactionBean
 */
export interface ItemResponseTransactionBean {
    /**
     * 
     * @type {TransactionBean}
     * @memberof ItemResponseTransactionBean
     */
    data: TransactionBean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseTransactionBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ItemResponseUIBundleView
 */
export interface ItemResponseUIBundleView {
    /**
     * 
     * @type {UIBundleView}
     * @memberof ItemResponseUIBundleView
     */
    data: UIBundleView;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseUIBundleView
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface JsonReport
 */
export interface JsonReport {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonReport
     */
    colNames?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof JsonReport
     */
    lines?: Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface JspConfigDescriptor
 */
export interface JspConfigDescriptor {
    /**
     * 
     * @type {Array<JspPropertyGroupDescriptor>}
     * @memberof JspConfigDescriptor
     */
    jspPropertyGroups?: Array<JspPropertyGroupDescriptor>;
    /**
     * 
     * @type {Array<TaglibDescriptor>}
     * @memberof JspConfigDescriptor
     */
    taglibs?: Array<TaglibDescriptor>;
}
/**
 * 
 * @export
 * @interface JspPropertyGroupDescriptor
 */
export interface JspPropertyGroupDescriptor {
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    buffer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JspPropertyGroupDescriptor
     */
    includeCodas?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    pageEncoding?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    elIgnored?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    isXml?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JspPropertyGroupDescriptor
     */
    includePreludes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    defaultContentType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JspPropertyGroupDescriptor
     */
    urlPatterns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    trimDirectiveWhitespaces?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    errorOnUndeclaredNamespace?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    errorOnELNotFound?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    scriptingInvalid?: string;
    /**
     * 
     * @type {string}
     * @memberof JspPropertyGroupDescriptor
     */
    deferredSyntaxAllowedAsLiteral?: string;
}
/**
 * 
 * @export
 * @interface LabelValueBean
 */
export interface LabelValueBean {
    /**
     * 
     * @type {string}
     * @memberof LabelValueBean
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof LabelValueBean
     */
    value: string;
}
/**
 * 
 * @export
 * @interface ListPagination
 */
export interface ListPagination {
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    startPage: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    lastPage: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof ListPagination
     */
    currentPage: number;
}
/**
 * 
 * @export
 * @interface ListRequest
 */
export interface ListRequest {
    /**
     * 
     * @type {string}
     * @memberof ListRequest
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof ListRequest
     */
    page?: number;
    /**
     * 
     * @type {string}
     * @memberof ListRequest
     */
    sortField?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRequest
     */
    sortDirection?: ListRequest.SortDirectionEnum;
}

/**
 * @export
 * @namespace ListRequest
 */
export namespace ListRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum SortDirectionEnum {
        Desc = <any> 'desc',
        Asc = <any> 'asc'
    }
}
/**
 * 
 * @export
 * @interface ListResponseAccountBean
 */
export interface ListResponseAccountBean {
    /**
     * 
     * @type {Array<ItemResponseAccountBean>}
     * @memberof ListResponseAccountBean
     */
    data: Array<ItemResponseAccountBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAccountBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAccountBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAdUnitDictBean
 */
export interface ListResponseAdUnitDictBean {
    /**
     * 
     * @type {Array<ItemResponseAdUnitDictBean>}
     * @memberof ListResponseAdUnitDictBean
     */
    data: Array<ItemResponseAdUnitDictBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAdUnitDictBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAdUnitDictBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAdminBean
 */
export interface ListResponseAdminBean {
    /**
     * 
     * @type {Array<ItemResponseAdminBean>}
     * @memberof ListResponseAdminBean
     */
    data: Array<ItemResponseAdminBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAdminBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAdminBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAgencyBean
 */
export interface ListResponseAgencyBean {
    /**
     * 
     * @type {Array<ItemResponseAgencyBean>}
     * @memberof ListResponseAgencyBean
     */
    data: Array<ItemResponseAgencyBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAgencyBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAgencyBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAlertBean
 */
export interface ListResponseAlertBean {
    /**
     * 
     * @type {Array<ItemResponseAlertBean>}
     * @memberof ListResponseAlertBean
     */
    data: Array<ItemResponseAlertBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAlertBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAlertBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAlertConfigBean
 */
export interface ListResponseAlertConfigBean {
    /**
     * 
     * @type {Array<ItemResponseAlertConfigBean>}
     * @memberof ListResponseAlertConfigBean
     */
    data: Array<ItemResponseAlertConfigBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAlertConfigBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAlertConfigBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseAlertNotificationBean
 */
export interface ListResponseAlertNotificationBean {
    /**
     * 
     * @type {Array<ItemResponseAlertNotificationBean>}
     * @memberof ListResponseAlertNotificationBean
     */
    data: Array<ItemResponseAlertNotificationBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseAlertNotificationBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseAlertNotificationBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseBundleBean
 */
export interface ListResponseBundleBean {
    /**
     * 
     * @type {Array<ItemResponseBundleBean>}
     * @memberof ListResponseBundleBean
     */
    data: Array<ItemResponseBundleBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseBundleBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseBundleBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseCustomFilterBean
 */
export interface ListResponseCustomFilterBean {
    /**
     * 
     * @type {Array<ItemResponseCustomFilterBean>}
     * @memberof ListResponseCustomFilterBean
     */
    data: Array<ItemResponseCustomFilterBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseCustomFilterBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseCustomFilterBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseCustomerBean
 */
export interface ListResponseCustomerBean {
    /**
     * 
     * @type {Array<ItemResponseCustomerBean>}
     * @memberof ListResponseCustomerBean
     */
    data: Array<ItemResponseCustomerBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseCustomerBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseCustomerBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseCustomersStatBean
 */
export interface ListResponseCustomersStatBean {
    /**
     * 
     * @type {Array<ItemResponseCustomersStatBean>}
     * @memberof ListResponseCustomersStatBean
     */
    data: Array<ItemResponseCustomersStatBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseCustomersStatBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseCustomersStatBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseManagerBean
 */
export interface ListResponseManagerBean {
    /**
     * 
     * @type {Array<ItemResponseManagerBean>}
     * @memberof ListResponseManagerBean
     */
    data: Array<ItemResponseManagerBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseManagerBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseManagerBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseTeamRowBean
 */
export interface ListResponseTeamRowBean {
    /**
     * 
     * @type {Array<ItemResponseTeamRowBean>}
     * @memberof ListResponseTeamRowBean
     */
    data: Array<ItemResponseTeamRowBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseTeamRowBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseTeamRowBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ListResponseTransactionBean
 */
export interface ListResponseTransactionBean {
    /**
     * 
     * @type {Array<ItemResponseTransactionBean>}
     * @memberof ListResponseTransactionBean
     */
    data: Array<ItemResponseTransactionBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof ListResponseTransactionBean
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ListResponseTransactionBean
     */
    permissions: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ManagerBean
 */
export interface ManagerBean {
    /**
     * 
     * @type {number}
     * @memberof ManagerBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    managerRole: ManagerBean.ManagerRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerBean
     */
    main?: boolean;
}

/**
 * @export
 * @namespace ManagerBean
 */
export namespace ManagerBean {
    /**
     * @export
     * @enum {string}
     */
    export enum ManagerRoleEnum {
        PRIMARY = <any> 'MANAGER_PRIMARY',
        SECONDARY = <any> 'MANAGER_SECONDARY'
    }
}
/**
 * 
 * @export
 * @interface ManagerInfoBean
 */
export interface ManagerInfoBean {
    /**
     * 
     * @type {number}
     * @memberof ManagerInfoBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    managerRole: ManagerInfoBean.ManagerRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerInfoBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerInfoBean
     */
    main?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerInfoBean
     */
    isLoggedIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerInfoBean
     */
    pageUrlEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManagerInfoBean
     */
    logo?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ManagerInfoBean
     */
    settings?: { [key: string]: any; };
}

/**
 * @export
 * @namespace ManagerInfoBean
 */
export namespace ManagerInfoBean {
    /**
     * @export
     * @enum {string}
     */
    export enum ManagerRoleEnum {
        PRIMARY = <any> 'MANAGER_PRIMARY',
        SECONDARY = <any> 'MANAGER_SECONDARY'
    }
}
/**
 * 
 * @export
 * @interface NamedFilter
 */
export interface NamedFilter {
    /**
     * 
     * @type {number}
     * @memberof NamedFilter
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof NamedFilter
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof NamedFilter
     */
    favorite: boolean;
    /**
     * 
     * @type {number}
     * @memberof NamedFilter
     */
    useRate: number;
}
/**
 * 
 * @export
 * @interface OverviewChartsData
 */
export interface OverviewChartsData {
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricByBidders: Array<OverviewStatisticsBean>;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricBySites: Array<OverviewStatisticsBean>;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricByAdUnits: Array<OverviewStatisticsBean>;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricByDevices: Array<OverviewStatisticsBean>;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricByAdSizes: Array<OverviewStatisticsBean>;
    /**
     * 
     * @type {Array<OverviewStatisticsBean>}
     * @memberof OverviewChartsData
     */
    metricByCountries: Array<OverviewStatisticsBean>;
}
/**
 * 
 * @export
 * @interface OverviewStatisticsBean
 */
export interface OverviewStatisticsBean {
    /**
     * 
     * @type {string}
     * @memberof OverviewStatisticsBean
     */
    parameterName: string;
    /**
     * 
     * @type {number}
     * @memberof OverviewStatisticsBean
     */
    parameterValue: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewStatisticsBean
     */
    usdParameterValue: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewStatisticsBean
     */
    eurParameterValue: number;
}
/**
 * 
 * @export
 * @interface PageLabelValueBean
 */
export interface PageLabelValueBean {
    /**
     * 
     * @type {number}
     * @memberof PageLabelValueBean
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLabelValueBean
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLabelValueBean
     */
    size?: number;
    /**
     * 
     * @type {Array<LabelValueBean>}
     * @memberof PageLabelValueBean
     */
    content?: Array<LabelValueBean>;
    /**
     * 
     * @type {number}
     * @memberof PageLabelValueBean
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLabelValueBean
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLabelValueBean
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageLabelValueBean
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLabelValueBean
     */
    last?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLabelValueBean
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLabelValueBean
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageLayoutBean
 */
export interface PageLayoutBean {
    /**
     * 
     * @type {number}
     * @memberof PageLayoutBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PageLayoutBean
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PageLayoutBean
     */
    ratio: number;
    /**
     * 
     * @type {string}
     * @memberof PageLayoutBean
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PageLayoutBean
     */
    smallDetails: string;
    /**
     * 
     * @type {Array<AdUnitLayoutBean>}
     * @memberof PageLayoutBean
     */
    adUnits: Array<AdUnitLayoutBean>;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    size?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    sort?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentCreateBean
 */
export interface PaymentCreateBean {
    /**
     * 
     * @type {string}
     * @memberof PaymentCreateBean
     */
    accountEmail?: string;
    /**
     * 
     * @type {Date}
     * @memberof PaymentCreateBean
     */
    intervalStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentCreateBean
     */
    intervalEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreateBean
     */
    requests?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreateBean
     */
    impressions?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreateBean
     */
    charge?: number;
}
/**
 * 
 * @export
 * @interface PaymentsHistoryBean
 */
export interface PaymentsHistoryBean {
    /**
     * 
     * @type {string}
     * @memberof PaymentsHistoryBean
     */
    billingDate: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsHistoryBean
     */
    trialEndDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsHistoryBean
     */
    billingActivated: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentsHistoryBean
     */
    billingProblem?: PaymentsHistoryBean.BillingProblemEnum;
    /**
     * 
     * @type {Array<TransactionBean>}
     * @memberof PaymentsHistoryBean
     */
    transactions: Array<TransactionBean>;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsHistoryBean
     */
    inTrial?: boolean;
}

/**
 * @export
 * @namespace PaymentsHistoryBean
 */
export namespace PaymentsHistoryBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BillingProblemEnum {
        NoRegisteredCard = <any> 'NoRegisteredCard',
        UnexpectedError = <any> 'UnexpectedError',
        CardDeclined = <any> 'CardDeclined',
        AdminBlock = <any> 'AdminBlock',
        DailyImprsExceeded = <any> 'DailyImprsExceeded',
        ClientBlock = <any> 'ClientBlock'
    }
}
/**
 * 
 * @export
 * @interface PeriodQuery
 */
export interface PeriodQuery {
    /**
     * 
     * @type {Date}
     * @memberof PeriodQuery
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PeriodQuery
     */
    endDate: Date;
}
/**
 * 
 * @export
 * @interface ProfileBean
 */
export interface ProfileBean {
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBean
     */
    emailIsConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    userCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    uiServerVersion: string;
    /**
     * 
     * @type {HealthMonitorBean}
     * @memberof ProfileBean
     */
    healthMonitorBean: HealthMonitorBean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBean
     */
    billingActivated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBean
     */
    inTrial: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBean
     */
    cardEntered: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBean
     */
    pageUrlEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    billingProblem?: ProfileBean.BillingProblemEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileBean
     */
    trialEndDate: string;
}

/**
 * @export
 * @namespace ProfileBean
 */
export namespace ProfileBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BillingProblemEnum {
        NoRegisteredCard = <any> 'NoRegisteredCard',
        UnexpectedError = <any> 'UnexpectedError',
        CardDeclined = <any> 'CardDeclined',
        AdminBlock = <any> 'AdminBlock',
        DailyImprsExceeded = <any> 'DailyImprsExceeded',
        ClientBlock = <any> 'ClientBlock'
    }
}
/**
 * 
 * @export
 * @interface ProfileForm
 */
export interface ProfileForm {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileForm
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileForm
     */
    emailIsConfirmed: boolean;
    /**
     * 
     * @type {UIXValueString}
     * @memberof ProfileForm
     */
    firstName: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof ProfileForm
     */
    lastName?: UIXValueString;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    phone?: string;
    /**
     * 
     * @type {UIXValueString}
     * @memberof ProfileForm
     */
    userCurrency: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof ProfileForm
     */
    password: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof ProfileForm
     */
    passwordConfirm: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileForm
     */
    cardEntered: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    cardTokenId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    cardLast4Digits?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    cardBrand?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileForm
     */
    billingProblem?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileForm
     */
    billingErrorMsg?: string;
}
/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {ProfileForm}
     * @memberof ProfileResponse
     */
    form?: ProfileForm;
}
/**
 * 
 * @export
 * @interface PublisherInfoBean
 */
export interface PublisherInfoBean {
    /**
     * 
     * @type {number}
     * @memberof PublisherInfoBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    role: PublisherInfoBean.RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublisherInfoBean
     */
    agencyPageUrlEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublisherInfoBean
     */
    pageUrlEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublisherInfoBean
     */
    activeSites?: number;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    lastLoginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    lastTrafficDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    status?: PublisherInfoBean.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PublisherInfoBean
     */
    last30DaysRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof PublisherInfoBean
     */
    percentToPrevious30Days?: number;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoBean
     */
    accessRights: PublisherInfoBean.AccessRightsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PublisherInfoBean
     */
    isLoggedIn?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublisherInfoBean
     */
    logo?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PublisherInfoBean
     */
    settings?: { [key: string]: any; };
}

/**
 * @export
 * @namespace PublisherInfoBean
 */
export namespace PublisherInfoBean {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Customer = <any> 'customer',
        TeamOwner = <any> 'team_owner',
        TeamAdmin = <any> 'team_admin',
        TeamMember = <any> 'team_member'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACTIVE = <any> 'ACTIVE',
        HOLD = <any> 'HOLD'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccessRightsEnum {
        FULL = <any> 'FULL',
        READONLY = <any> 'READ_ONLY'
    }
}
/**
 * 
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
    /**
     * 
     * @type {ApplicationContext}
     * @memberof RedirectView
     */
    applicationContext?: ApplicationContext;
    /**
     * 
     * @type {ServletContext}
     * @memberof RedirectView
     */
    servletContext?: ServletContext;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    requestContextAttribute?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RedirectView
     */
    staticAttributes?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposePathVariables?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposeContextBeansAsAttributes?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedirectView
     */
    exposedContextBeanNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    beanName?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    contextRelative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    http10Compatible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposeModelAttributes?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    encodingScheme?: string;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof RedirectView
     */
    statusCode?: HttpStatusCode;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    expandUriTemplateVariables?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    propagateQueryParams?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedirectView
     */
    hosts?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    redirectView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    propagateQueryProperties?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RedirectView
     */
    attributes?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RedirectView
     */
    attributesMap?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    attributesCSV?: string;
}
/**
 * 
 * @export
 * @interface RegistrationForm
 */
export interface RegistrationForm {
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    email: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    password: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    referralCode: UIXValueString;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof RegistrationForm
     */
    needHelp: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueEnumBillingPlan}
     * @memberof RegistrationForm
     */
    billingPlan: UIXValueEnumBillingPlan;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    workspace?: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    crmId?: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RegistrationForm
     */
    name?: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationForm
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface RegistrationResponse
 */
export interface RegistrationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationResponse
     */
    success?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationResponse
     */
    alreadyRegistered?: boolean;
    /**
     * 
     * @type {RegistrationForm}
     * @memberof RegistrationResponse
     */
    form?: RegistrationForm;
    /**
     * 
     * @type {string}
     * @memberof RegistrationResponse
     */
    domainName?: string;
}
/**
 * 
 * @export
 * @interface RegistrationResponseAdmin
 */
export interface RegistrationResponseAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationResponseAdmin
     */
    success?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationResponseAdmin
     */
    alreadyRegistered?: boolean;
    /**
     * 
     * @type {RegistrationForm}
     * @memberof RegistrationResponseAdmin
     */
    form?: RegistrationForm;
    /**
     * 
     * @type {number}
     * @memberof RegistrationResponseAdmin
     */
    accountId?: number;
}
/**
 * 
 * @export
 * @interface ReportFilter
 */
export interface ReportFilter {
    /**
     * 
     * @type {string}
     * @memberof ReportFilter
     */
    column: ReportFilter.ColumnEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportFilter
     */
    values: Array<string>;
}

/**
 * @export
 * @namespace ReportFilter
 */
export namespace ReportFilter {
    /**
     * @export
     * @enum {string}
     */
    export enum ColumnEnum {
        Time = <any> 'time',
        AccountId = <any> 'accountId',
        AccountEmail = <any> 'accountEmail',
        SiteId = <any> 'siteId',
        SiteName = <any> 'siteName',
        SiteKey = <any> 'siteKey',
        Workspace = <any> 'workspace',
        CrmId = <any> 'crmId',
        Day = <any> 'day',
        Hour = <any> 'hour',
        AdUnitCode = <any> 'adUnitCode',
        BidderId = <any> 'bidderId',
        Currency = <any> 'currency',
        MediaType = <any> 'mediaType',
        Size = <any> 'size',
        RequestedSizes = <any> 'requestedSizes',
        BidderResponseTimeBucket = <any> 'bidderResponseTimeBucket',
        Device = <any> 'device',
        Country = <any> 'country',
        UtmSource = <any> 'utmSource',
        UtmMedium = <any> 'utmMedium',
        UtmCampaign = <any> 'utmCampaign',
        UtmTerm = <any> 'utmTerm',
        UtmContent = <any> 'utmContent',
        HbSource = <any> 'hbSource',
        Version = <any> 'version',
        TcfCompliant = <any> 'tcfCompliant',
        SamplingRate = <any> 'samplingRate',
        CustomField1 = <any> 'customField1',
        CustomField2 = <any> 'customField2',
        CustomField3 = <any> 'customField3',
        CustomField4 = <any> 'customField4',
        CustomField5 = <any> 'customField5',
        PageUrl = <any> 'pageUrl',
        RefererUrl = <any> 'refererUrl',
        Platform = <any> 'platform',
        Browser = <any> 'browser',
        BidsInAuction = <any> 'bidsInAuction',
        SecondWinBidder = <any> 'secondWinBidder'
    }
}
/**
 * 
 * @export
 * @interface ReportQuery
 */
export interface ReportQuery {
    /**
     * 
     * @type {string}
     * @memberof ReportQuery
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReportQuery
     */
    endDate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportQuery
     */
    keys?: Array<ReportQuery.KeysEnum>;
    /**
     * 
     * @type {Array<ReportFilter>}
     * @memberof ReportQuery
     */
    filters?: Array<ReportFilter>;
    /**
     * 
     * @type {Array<ReportSort>}
     * @memberof ReportQuery
     */
    sorting?: Array<ReportSort>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportQuery
     */
    metrics?: Array<ReportQuery.MetricsEnum>;
}

/**
 * @export
 * @namespace ReportQuery
 */
export namespace ReportQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        Time = <any> 'time',
        AccountId = <any> 'accountId',
        AccountEmail = <any> 'accountEmail',
        SiteId = <any> 'siteId',
        SiteName = <any> 'siteName',
        SiteKey = <any> 'siteKey',
        Workspace = <any> 'workspace',
        CrmId = <any> 'crmId',
        Day = <any> 'day',
        Hour = <any> 'hour',
        AdUnitCode = <any> 'adUnitCode',
        BidderId = <any> 'bidderId',
        Currency = <any> 'currency',
        MediaType = <any> 'mediaType',
        Size = <any> 'size',
        RequestedSizes = <any> 'requestedSizes',
        BidderResponseTimeBucket = <any> 'bidderResponseTimeBucket',
        Device = <any> 'device',
        Country = <any> 'country',
        UtmSource = <any> 'utmSource',
        UtmMedium = <any> 'utmMedium',
        UtmCampaign = <any> 'utmCampaign',
        UtmTerm = <any> 'utmTerm',
        UtmContent = <any> 'utmContent',
        HbSource = <any> 'hbSource',
        Version = <any> 'version',
        TcfCompliant = <any> 'tcfCompliant',
        SamplingRate = <any> 'samplingRate',
        CustomField1 = <any> 'customField1',
        CustomField2 = <any> 'customField2',
        CustomField3 = <any> 'customField3',
        CustomField4 = <any> 'customField4',
        CustomField5 = <any> 'customField5',
        PageUrl = <any> 'pageUrl',
        RefererUrl = <any> 'refererUrl',
        Platform = <any> 'platform',
        Browser = <any> 'browser',
        BidsInAuction = <any> 'bidsInAuction',
        SecondWinBidder = <any> 'secondWinBidder'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MetricsEnum {
        SiteRequests = <any> 'siteRequests',
        AdUnitRequests = <any> 'adUnitRequests',
        Impressions = <any> 'impressions',
        Bids = <any> 'bids',
        Wins = <any> 'wins',
        Timeouts = <any> 'timeouts',
        Auctions = <any> 'auctions',
        BidRequests = <any> 'bidRequests',
        UsdRevenue = <any> 'usdRevenue',
        UsdUnaffectedRevenue = <any> 'usdUnaffectedRevenue',
        UsdEcpm = <any> 'usdEcpm',
        UsdBidPrice = <any> 'usdBidPrice',
        EurRevenue = <any> 'eurRevenue',
        EurUnaffectedRevenue = <any> 'eurUnaffectedRevenue',
        EurEcpm = <any> 'eurEcpm',
        EurBidPrice = <any> 'eurBidPrice',
        Revenue = <any> 'revenue',
        ECPM = <any> 'eCPM',
        BidPrice = <any> 'bidPrice',
        BidRate = <any> 'bidRate',
        TimeoutRate = <any> 'timeoutRate',
        WinRate = <any> 'winRate',
        AuctionWins = <any> 'auctionWins',
        WinCpmUsd = <any> 'winCpmUsd',
        WinCpmEur = <any> 'winCpmEur',
        SecondWinCpmUsd = <any> 'secondWinCpmUsd',
        SecondWinCpmEur = <any> 'secondWinCpmEur'
    }
}
/**
 * 
 * @export
 * @interface ReportRecords
 */
export interface ReportRecords {
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof ReportRecords
     */
    records?: Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface ReportSort
 */
export interface ReportSort {
    /**
     * 
     * @type {string}
     * @memberof ReportSort
     */
    column?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSort
     */
    order?: ReportSort.OrderEnum;
}

/**
 * @export
 * @namespace ReportSort
 */
export namespace ReportSort {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderEnum {
        Asc = <any> 'asc',
        Desc = <any> 'desc'
    }
}
/**
 * 
 * @export
 * @interface RequestPasswordResetBean
 */
export interface RequestPasswordResetBean {
    /**
     * 
     * @type {string}
     * @memberof RequestPasswordResetBean
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordBean
 */
export interface ResetPasswordBean {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordBean
     */
    resetToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordBean
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface RestorePasswordActionForm
 */
export interface RestorePasswordActionForm {
    /**
     * 
     * @type {string}
     * @memberof RestorePasswordActionForm
     */
    token?: string;
    /**
     * 
     * @type {UIXValueString}
     * @memberof RestorePasswordActionForm
     */
    password: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof RestorePasswordActionForm
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface RestorePasswordActionResponse
 */
export interface RestorePasswordActionResponse {
    /**
     * 
     * @type {string}
     * @memberof RestorePasswordActionResponse
     */
    result?: RestorePasswordActionResponse.ResultEnum;
}

/**
 * @export
 * @namespace RestorePasswordActionResponse
 */
export namespace RestorePasswordActionResponse {
    /**
     * @export
     * @enum {string}
     */
    export enum ResultEnum {
        UnknownToken = <any> 'unknownToken',
        ExpiredToken = <any> 'expiredToken',
        Success = <any> 'success',
        InvalidPassword = <any> 'invalidPassword'
    }
}
/**
 * 
 * @export
 * @interface RestorePasswordRequestForm
 */
export interface RestorePasswordRequestForm {
    /**
     * 
     * @type {UIXValueString}
     * @memberof RestorePasswordRequestForm
     */
    email: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof RestorePasswordRequestForm
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface RestorePasswordRequestResponse
 */
export interface RestorePasswordRequestResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RestorePasswordRequestResponse
     */
    success?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RestorePasswordRequestResponse
     */
    alreadyRegistered?: boolean;
    /**
     * 
     * @type {RestorePasswordRequestForm}
     * @memberof RestorePasswordRequestResponse
     */
    form?: RestorePasswordRequestForm;
}
/**
 * 
 * @export
 * @interface ServletContext
 */
export interface ServletContext {
    /**
     * 
     * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
     * @memberof ServletContext
     */
    classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
    /**
     * 
     * @type {number}
     * @memberof ServletContext
     */
    majorVersion?: number;
    /**
     * 
     * @type {number}
     * @memberof ServletContext
     */
    minorVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    contextPath?: string;
    /**
     * 
     * @type {any}
     * @memberof ServletContext
     */
    attributeNames?: any;
    /**
     * 
     * @type {any}
     * @memberof ServletContext
     */
    initParameterNames?: any;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServletContext
     */
    sessionTrackingModes?: Array<ServletContext.SessionTrackingModesEnum>;
    /**
     * 
     * @type {number}
     * @memberof ServletContext
     */
    sessionTimeout?: number;
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    servletContextName?: string;
    /**
     * 
     * @type {SessionCookieConfig}
     * @memberof ServletContext
     */
    sessionCookieConfig?: SessionCookieConfig;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServletContext
     */
    defaultSessionTrackingModes?: Array<ServletContext.DefaultSessionTrackingModesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServletContext
     */
    effectiveSessionTrackingModes?: Array<ServletContext.EffectiveSessionTrackingModesEnum>;
    /**
     * 
     * @type {{ [key: string]: FilterRegistration; }}
     * @memberof ServletContext
     */
    filterRegistrations?: { [key: string]: FilterRegistration; };
    /**
     * 
     * @type {JspConfigDescriptor}
     * @memberof ServletContext
     */
    jspConfigDescriptor?: JspConfigDescriptor;
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    virtualServerName?: string;
    /**
     * 
     * @type {number}
     * @memberof ServletContext
     */
    effectiveMajorVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    requestCharacterEncoding?: string;
    /**
     * 
     * @type {number}
     * @memberof ServletContext
     */
    effectiveMinorVersion?: number;
    /**
     * 
     * @type {{ [key: string]: ServletRegistration; }}
     * @memberof ServletContext
     */
    servletRegistrations?: { [key: string]: ServletRegistration; };
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    responseCharacterEncoding?: string;
    /**
     * 
     * @type {string}
     * @memberof ServletContext
     */
    serverInfo?: string;
}

/**
 * @export
 * @namespace ServletContext
 */
export namespace ServletContext {
    /**
     * @export
     * @enum {string}
     */
    export enum SessionTrackingModesEnum {
        COOKIE = <any> 'COOKIE',
        URL = <any> 'URL',
        SSL = <any> 'SSL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultSessionTrackingModesEnum {
        COOKIE = <any> 'COOKIE',
        URL = <any> 'URL',
        SSL = <any> 'SSL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EffectiveSessionTrackingModesEnum {
        COOKIE = <any> 'COOKIE',
        URL = <any> 'URL',
        SSL = <any> 'SSL'
    }
}
/**
 * 
 * @export
 * @interface ServletRegistration
 */
export interface ServletRegistration {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServletRegistration
     */
    mappings?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServletRegistration
     */
    runAsRole?: string;
    /**
     * 
     * @type {string}
     * @memberof ServletRegistration
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServletRegistration
     */
    className?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServletRegistration
     */
    initParameters?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface SessionCookieConfig
 */
export interface SessionCookieConfig {
    /**
     * 
     * @type {string}
     * @memberof SessionCookieConfig
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionCookieConfig
     */
    path?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SessionCookieConfig
     */
    attributes?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof SessionCookieConfig
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionCookieConfig
     */
    domain?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionCookieConfig
     */
    maxAge?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionCookieConfig
     */
    httpOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionCookieConfig
     */
    secure?: boolean;
}
/**
 * 
 * @export
 * @interface SiteBean
 */
export interface SiteBean {
    /**
     * 
     * @type {UIXValueString}
     * @memberof SiteBean
     */
    name: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof SiteBean
     */
    domain: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof SiteBean
     */
    description: UIXValueString;
    /**
     * 
     * @type {number}
     * @memberof SiteBean
     */
    id: number;
    /**
     * 
     * @type {SiteHealth}
     * @memberof SiteBean
     */
    siteHealth: SiteHealth;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    analyticSetupCode: string;
}
/**
 * 
 * @export
 * @interface SiteHealth
 */
export interface SiteHealth {
    /**
     * 
     * @type {number}
     * @memberof SiteHealth
     */
    trafficHoursAgo?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteHealth
     */
    activeHoursPart?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteHealth
     */
    healthState: SiteHealth.HealthStateEnum;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof SiteHealth
     */
    healthStatus: BootstrapStatusLabel;
}

/**
 * @export
 * @namespace SiteHealth
 */
export namespace SiteHealth {
    /**
     * @export
     * @enum {string}
     */
    export enum HealthStateEnum {
        Ok = <any> 'ok',
        Normal = <any> 'normal',
        Bad = <any> 'bad',
        NotStarted = <any> 'notStarted'
    }
}
/**
 * 
 * @export
 * @interface SitesList
 */
export interface SitesList {
    /**
     * 
     * @type {Array<ItemResponseBundleDraftRowBean>}
     * @memberof SitesList
     */
    data: Array<ItemResponseBundleDraftRowBean>;
    /**
     * 
     * @type {ListPagination}
     * @memberof SitesList
     */
    pagination: ListPagination;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof SitesList
     */
    permissions: { [key: string]: boolean; };
    /**
     * 
     * @type {SitesListQuery}
     * @memberof SitesList
     */
    query: SitesListQuery;
    /**
     * 
     * @type {number}
     * @memberof SitesList
     */
    bundleLimitBalance: number;
    /**
     * 
     * @type {number}
     * @memberof SitesList
     */
    bundleLimitUsage: number;
    /**
     * 
     * @type {string}
     * @memberof SitesList
     */
    listTitle: string;
    /**
     * 
     * @type {string}
     * @memberof SitesList
     */
    listSubtitle: string;
}
/**
 * 
 * @export
 * @interface SitesListQuery
 */
export interface SitesListQuery {
    /**
     * 
     * @type {number}
     * @memberof SitesListQuery
     */
    teamId?: number;
    /**
     * 
     * @type {number}
     * @memberof SitesListQuery
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof SitesListQuery
     */
    page: number;
    /**
     * 
     * @type {string}
     * @memberof SitesListQuery
     */
    search: string;
}
/**
 * 
 * @export
 * @interface SmallStat
 */
export interface SmallStat {
    /**
     * 
     * @type {Array<number>}
     * @memberof SmallStat
     */
    data: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SmallStat
     */
    status: SmallStat.StatusEnum;
}

/**
 * @export
 * @namespace SmallStat
 */
export namespace SmallStat {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface StatBlock
 */
export interface StatBlock {
    /**
     * 
     * @type {StatValue}
     * @memberof StatBlock
     */
    summary: StatValue;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof StatBlock
     */
    history: Array<StatValueSnapshot>;
    /**
     * 
     * @type {boolean}
     * @memberof StatBlock
     */
    realData: boolean;
    /**
     * 
     * @type {number}
     * @memberof StatBlock
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface StatFilters
 */
export interface StatFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    timeGrouping: Array<StatFilters.TimeGroupingEnum>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    sites: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterSiteBean>}
     * @memberof StatFilters
     */
    biddersWithSiteIds: Array<FilterSiteBean>;
    /**
     * 
     * @type {Array<FilterSiteBean>}
     * @memberof StatFilters
     */
    adUnitsWithSiteIds: Array<FilterSiteBean>;
    /**
     * 
     * @type {Array<FilterSiteBean>}
     * @memberof StatFilters
     */
    mediaTypesWithSiteIds: Array<FilterSiteBean>;
    /**
     * 
     * @type {Array<FilterSiteBean>}
     * @memberof StatFilters
     */
    sizesWithSiteIds: Array<FilterSiteBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    bidders: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    adUnits: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    mediaTypes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    sizes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    currencies: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    devices: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    countries: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    browsers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    utmSources: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    utmMediums: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    utmCampaigns: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    versions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    tcfCompliants: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    samplingRates: Array<FilterBean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    accountIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    workspaces?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatFilters
     */
    crmIds?: Array<string>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    auctionMetrics: Array<FilterBean>;
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof StatFilters
     */
    revenueMetrics: Array<FilterBean>;
    /**
     * 
     * @type {boolean}
     * @memberof StatFilters
     */
    usdOnly: boolean;
    /**
     * 
     * @type {Array<CustomFilterBean>}
     * @memberof StatFilters
     */
    customFilters?: Array<CustomFilterBean>;
}

/**
 * @export
 * @namespace StatFilters
 */
export namespace StatFilters {
    /**
     * @export
     * @enum {string}
     */
    export enum TimeGroupingEnum {
        Total = <any> 'Total',
        Daily = <any> 'Daily',
        Hourly = <any> 'Hourly'
    }
}
/**
 * 
 * @export
 * @interface StatValue
 */
export interface StatValue {
    /**
     * 
     * @type {number}
     * @memberof StatValue
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof StatValue
     */
    trendValue: number;
    /**
     * 
     * @type {string}
     * @memberof StatValue
     */
    trend: StatValue.TrendEnum;
    /**
     * 
     * @type {number}
     * @memberof StatValue
     */
    trendPercent: number;
    /**
     * 
     * @type {string}
     * @memberof StatValue
     */
    type: StatValue.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StatValue
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatValue
     */
    realData: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatValue
     */
    formattedTrendValue?: string;
    /**
     * 
     * @type {number}
     * @memberof StatValue
     */
    roundedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof StatValue
     */
    formattedValue?: string;
}

/**
 * @export
 * @namespace StatValue
 */
export namespace StatValue {
    /**
     * @export
     * @enum {string}
     */
    export enum TrendEnum {
        Up = <any> 'up',
        Down = <any> 'down',
        Same = <any> 'same'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Auctions = <any> 'auctions',
        Requests = <any> 'requests',
        BidRequests = <any> 'bidRequests',
        Bids = <any> 'bids',
        Wins = <any> 'wins',
        Impressions = <any> 'impressions',
        Timeouts = <any> 'timeouts',
        UsdECPM = <any> 'usdECPM',
        EurECPM = <any> 'eurECPM',
        UsdRevenue = <any> 'usdRevenue',
        EurRevenue = <any> 'eurRevenue',
        BidRate = <any> 'bidRate',
        WinRate = <any> 'winRate',
        UsdBidPrice = <any> 'usdBidPrice',
        EurBidPrice = <any> 'eurBidPrice',
        TimeoutRate = <any> 'timeoutRate'
    }
}
/**
 * 
 * @export
 * @interface StatValueSnapshot
 */
export interface StatValueSnapshot {
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    trendValue: number;
    /**
     * 
     * @type {string}
     * @memberof StatValueSnapshot
     */
    trend: StatValueSnapshot.TrendEnum;
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    trendPercent: number;
    /**
     * 
     * @type {string}
     * @memberof StatValueSnapshot
     */
    type: StatValueSnapshot.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatValueSnapshot
     */
    realData: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StatValueSnapshot
     */
    dateTime: Date;
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof StatValueSnapshot
     */
    formattedTrendValue?: string;
    /**
     * 
     * @type {number}
     * @memberof StatValueSnapshot
     */
    roundedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof StatValueSnapshot
     */
    formattedValue?: string;
}

/**
 * @export
 * @namespace StatValueSnapshot
 */
export namespace StatValueSnapshot {
    /**
     * @export
     * @enum {string}
     */
    export enum TrendEnum {
        Up = <any> 'up',
        Down = <any> 'down',
        Same = <any> 'same'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Auctions = <any> 'auctions',
        Requests = <any> 'requests',
        BidRequests = <any> 'bidRequests',
        Bids = <any> 'bids',
        Wins = <any> 'wins',
        Impressions = <any> 'impressions',
        Timeouts = <any> 'timeouts',
        UsdECPM = <any> 'usdECPM',
        EurECPM = <any> 'eurECPM',
        UsdRevenue = <any> 'usdRevenue',
        EurRevenue = <any> 'eurRevenue',
        BidRate = <any> 'bidRate',
        WinRate = <any> 'winRate',
        UsdBidPrice = <any> 'usdBidPrice',
        EurBidPrice = <any> 'eurBidPrice',
        TimeoutRate = <any> 'timeoutRate'
    }
}
/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    auctions: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    requests: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    bidRequests: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    bids: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    impressions: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    usdECPM: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    eurECPM: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    usdRevenue: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    eurRevenue: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    bidRate: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    winRate: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    usdBidPrice: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    eurBidPrice: StatBlock;
    /**
     * 
     * @type {StatBlock}
     * @memberof Statistics
     */
    timeoutRate: StatBlock;
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof Statistics
     */
    axisX: Array<AxisLabel>;
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof Statistics
     */
    axisYLeft: Array<AxisLabel>;
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof Statistics
     */
    axisYRight: Array<AxisLabel>;
    /**
     * 
     * @type {boolean}
     * @memberof Statistics
     */
    realData: boolean;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    maxCounterValue: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    maxMoneyValue: number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    text: string;
}
/**
 * 
 * @export
 * @interface TaglibDescriptor
 */
export interface TaglibDescriptor {
    /**
     * 
     * @type {string}
     * @memberof TaglibDescriptor
     */
    taglibLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof TaglibDescriptor
     */
    taglibURI?: string;
}
/**
 * 
 * @export
 * @interface TeamDetailsBean
 */
export interface TeamDetailsBean {
    /**
     * 
     * @type {number}
     * @memberof TeamDetailsBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeamDetailsBean
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDetailsBean
     */
    membershipPlan?: TeamDetailsBean.MembershipPlanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TeamDetailsBean
     */
    demo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamDetailsBean
     */
    moneyBalance?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamDetailsBean
     */
    pointBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDetailsBean
     */
    countOfMembers?: number;
    /**
     * 
     * @type {SmallStat}
     * @memberof TeamDetailsBean
     */
    stat?: SmallStat;
    /**
     * 
     * @type {string}
     * @memberof TeamDetailsBean
     */
    description?: string;
}

/**
 * @export
 * @namespace TeamDetailsBean
 */
export namespace TeamDetailsBean {
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipPlanEnum {
        Basic = <any> 'basic',
        Standard = <any> 'standard',
        Premium = <any> 'premium'
    }
}
/**
 * 
 * @export
 * @interface TeamRowBean
 */
export interface TeamRowBean {
    /**
     * 
     * @type {number}
     * @memberof TeamRowBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeamRowBean
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamRowBean
     */
    membershipPlan?: TeamRowBean.MembershipPlanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TeamRowBean
     */
    demo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamRowBean
     */
    moneyBalance?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamRowBean
     */
    pointBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamRowBean
     */
    countOfMembers?: number;
    /**
     * 
     * @type {SmallStat}
     * @memberof TeamRowBean
     */
    stat?: SmallStat;
}

/**
 * @export
 * @namespace TeamRowBean
 */
export namespace TeamRowBean {
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipPlanEnum {
        Basic = <any> 'basic',
        Standard = <any> 'standard',
        Premium = <any> 'premium'
    }
}
/**
 * 
 * @export
 * @interface TeamUpdateBean
 */
export interface TeamUpdateBean {
    /**
     * 
     * @type {number}
     * @memberof TeamUpdateBean
     */
    id: number;
}
/**
 * 
 * @export
 * @interface TestDataBean
 */
export interface TestDataBean {
    /**
     * 
     * @type {string}
     * @memberof TestDataBean
     */
    agencyStringId?: string;
    /**
     * 
     * @type {number}
     * @memberof TestDataBean
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof TestDataBean
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TestDataBean
     */
    days?: number;
}
/**
 * 
 * @export
 * @interface TotalCustomersStatBean
 */
export interface TotalCustomersStatBean {
    /**
     * 
     * @type {number}
     * @memberof TotalCustomersStatBean
     */
    registered: number;
    /**
     * 
     * @type {number}
     * @memberof TotalCustomersStatBean
     */
    confirmed: number;
    /**
     * 
     * @type {number}
     * @memberof TotalCustomersStatBean
     */
    inTrial: number;
    /**
     * 
     * @type {number}
     * @memberof TotalCustomersStatBean
     */
    billingStarted: number;
    /**
     * 
     * @type {number}
     * @memberof TotalCustomersStatBean
     */
    withBillingProblem: number;
}
/**
 * 
 * @export
 * @interface TransactionBean
 */
export interface TransactionBean {
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    paymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    billingDate: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    billStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    billEndDate: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    requests: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    imprs: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    chargeAmount: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    status: TransactionBean.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionBean
     */
    accountEmail: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    attemptsToPay: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionBean
     */
    creatorId?: number;
}

/**
 * @export
 * @namespace TransactionBean
 */
export namespace TransactionBean {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'Pending',
        NoBillable = <any> 'No_Billable',
        Paid = <any> 'Paid',
        Failed = <any> 'Failed',
        Paused = <any> 'Paused',
        Declined = <any> 'Declined'
    }
}
/**
 * 
 * @export
 * @interface UIAdUnit
 */
export interface UIAdUnit {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIAdUnit
     */
    code: UIXValueString;
    /**
     * 
     * @type {UIAdUnitBidList}
     * @memberof UIAdUnit
     */
    bids: UIAdUnitBidList;
    /**
     * 
     * @type {UIMediaTypes}
     * @memberof UIAdUnit
     */
    mediaTypes: UIMediaTypes;
    /**
     * 
     * @type {UISizeConfigLabelSelector}
     * @memberof UIAdUnit
     */
    labelAny: UISizeConfigLabelSelector;
    /**
     * 
     * @type {UISizeConfigLabelSelector}
     * @memberof UIAdUnit
     */
    labelAll: UISizeConfigLabelSelector;
    /**
     * 
     * @type {boolean}
     * @memberof UIAdUnit
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIAdUnitBid
 */
export interface UIAdUnitBid {
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitBid
     */
    bidderKey: UIAdUnitBid.BidderKeyEnum;
    /**
     * 
     * @type {UISizeConfigLabelSelector}
     * @memberof UIAdUnitBid
     */
    labelAll: UISizeConfigLabelSelector;
    /**
     * 
     * @type {UISizeConfigLabelSelector}
     * @memberof UIAdUnitBid
     */
    labelAny: UISizeConfigLabelSelector;
    /**
     * 
     * @type {UIAdUnitBidDemo1}
     * @memberof UIAdUnitBid
     */
    demo1?: UIAdUnitBidDemo1;
    /**
     * 
     * @type {UIAdUnitBidAppnexus}
     * @memberof UIAdUnitBid
     */
    appnexus?: UIAdUnitBidAppnexus;
}

/**
 * @export
 * @namespace UIAdUnitBid
 */
export namespace UIAdUnitBid {
    /**
     * @export
     * @enum {string}
     */
    export enum BidderKeyEnum {
        Demo1 = <any> 'demo1',
        Appnexus = <any> 'appnexus'
    }
}
/**
 * 
 * @export
 * @interface UIAdUnitBidAppnexus
 */
export interface UIAdUnitBidAppnexus {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIAdUnitBidAppnexus
     */
    placementId: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof UIAdUnitBidAppnexus
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIAdUnitBidDemo1
 */
export interface UIAdUnitBidDemo1 {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIAdUnitBidDemo1
     */
    companyId: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof UIAdUnitBidDemo1
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIAdUnitBidList
 */
export interface UIAdUnitBidList {
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitBidList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitBidList
     */
    errorLevel?: UIAdUnitBidList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIAdUnitBidList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIAdUnitBid>}
     * @memberof UIAdUnitBidList
     */
    items: Array<UIAdUnitBid>;
    /**
     * 
     * @type {boolean}
     * @memberof UIAdUnitBidList
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitBidList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIAdUnitBidList
 */
export namespace UIAdUnitBidList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIAdUnitList
 */
export interface UIAdUnitList {
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitList
     */
    errorLevel?: UIAdUnitList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIAdUnitList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIAdUnit>}
     * @memberof UIAdUnitList
     */
    items: Array<UIAdUnit>;
    /**
     * 
     * @type {boolean}
     * @memberof UIAdUnitList
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIAdUnitList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIAdUnitList
 */
export namespace UIAdUnitList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIAspectRatios
 */
export interface UIAspectRatios {
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIAspectRatios
     */
    minWidth: UIXValueInteger;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIAspectRatios
     */
    ratioWidth: UIXValueInteger;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIAspectRatios
     */
    ratioHeight: UIXValueInteger;
}
/**
 * 
 * @export
 * @interface UIBidderConfigAppnexus
 */
export interface UIBidderConfigAppnexus {
}
/**
 * 
 * @export
 * @interface UIBidderConfigDemo1
 */
export interface UIBidderConfigDemo1 {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIBidderConfigDemo1
     */
    key: UIXValueString;
}
/**
 * 
 * @export
 * @interface UIBidderConfigSet
 */
export interface UIBidderConfigSet {
    /**
     * 
     * @type {UIBidderConfigDemo1}
     * @memberof UIBidderConfigSet
     */
    demo1?: UIBidderConfigDemo1;
    /**
     * 
     * @type {UIBidderConfigAppnexus}
     * @memberof UIBidderConfigSet
     */
    appnexus?: UIBidderConfigAppnexus;
}
/**
 * 
 * @export
 * @interface UIBundle
 */
export interface UIBundle {
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    teamId?: number;
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    configVersion: number;
    /**
     * 
     * @type {UIBundleGeneral}
     * @memberof UIBundle
     */
    general: UIBundleGeneral;
    /**
     * 
     * @type {UIBundleBidders}
     * @memberof UIBundle
     */
    bidders: UIBundleBidders;
    /**
     * 
     * @type {UIBundleTuning}
     * @memberof UIBundle
     */
    tuning: UIBundleTuning;
    /**
     * 
     * @type {UIBundleUserSync}
     * @memberof UIBundle
     */
    userSync: UIBundleUserSync;
    /**
     * 
     * @type {UIBundleSizeConfig}
     * @memberof UIBundle
     */
    sizeConfig: UIBundleSizeConfig;
    /**
     * 
     * @type {UIBundleAdUnits}
     * @memberof UIBundle
     */
    adUnits: UIBundleAdUnits;
    /**
     * 
     * @type {UIBundleDeploy}
     * @memberof UIBundle
     */
    deploy: UIBundleDeploy;
    /**
     * 
     * @type {string}
     * @memberof UIBundle
     */
    selectedPage: UIBundle.SelectedPageEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundle
     */
    valid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UIBundle
     */
    bundleId?: number;
    /**
     * 
     * @type {string}
     * @memberof UIBundle
     */
    adUnitId?: string;
}

/**
 * @export
 * @namespace UIBundle
 */
export namespace UIBundle {
    /**
     * @export
     * @enum {string}
     */
    export enum SelectedPageEnum {
        General = <any> 'general',
        Tuning = <any> 'tuning',
        Bidders = <any> 'bidders',
        UserSync = <any> 'userSync',
        SizeConfig = <any> 'sizeConfig',
        AdUnits = <any> 'adUnits',
        Deploy = <any> 'deploy'
    }
}
/**
 * 
 * @export
 * @interface UIBundleAdUnits
 */
export interface UIBundleAdUnits {
    /**
     * 
     * @type {UIAdUnitList}
     * @memberof UIBundleAdUnits
     */
    list: UIAdUnitList;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleAdUnits
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleBidders
 */
export interface UIBundleBidders {
    /**
     * 
     * @type {Array<BundleBidderInfo>}
     * @memberof UIBundleBidders
     */
    bidders: Array<BundleBidderInfo>;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleBidders
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleDeploy
 */
export interface UIBundleDeploy {
}
/**
 * 
 * @export
 * @interface UIBundleGeneral
 */
export interface UIBundleGeneral {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIBundleGeneral
     */
    name: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIBundleGeneral
     */
    domain: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIBundleGeneral
     */
    tags: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIBundleGeneral
     */
    description: UIXValueString;
    /**
     * 
     * @type {string}
     * @memberof UIBundleGeneral
     */
    prebidVersion: string;
    /**
     * 
     * @type {PageLayoutBean}
     * @memberof UIBundleGeneral
     */
    layout: PageLayoutBean;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleGeneral
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleSizeConfig
 */
export interface UIBundleSizeConfig {
    /**
     * 
     * @type {UISizeConfigList}
     * @memberof UIBundleSizeConfig
     */
    sizeConfig: UISizeConfigList;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleSizeConfig
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleTuning
 */
export interface UIBundleTuning {
    /**
     * 
     * @type {UIXValueEnumBMBidderSequenceType}
     * @memberof UIBundleTuning
     */
    bidderSequence: UIXValueEnumBMBidderSequenceType;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIBundleTuning
     */
    cookieSyncDelay: UIXValueInteger;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIBundleTuning
     */
    timeout: UIXValueInteger;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleTuning
     */
    debug: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleTuning
     */
    sendAllBids: UIXValueBoolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleTuning
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleUserSync
 */
export interface UIBundleUserSync {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleUserSync
     */
    userSyncEnabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleUserSync
     */
    userSyncEnableOverride: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleUserSync
     */
    userSyncIFrameEnabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleUserSync
     */
    userSyncPixelEnabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIBundleUserSync
     */
    userSyncSyncPerBidderAll: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIBundleUserSync
     */
    userSyncSyncPerBidder: UIXValueInteger;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIBundleUserSync
     */
    userSyncSyncDelay: UIXValueInteger;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleUserSync
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIBundleView
 */
export interface UIBundleView {
    /**
     * 
     * @type {Statistics}
     * @memberof UIBundleView
     */
    statistics: Statistics;
    /**
     * 
     * @type {string}
     * @memberof UIBundleView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UIBundleView
     */
    domain: string;
    /**
     * 
     * @type {BootstrapStatusLabel}
     * @memberof UIBundleView
     */
    status: BootstrapStatusLabel;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleView
     */
    archived: boolean;
    /**
     * 
     * @type {number}
     * @memberof UIBundleView
     */
    id: number;
    /**
     * 
     * @type {Array<AdUnitPreview>}
     * @memberof UIBundleView
     */
    adUnits: Array<AdUnitPreview>;
    /**
     * 
     * @type {boolean}
     * @memberof UIBundleView
     */
    hasTraffic: boolean;
    /**
     * 
     * @type {number}
     * @memberof UIBundleView
     */
    trafficHoursAgo: number;
}
/**
 * 
 * @export
 * @interface UIBundleViewQuery
 */
export interface UIBundleViewQuery {
    /**
     * 
     * @type {string}
     * @memberof UIBundleViewQuery
     */
    period: UIBundleViewQuery.PeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof UIBundleViewQuery
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof UIBundleViewQuery
     */
    to: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UIBundleViewQuery
     */
    showValue: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof UIBundleViewQuery
     */
    id: number;
}

/**
 * @export
 * @namespace UIBundleViewQuery
 */
export namespace UIBundleViewQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodEnum {
        Last30Days = <any> 'last30Days',
        Last7Days = <any> 'last7Days',
        Day = <any> 'day',
        Year = <any> 'year',
        Realtime = <any> 'realtime',
        Custom = <any> 'custom'
    }
}
/**
 * 
 * @export
 * @interface UIMediaQuery
 */
export interface UIMediaQuery {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaQuery
     */
    custom: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIMediaQuery
     */
    customQuery: UIXValueString;
    /**
     * 
     * @type {UIMediaQueryMatchList}
     * @memberof UIMediaQuery
     */
    items: UIMediaQueryMatchList;
}
/**
 * 
 * @export
 * @interface UIMediaQueryFeature
 */
export interface UIMediaQueryFeature {
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeature
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeature
     */
    errorLevel?: UIMediaQueryFeature.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaQueryFeature
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeature
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeature
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaQueryFeature
 */
export namespace UIMediaQueryFeature {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaQueryFeatureOperation
 */
export interface UIMediaQueryFeatureOperation {
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureOperation
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureOperation
     */
    errorLevel?: UIMediaQueryFeatureOperation.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaQueryFeatureOperation
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureOperation
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureOperation
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaQueryFeatureOperation
 */
export namespace UIMediaQueryFeatureOperation {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaQueryFeatureTest
 */
export interface UIMediaQueryFeatureTest {
    /**
     * 
     * @type {UIMediaQueryFeature}
     * @memberof UIMediaQueryFeatureTest
     */
    feature: UIMediaQueryFeature;
    /**
     * 
     * @type {UIMediaQueryFeatureOperation}
     * @memberof UIMediaQueryFeatureTest
     */
    operation: UIMediaQueryFeatureOperation;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIMediaQueryFeatureTest
     */
    value: UIXValueString;
}
/**
 * 
 * @export
 * @interface UIMediaQueryFeatureTestList
 */
export interface UIMediaQueryFeatureTestList {
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureTestList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureTestList
     */
    errorLevel?: UIMediaQueryFeatureTestList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaQueryFeatureTestList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIMediaQueryFeatureTest>}
     * @memberof UIMediaQueryFeatureTestList
     */
    items: Array<UIMediaQueryFeatureTest>;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryFeatureTestList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaQueryFeatureTestList
 */
export namespace UIMediaQueryFeatureTestList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaQueryMatch
 */
export interface UIMediaQueryMatch {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaQueryMatch
     */
    custom: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIMediaQueryMatch
     */
    customQuery: UIXValueString;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaQueryMatch
     */
    not: UIXValueBoolean;
    /**
     * 
     * @type {UIMediaQueryFeatureTestList}
     * @memberof UIMediaQueryMatch
     */
    featureTests: UIMediaQueryFeatureTestList;
}
/**
 * 
 * @export
 * @interface UIMediaQueryMatchList
 */
export interface UIMediaQueryMatchList {
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryMatchList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryMatchList
     */
    errorLevel?: UIMediaQueryMatchList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaQueryMatchList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIMediaQueryMatch>}
     * @memberof UIMediaQueryMatchList
     */
    items: Array<UIMediaQueryMatch>;
    /**
     * 
     * @type {string}
     * @memberof UIMediaQueryMatchList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaQueryMatchList
 */
export namespace UIMediaQueryMatchList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaSize
 */
export interface UIMediaSize {
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIMediaSize
     */
    width: UIXValueInteger;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIMediaSize
     */
    height: UIXValueInteger;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaSize
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIMediaSizeList
 */
export interface UIMediaSizeList {
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeList
     */
    errorLevel?: UIMediaSizeList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaSizeList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIMediaSize>}
     * @memberof UIMediaSizeList
     */
    items: Array<UIMediaSize>;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaSizeList
     */
    canBeEmpty: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaSizeList
 */
export namespace UIMediaSizeList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaSizeListOptional
 */
export interface UIMediaSizeListOptional {
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeListOptional
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeListOptional
     */
    errorLevel?: UIMediaSizeListOptional.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMediaSizeListOptional
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIMediaSize>}
     * @memberof UIMediaSizeListOptional
     */
    items: Array<UIMediaSize>;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaSizeListOptional
     */
    canBeEmpty: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIMediaSizeListOptional
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMediaSizeListOptional
 */
export namespace UIMediaSizeListOptional {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIMediaTypeBanner
 */
export interface UIMediaTypeBanner {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeBanner
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UIMediaTypeBanner
     */
    name: UIXValueString;
    /**
     * 
     * @type {UIMediaSizeList}
     * @memberof UIMediaTypeBanner
     */
    sizes: UIMediaSizeList;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaTypeBanner
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNative
 */
export interface UIMediaTypeNative {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNative
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIMediaSizeList}
     * @memberof UIMediaTypeNative
     */
    sizes: UIMediaSizeList;
    /**
     * 
     * @type {UIMediaTypeNativeTitle}
     * @memberof UIMediaTypeNative
     */
    title: UIMediaTypeNativeTitle;
    /**
     * 
     * @type {UIMediaTypeNativeBody}
     * @memberof UIMediaTypeNative
     */
    body: UIMediaTypeNativeBody;
    /**
     * 
     * @type {UIMediaTypeNativeSponsoredBy}
     * @memberof UIMediaTypeNative
     */
    sponsoredBy: UIMediaTypeNativeSponsoredBy;
    /**
     * 
     * @type {UIMediaTypeNativeIcon}
     * @memberof UIMediaTypeNative
     */
    icon: UIMediaTypeNativeIcon;
    /**
     * 
     * @type {UIMediaTypeNativeImage}
     * @memberof UIMediaTypeNative
     */
    image: UIMediaTypeNativeImage;
    /**
     * 
     * @type {UIMediaTypeNativeClickUrl}
     * @memberof UIMediaTypeNative
     */
    clickUrl: UIMediaTypeNativeClickUrl;
    /**
     * 
     * @type {UIMediaTypeNativeCta}
     * @memberof UIMediaTypeNative
     */
    cta: UIMediaTypeNativeCta;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeBody
 */
export interface UIMediaTypeNativeBody {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeBody
     */
    required: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIMediaTypeNativeBody
     */
    len: UIXValueInteger;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeClickUrl
 */
export interface UIMediaTypeNativeClickUrl {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeClickUrl
     */
    required: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeCta
 */
export interface UIMediaTypeNativeCta {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeCta
     */
    required: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeIcon
 */
export interface UIMediaTypeNativeIcon {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeIcon
     */
    required: UIXValueBoolean;
    /**
     * 
     * @type {UIMediaSizeListOptional}
     * @memberof UIMediaTypeNativeIcon
     */
    sizes: UIMediaSizeListOptional;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeIcon
     */
    aspectRatiosEnabled: UIXValueBoolean;
    /**
     * 
     * @type {UIAspectRatios}
     * @memberof UIMediaTypeNativeIcon
     */
    aspectRatios: UIAspectRatios;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeImage
 */
export interface UIMediaTypeNativeImage {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeImage
     */
    required: UIXValueBoolean;
    /**
     * 
     * @type {UIMediaSizeListOptional}
     * @memberof UIMediaTypeNativeImage
     */
    sizes: UIMediaSizeListOptional;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeImage
     */
    aspectRatiosEnabled: UIXValueBoolean;
    /**
     * 
     * @type {UIAspectRatios}
     * @memberof UIMediaTypeNativeImage
     */
    aspectRatios: UIAspectRatios;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeSponsoredBy
 */
export interface UIMediaTypeNativeSponsoredBy {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeSponsoredBy
     */
    required: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIMediaTypeNativeTitle
 */
export interface UIMediaTypeNativeTitle {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeNativeTitle
     */
    required: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueInteger}
     * @memberof UIMediaTypeNativeTitle
     */
    len: UIXValueInteger;
}
/**
 * 
 * @export
 * @interface UIMediaTypeVideo
 */
export interface UIMediaTypeVideo {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIMediaTypeVideo
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIVideoContext}
     * @memberof UIMediaTypeVideo
     */
    context: UIVideoContext;
    /**
     * 
     * @type {UIMediaSize}
     * @memberof UIMediaTypeVideo
     */
    playerSize: UIMediaSize;
    /**
     * 
     * @type {UIVideoApi}
     * @memberof UIMediaTypeVideo
     */
    api: UIVideoApi;
    /**
     * 
     * @type {UIMimeList}
     * @memberof UIMediaTypeVideo
     */
    mimes: UIMimeList;
    /**
     * 
     * @type {UIVideoProtocols}
     * @memberof UIMediaTypeVideo
     */
    protocols: UIVideoProtocols;
    /**
     * 
     * @type {UIVideoPlaybackMethod}
     * @memberof UIMediaTypeVideo
     */
    playbackmethod: UIVideoPlaybackMethod;
}
/**
 * 
 * @export
 * @interface UIMediaTypes
 */
export interface UIMediaTypes {
    /**
     * 
     * @type {UIMediaTypeBanner}
     * @memberof UIMediaTypes
     */
    banner: UIMediaTypeBanner;
    /**
     * 
     * @type {UIMediaTypeNative}
     * @memberof UIMediaTypes
     */
    nativeType: UIMediaTypeNative;
    /**
     * 
     * @type {UIMediaTypeVideo}
     * @memberof UIMediaTypes
     */
    video: UIMediaTypeVideo;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaTypes
     */
    valid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIMediaTypes
     */
    atLeastOneRequiredError?: boolean;
}
/**
 * 
 * @export
 * @interface UIMimeList
 */
export interface UIMimeList {
    /**
     * 
     * @type {string}
     * @memberof UIMimeList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIMimeList
     */
    errorLevel?: UIMimeList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIMimeList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UIXValueString>}
     * @memberof UIMimeList
     */
    items: Array<UIXValueString>;
    /**
     * 
     * @type {string}
     * @memberof UIMimeList
     */
    error?: string;
}

/**
 * @export
 * @namespace UIMimeList
 */
export namespace UIMimeList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UISizeConfig
 */
export interface UISizeConfig {
    /**
     * 
     * @type {UISizeConfigLabelList}
     * @memberof UISizeConfig
     */
    labels: UISizeConfigLabelList;
    /**
     * 
     * @type {UIMediaQuery}
     * @memberof UISizeConfig
     */
    mediaQuery: UIMediaQuery;
    /**
     * 
     * @type {UIMediaSizeList}
     * @memberof UISizeConfig
     */
    sizesSupported: UIMediaSizeList;
    /**
     * 
     * @type {boolean}
     * @memberof UISizeConfig
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UISizeConfigLabel
 */
export interface UISizeConfigLabel {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UISizeConfigLabel
     */
    label: UIXValueString;
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigLabel
     */
    comments: string;
    /**
     * 
     * @type {boolean}
     * @memberof UISizeConfigLabel
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UISizeConfigLabelList
 */
export interface UISizeConfigLabelList {
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigLabelList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigLabelList
     */
    errorLevel?: UISizeConfigLabelList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UISizeConfigLabelList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UISizeConfigLabel>}
     * @memberof UISizeConfigLabelList
     */
    items: Array<UISizeConfigLabel>;
    /**
     * 
     * @type {boolean}
     * @memberof UISizeConfigLabelList
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigLabelList
     */
    error?: string;
}

/**
 * @export
 * @namespace UISizeConfigLabelList
 */
export namespace UISizeConfigLabelList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UISizeConfigLabelSelector
 */
export interface UISizeConfigLabelSelector {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UISizeConfigLabelSelector
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {{ [key: string]: UIXValueBoolean; }}
     * @memberof UISizeConfigLabelSelector
     */
    items: { [key: string]: UIXValueBoolean; };
}
/**
 * 
 * @export
 * @interface UISizeConfigList
 */
export interface UISizeConfigList {
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigList
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigList
     */
    errorLevel?: UISizeConfigList.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UISizeConfigList
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {Array<UISizeConfig>}
     * @memberof UISizeConfigList
     */
    items: Array<UISizeConfig>;
    /**
     * 
     * @type {string}
     * @memberof UISizeConfigList
     */
    error?: string;
}

/**
 * @export
 * @namespace UISizeConfigList
 */
export namespace UISizeConfigList {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIVideoApi
 */
export interface UIVideoApi {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    vpaid10: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    vpaid20: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    mraid1: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    ormma: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoApi
     */
    mraid2: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIVideoContext
 */
export interface UIVideoContext {
    /**
     * 
     * @type {string}
     * @memberof UIVideoContext
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIVideoContext
     */
    errorLevel?: UIVideoContext.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIVideoContext
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIVideoContext
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof UIVideoContext
     */
    error?: string;
}

/**
 * @export
 * @namespace UIVideoContext
 */
export namespace UIVideoContext {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIVideoPlaybackMethod
 */
export interface UIVideoPlaybackMethod {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoPlaybackMethod
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoPlaybackMethod
     */
    autoPlaySoundOff: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoPlaybackMethod
     */
    autoPlaySoundOn: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoPlaybackMethod
     */
    clickToPay: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoPlaybackMethod
     */
    mouseOver: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIVideoProtocols
 */
export interface UIVideoProtocols {
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    enabled: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast10: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast20: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast30: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast10Wrapper: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast20Wrapper: UIXValueBoolean;
    /**
     * 
     * @type {UIXValueBoolean}
     * @memberof UIVideoProtocols
     */
    vast30Wrapper: UIXValueBoolean;
}
/**
 * 
 * @export
 * @interface UIXRect
 */
export interface UIXRect {
    /**
     * 
     * @type {number}
     * @memberof UIXRect
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof UIXRect
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof UIXRect
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof UIXRect
     */
    height: number;
}
/**
 * 
 * @export
 * @interface UIXSelectOption
 */
export interface UIXSelectOption {
    /**
     * 
     * @type {string}
     * @memberof UIXSelectOption
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof UIXSelectOption
     */
    label?: string;
}
/**
 * 
 * @export
 * @interface UIXSettings
 */
export interface UIXSettings {
    /**
     * 
     * @type {UIXSettingsTypes}
     * @memberof UIXSettings
     */
    types: UIXSettingsTypes;
}
/**
 * 
 * @export
 * @interface UIXSettingsTypes
 */
export interface UIXSettingsTypes {
    /**
     * 
     * @type {UIXSettingsTypesCore}
     * @memberof UIXSettingsTypes
     */
    core?: UIXSettingsTypesCore;
    /**
     * 
     * @type {UIXTypeBidderDemo1Key}
     * @memberof UIXSettingsTypes
     */
    bidderDemo1Key: UIXTypeBidderDemo1Key;
    /**
     * 
     * @type {UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    bundleName: UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain}
     * @memberof UIXSettingsTypes
     */
    bundleDomain: UIXTypeBundleDomain;
    /**
     * 
     * @type {UIXTypeBundlePageLayoutId}
     * @memberof UIXSettingsTypes
     */
    bundlePageLayoutId: UIXTypeBundlePageLayoutId;
    /**
     * 
     * @type {UIXTypeBundleTags}
     * @memberof UIXSettingsTypes
     */
    bundleTags: UIXTypeBundleTags;
    /**
     * 
     * @type {UIXTypeBundleDescription}
     * @memberof UIXSettingsTypes
     */
    bundleDescription: UIXTypeBundleDescription;
    /**
     * 
     * @type {UIXTypeBidderComments}
     * @memberof UIXSettingsTypes
     */
    bidderComments: UIXTypeBidderComments;
    /**
     * 
     * @type {UIXTypeBundleBidderSequence}
     * @memberof UIXSettingsTypes
     */
    bidderSequence: UIXTypeBundleBidderSequence;
    /**
     * 
     * @type {UIXTypeBundleCookieSyncDelay}
     * @memberof UIXSettingsTypes
     */
    cookieSyncDelay: UIXTypeBundleCookieSyncDelay;
    /**
     * 
     * @type {UIXTypeBundleTimeout}
     * @memberof UIXSettingsTypes
     */
    bundleTimeout: UIXTypeBundleTimeout;
    /**
     * 
     * @type {UIXTypeBundleDebug}
     * @memberof UIXSettingsTypes
     */
    bundleDebug: UIXTypeBundleDebug;
    /**
     * 
     * @type {UIXTypeBundleSendAllBids}
     * @memberof UIXSettingsTypes
     */
    bundleSendAllBids: UIXTypeBundleSendAllBids;
    /**
     * 
     * @type {UIXTypeBundleUserSyncEnabled}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncEnabled: UIXTypeBundleUserSyncEnabled;
    /**
     * 
     * @type {UIXTypeBundleUserSyncEnableOverride}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncEnableOverride: UIXTypeBundleUserSyncEnableOverride;
    /**
     * 
     * @type {UIXTypeBundleUserSyncIFrameEnabled}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncIFrameEnabled: UIXTypeBundleUserSyncIFrameEnabled;
    /**
     * 
     * @type {UIXTypeBundleUserSyncPixelEnabled}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncPixelEnabled: UIXTypeBundleUserSyncPixelEnabled;
    /**
     * 
     * @type {UIXTypeBundleUserSyncSyncsPerBidderAll}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncSyncPerBidderAll: UIXTypeBundleUserSyncSyncsPerBidderAll;
    /**
     * 
     * @type {UIXTypeBundleUserSyncPerBidder}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncSyncPerBidder: UIXTypeBundleUserSyncPerBidder;
    /**
     * 
     * @type {UIXTypeBundleUserSyncSyncDelay}
     * @memberof UIXSettingsTypes
     */
    bundleUserSyncSyncDelay: UIXTypeBundleUserSyncSyncDelay;
    /**
     * 
     * @type {UIXTypeBundleAdUnitCode}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitCode: UIXTypeBundleAdUnitCode;
    /**
     * 
     * @type {UIXTypeBundleAdUnitList}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitList: UIXTypeBundleAdUnitList;
    /**
     * 
     * @type {UIXTypeMediaSizeWidth}
     * @memberof UIXSettingsTypes
     */
    mediaSizeWidth: UIXTypeMediaSizeWidth;
    /**
     * 
     * @type {UIXTypeMediaSizeHeight}
     * @memberof UIXSettingsTypes
     */
    mediaSizeHeight: UIXTypeMediaSizeHeight;
    /**
     * 
     * @type {UIXTypeSizeConfigLabel}
     * @memberof UIXSettingsTypes
     */
    sizeConfigLabel: UIXTypeSizeConfigLabel;
    /**
     * 
     * @type {UIXTypeBundleAdUnitMediaTypeBannerName}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitMediaTypeBannerName: UIXTypeBundleAdUnitMediaTypeBannerName;
    /**
     * 
     * @type {UIXTypeBundleAdUnitMediaTypeNativeTitleLen}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitMediaTypeNativeTitleLen: UIXTypeBundleAdUnitMediaTypeNativeTitleLen;
    /**
     * 
     * @type {UIXTypeBundleAdUnitMediaTypeNativeBodyLen}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitMediaTypeNativeBodyLen: UIXTypeBundleAdUnitMediaTypeNativeBodyLen;
    /**
     * 
     * @type {UIXTypeBundleAdUnitMediaTypeNativeType}
     * @memberof UIXSettingsTypes
     */
    bundleAdUnitMediaTypeNativeType: UIXTypeBundleAdUnitMediaTypeNativeType;
    /**
     * 
     * @type {UIXTypeAspectRatioMinWidth}
     * @memberof UIXSettingsTypes
     */
    aspectRatioMinWidth: UIXTypeAspectRatioMinWidth;
    /**
     * 
     * @type {UIXTypeAspectRatioRatioWidth}
     * @memberof UIXSettingsTypes
     */
    aspectRatioRatioWidth: UIXTypeAspectRatioRatioWidth;
    /**
     * 
     * @type {UIXTypeAspectRatioRatioHeight}
     * @memberof UIXSettingsTypes
     */
    aspectRatioRatioHeight: UIXTypeAspectRatioRatioHeight;
    /**
     * 
     * @type {UIXTypeVideoContext}
     * @memberof UIXSettingsTypes
     */
    videoContext: UIXTypeVideoContext;
    /**
     * 
     * @type {UIXTypeVideoMime}
     * @memberof UIXSettingsTypes
     */
    videoMime: UIXTypeVideoMime;
    /**
     * 
     * @type {UIXTypeMediaQueryFeatureTestValue}
     * @memberof UIXSettingsTypes
     */
    mediaQueryFeatureTestValue: UIXTypeMediaQueryFeatureTestValue;
    /**
     * 
     * @type {UIXTypeMediaQueryCustomQuery}
     * @memberof UIXSettingsTypes
     */
    mediaQueryCustomQuery: UIXTypeMediaQueryCustomQuery;
    /**
     * 
     * @type {UIXTypeMediaQueryFeatureTestFeature}
     * @memberof UIXSettingsTypes
     */
    mediaQueryFeatureTestFeature: UIXTypeMediaQueryFeatureTestFeature;
    /**
     * 
     * @type {UIXTypeMediaQueryFeatureTestOperation}
     * @memberof UIXSettingsTypes
     */
    mediaQueryFeatureTestOperation: UIXTypeMediaQueryFeatureTestOperation;
    /**
     * 
     * @type {UIXTypeMediaQueryCustom}
     * @memberof UIXSettingsTypes
     */
    mediaQueryCustom: UIXTypeMediaQueryCustom;
    /**
     * 
     * @type {UIXTypeMediaQueryNot}
     * @memberof UIXSettingsTypes
     */
    mediaQueryNot: UIXTypeMediaQueryNot;
    /**
     * 
     * @type {UIXTypeMediaQueryMatchCustom}
     * @memberof UIXSettingsTypes
     */
    mediaQueryMatchCustom: UIXTypeMediaQueryMatchCustom;
    /**
     * 
     * @type {UIXTypeMediaQueryMatchCustomQuery}
     * @memberof UIXSettingsTypes
     */
    mediaQueryMatchCustomQuer: UIXTypeMediaQueryMatchCustomQuery;
    /**
     * 
     * @type {UIXTypeMediaSizeOptionalEnabled}
     * @memberof UIXSettingsTypes
     */
    mediaSizeOptionalEnabled: UIXTypeMediaSizeOptionalEnabled;
    /**
     * 
     * @type {UIXTypeMediaTypeBannerEnabled}
     * @memberof UIXSettingsTypes
     */
    mediaTypeBannerEnabled: UIXTypeMediaTypeBannerEnabled;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeEnabled}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeEnabled: UIXTypeMediaTypeNativeEnabled;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeBodyRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeBodyRequired: UIXTypeMediaTypeNativeBodyRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeClickUrlRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeClickUrlRequired: UIXTypeMediaTypeNativeClickUrlRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeCtaRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeCtaRequired: UIXTypeMediaTypeNativeCtaRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeIconRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeIconRequired: UIXTypeMediaTypeNativeIconRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeImageRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeImageRequired: UIXTypeMediaTypeNativeImageRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeSponsoredByRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeSponsoredByRequired: UIXTypeMediaTypeNativeSponsoredByRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeNativeTitleRequired}
     * @memberof UIXSettingsTypes
     */
    mediaTypeNativeTitleRequired: UIXTypeMediaTypeNativeTitleRequired;
    /**
     * 
     * @type {UIXTypeMediaTypeVideoEnabled}
     * @memberof UIXSettingsTypes
     */
    mediaTypeVideoEnabled: UIXTypeMediaTypeVideoEnabled;
    /**
     * 
     * @type {UIXTypeMediaQueryMatchCustomQuery}
     * @memberof UIXSettingsTypes
     */
    mediaQueryMatchCustomQuery: UIXTypeMediaQueryMatchCustomQuery;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    bidAppnexusPlacementId: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBidDemo1CompanyId}
     * @memberof UIXSettingsTypes
     */
    bidDemo1CompanyId: UIXTypeBidDemo1CompanyId;
    /**
     * 
     * @type {UIXTypeBundleAdUnitBidList}
     * @memberof UIXSettingsTypes
     */
    bundleBidList: UIXTypeBundleAdUnitBidList;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    registationEmail: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    registationPassword: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    registationReferralCode: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profileFirstName: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profileLastName: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profilePhone: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profileCurrency: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profilePassword: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypes
     */
    profilePasswordConfirm: UIXTypeBundleDomain | UIXTypeBundleName;
    /**
     * 
     * @type {UIXTypeBoolean}
     * @memberof UIXSettingsTypes
     */
    nativeImageAspectRatioEnabled: UIXTypeBoolean;
    /**
     * 
     * @type {UIXTypeBoolean}
     * @memberof UIXSettingsTypes
     */
    nativeIconAspectRatioEnabled: UIXTypeBoolean;
}
/**
 * 
 * @export
 * @interface UIXSettingsTypesCore
 */
export interface UIXSettingsTypesCore {
    /**
     * 
     * @type {UIXTypeBoolean}
     * @memberof UIXSettingsTypesCore
     */
    b?: UIXTypeBoolean;
    /**
     * 
     * @type {UIXTypeBundleBidderSequence}
     * @memberof UIXSettingsTypesCore
     */
    e?: UIXTypeBundleBidderSequence;
    /**
     * 
     * @type {UIXTypeBundleCookieSyncDelay | UIXTypeBundlePageLayoutId | UIXTypeBundleTimeout | UIXTypeBundleUserSyncPerBidder | UIXTypeBundleUserSyncSyncDelay}
     * @memberof UIXSettingsTypesCore
     */
    n?: UIXTypeBundleCookieSyncDelay | UIXTypeBundlePageLayoutId | UIXTypeBundleTimeout | UIXTypeBundleUserSyncPerBidder | UIXTypeBundleUserSyncSyncDelay;
    /**
     * 
     * @type {UIXTypeBundleDomain | UIXTypeBundleName}
     * @memberof UIXSettingsTypesCore
     */
    s?: UIXTypeBundleDomain | UIXTypeBundleName;
}
/**
 * 
 * @export
 * @interface UIXTypeAspectRatioMinWidth
 */
export interface UIXTypeAspectRatioMinWidth {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    type?: UIXTypeAspectRatioMinWidth.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    helpId?: UIXTypeAspectRatioMinWidth.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioMinWidth
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeAspectRatioMinWidth
 */
export namespace UIXTypeAspectRatioMinWidth {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeAspectRatioRatioHeight
 */
export interface UIXTypeAspectRatioRatioHeight {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    type?: UIXTypeAspectRatioRatioHeight.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    helpId?: UIXTypeAspectRatioRatioHeight.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioHeight
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeAspectRatioRatioHeight
 */
export namespace UIXTypeAspectRatioRatioHeight {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeAspectRatioRatioWidth
 */
export interface UIXTypeAspectRatioRatioWidth {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    type?: UIXTypeAspectRatioRatioWidth.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    helpId?: UIXTypeAspectRatioRatioWidth.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeAspectRatioRatioWidth
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeAspectRatioRatioWidth
 */
export namespace UIXTypeAspectRatioRatioWidth {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBidDemo1CompanyId
 */
export interface UIXTypeBidDemo1CompanyId {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    type?: UIXTypeBidDemo1CompanyId.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    helpId?: UIXTypeBidDemo1CompanyId.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidDemo1CompanyId
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBidDemo1CompanyId
 */
export namespace UIXTypeBidDemo1CompanyId {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBidderComments
 */
export interface UIXTypeBidderComments {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    type?: UIXTypeBidderComments.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderComments
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    helpId?: UIXTypeBidderComments.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderComments
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderComments
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderComments
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBidderComments
 */
export namespace UIXTypeBidderComments {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBidderDemo1Key
 */
export interface UIXTypeBidderDemo1Key {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    type?: UIXTypeBidderDemo1Key.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderDemo1Key
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    helpId?: UIXTypeBidderDemo1Key.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderDemo1Key
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBidderDemo1Key
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBidderDemo1Key
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBidderDemo1Key
 */
export namespace UIXTypeBidderDemo1Key {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBoolean
 */
export interface UIXTypeBoolean {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBoolean
     */
    type?: UIXTypeBoolean.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBoolean
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBoolean
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBoolean
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBoolean
     */
    helpId?: UIXTypeBoolean.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBoolean
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBoolean
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBoolean
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBoolean
 */
export namespace UIXTypeBoolean {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitBidList
 */
export interface UIXTypeBundleAdUnitBidList {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    type?: UIXTypeBundleAdUnitBidList.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    helpId?: UIXTypeBundleAdUnitBidList.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    suggestions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    minLength: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    minLengthError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    maxLength: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitBidList
     */
    msxLengthError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitBidList
 */
export namespace UIXTypeBundleAdUnitBidList {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitCode
 */
export interface UIXTypeBundleAdUnitCode {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    type?: UIXTypeBundleAdUnitCode.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitCode
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    helpId?: UIXTypeBundleAdUnitCode.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitCode
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitCode
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitCode
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitCode
 */
export namespace UIXTypeBundleAdUnitCode {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitList
 */
export interface UIXTypeBundleAdUnitList {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    type?: UIXTypeBundleAdUnitList.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitList
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    helpId?: UIXTypeBundleAdUnitList.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitList
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitList
     */
    suggestions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitList
     */
    minLength: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    minLengthError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitList
     */
    maxLength: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitList
     */
    msxLengthError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitList
 */
export namespace UIXTypeBundleAdUnitList {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitMediaTypeBannerName
 */
export interface UIXTypeBundleAdUnitMediaTypeBannerName {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    type?: UIXTypeBundleAdUnitMediaTypeBannerName.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    helpId?: UIXTypeBundleAdUnitMediaTypeBannerName.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeBannerName
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitMediaTypeBannerName
 */
export namespace UIXTypeBundleAdUnitMediaTypeBannerName {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitMediaTypeNativeBodyLen
 */
export interface UIXTypeBundleAdUnitMediaTypeNativeBodyLen {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    type?: UIXTypeBundleAdUnitMediaTypeNativeBodyLen.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    helpId?: UIXTypeBundleAdUnitMediaTypeNativeBodyLen.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeBodyLen
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitMediaTypeNativeBodyLen
 */
export namespace UIXTypeBundleAdUnitMediaTypeNativeBodyLen {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitMediaTypeNativeTitleLen
 */
export interface UIXTypeBundleAdUnitMediaTypeNativeTitleLen {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    type?: UIXTypeBundleAdUnitMediaTypeNativeTitleLen.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    helpId?: UIXTypeBundleAdUnitMediaTypeNativeTitleLen.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeTitleLen
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitMediaTypeNativeTitleLen
 */
export namespace UIXTypeBundleAdUnitMediaTypeNativeTitleLen {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleAdUnitMediaTypeNativeType
 */
export interface UIXTypeBundleAdUnitMediaTypeNativeType {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    type?: UIXTypeBundleAdUnitMediaTypeNativeType.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    helpId?: UIXTypeBundleAdUnitMediaTypeNativeType.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    defaultValue: UIXTypeBundleAdUnitMediaTypeNativeType.DefaultValueEnum;
    /**
     * 
     * @type {Array<UIXSelectOption>}
     * @memberof UIXTypeBundleAdUnitMediaTypeNativeType
     */
    options?: Array<UIXSelectOption>;
}

/**
 * @export
 * @namespace UIXTypeBundleAdUnitMediaTypeNativeType
 */
export namespace UIXTypeBundleAdUnitMediaTypeNativeType {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultValueEnum {
        Image = <any> 'image'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleBidderSequence
 */
export interface UIXTypeBundleBidderSequence {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    type?: UIXTypeBundleBidderSequence.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleBidderSequence
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    helpId?: UIXTypeBundleBidderSequence.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleBidderSequence
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleBidderSequence
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleBidderSequence
     */
    defaultValue: UIXTypeBundleBidderSequence.DefaultValueEnum;
    /**
     * 
     * @type {Array<UIXSelectOption>}
     * @memberof UIXTypeBundleBidderSequence
     */
    options?: Array<UIXSelectOption>;
}

/**
 * @export
 * @namespace UIXTypeBundleBidderSequence
 */
export namespace UIXTypeBundleBidderSequence {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultValueEnum {
        Random = <any> 'random',
        Fixed = <any> 'fixed'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleCookieSyncDelay
 */
export interface UIXTypeBundleCookieSyncDelay {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    type?: UIXTypeBundleCookieSyncDelay.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    helpId?: UIXTypeBundleCookieSyncDelay.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleCookieSyncDelay
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleCookieSyncDelay
 */
export namespace UIXTypeBundleCookieSyncDelay {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleDebug
 */
export interface UIXTypeBundleDebug {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDebug
     */
    type?: UIXTypeBundleDebug.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDebug
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDebug
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDebug
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDebug
     */
    helpId?: UIXTypeBundleDebug.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDebug
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDebug
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDebug
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleDebug
 */
export namespace UIXTypeBundleDebug {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleDescription
 */
export interface UIXTypeBundleDescription {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    type?: UIXTypeBundleDescription.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDescription
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    helpId?: UIXTypeBundleDescription.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDescription
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleDescription
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleDescription
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBundleDescription
 */
export namespace UIXTypeBundleDescription {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleDomain
 */
export interface UIXTypeBundleDomain extends UIXTypeString {
}

/**
 * @export
 * @namespace UIXTypeBundleDomain
 */
export namespace UIXTypeBundleDomain {
}
/**
 * 
 * @export
 * @interface UIXTypeBundleName
 */
export interface UIXTypeBundleName extends UIXTypeString {
}

/**
 * @export
 * @namespace UIXTypeBundleName
 */
export namespace UIXTypeBundleName {
}
/**
 * 
 * @export
 * @interface UIXTypeBundlePageLayoutId
 */
export interface UIXTypeBundlePageLayoutId {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    type?: UIXTypeBundlePageLayoutId.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundlePageLayoutId
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    helpId?: UIXTypeBundlePageLayoutId.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundlePageLayoutId
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundlePageLayoutId
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundlePageLayoutId
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundlePageLayoutId
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundlePageLayoutId
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundlePageLayoutId
 */
export namespace UIXTypeBundlePageLayoutId {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleSendAllBids
 */
export interface UIXTypeBundleSendAllBids {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleSendAllBids
     */
    type?: UIXTypeBundleSendAllBids.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleSendAllBids
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleSendAllBids
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleSendAllBids
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleSendAllBids
     */
    helpId?: UIXTypeBundleSendAllBids.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleSendAllBids
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleSendAllBids
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleSendAllBids
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleSendAllBids
 */
export namespace UIXTypeBundleSendAllBids {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleTags
 */
export interface UIXTypeBundleTags {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    type?: UIXTypeBundleTags.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTags
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    helpId?: UIXTypeBundleTags.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTags
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTags
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTags
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeBundleTags
 */
export namespace UIXTypeBundleTags {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleTimeout
 */
export interface UIXTypeBundleTimeout {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    type?: UIXTypeBundleTimeout.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTimeout
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    helpId?: UIXTypeBundleTimeout.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTimeout
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleTimeout
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleTimeout
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleTimeout
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleTimeout
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleTimeout
 */
export namespace UIXTypeBundleTimeout {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncEnableOverride
 */
export interface UIXTypeBundleUserSyncEnableOverride {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    type?: UIXTypeBundleUserSyncEnableOverride.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    helpId?: UIXTypeBundleUserSyncEnableOverride.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnableOverride
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncEnableOverride
 */
export namespace UIXTypeBundleUserSyncEnableOverride {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncEnabled
 */
export interface UIXTypeBundleUserSyncEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    type?: UIXTypeBundleUserSyncEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    helpId?: UIXTypeBundleUserSyncEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncEnabled
 */
export namespace UIXTypeBundleUserSyncEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncIFrameEnabled
 */
export interface UIXTypeBundleUserSyncIFrameEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    type?: UIXTypeBundleUserSyncIFrameEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    helpId?: UIXTypeBundleUserSyncIFrameEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncIFrameEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncIFrameEnabled
 */
export namespace UIXTypeBundleUserSyncIFrameEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncPerBidder
 */
export interface UIXTypeBundleUserSyncPerBidder {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    type?: UIXTypeBundleUserSyncPerBidder.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    helpId?: UIXTypeBundleUserSyncPerBidder.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPerBidder
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncPerBidder
 */
export namespace UIXTypeBundleUserSyncPerBidder {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncPixelEnabled
 */
export interface UIXTypeBundleUserSyncPixelEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    type?: UIXTypeBundleUserSyncPixelEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    helpId?: UIXTypeBundleUserSyncPixelEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncPixelEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncPixelEnabled
 */
export namespace UIXTypeBundleUserSyncPixelEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncSyncDelay
 */
export interface UIXTypeBundleUserSyncSyncDelay {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    type?: UIXTypeBundleUserSyncSyncDelay.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    helpId?: UIXTypeBundleUserSyncSyncDelay.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncDelay
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncSyncDelay
 */
export namespace UIXTypeBundleUserSyncSyncDelay {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeBundleUserSyncSyncsPerBidderAll
 */
export interface UIXTypeBundleUserSyncSyncsPerBidderAll {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    type?: UIXTypeBundleUserSyncSyncsPerBidderAll.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    helpId?: UIXTypeBundleUserSyncSyncsPerBidderAll.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeBundleUserSyncSyncsPerBidderAll
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeBundleUserSyncSyncsPerBidderAll
 */
export namespace UIXTypeBundleUserSyncSyncsPerBidderAll {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryCustom
 */
export interface UIXTypeMediaQueryCustom {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustom
     */
    type?: UIXTypeMediaQueryCustom.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustom
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustom
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustom
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustom
     */
    helpId?: UIXTypeMediaQueryCustom.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustom
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustom
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustom
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryCustom
 */
export namespace UIXTypeMediaQueryCustom {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryCustomQuery
 */
export interface UIXTypeMediaQueryCustomQuery {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    type?: UIXTypeMediaQueryCustomQuery.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    helpId?: UIXTypeMediaQueryCustomQuery.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryCustomQuery
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryCustomQuery
 */
export namespace UIXTypeMediaQueryCustomQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryFeatureTestFeature
 */
export interface UIXTypeMediaQueryFeatureTestFeature {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    type?: UIXTypeMediaQueryFeatureTestFeature.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    helpId?: UIXTypeMediaQueryFeatureTestFeature.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    defaultValue: UIXTypeMediaQueryFeatureTestFeature.DefaultValueEnum;
    /**
     * 
     * @type {Array<UIXSelectOption>}
     * @memberof UIXTypeMediaQueryFeatureTestFeature
     */
    options?: Array<UIXSelectOption>;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryFeatureTestFeature
 */
export namespace UIXTypeMediaQueryFeatureTestFeature {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultValueEnum {
        Width = <any> 'width',
        Height = <any> 'height',
        AspectRatio = <any> 'aspectRatio',
        Orientation = <any> 'orientation',
        Resolution = <any> 'resolution',
        Scan = <any> 'scan',
        Grid = <any> 'grid',
        Update = <any> 'update',
        OverflowBlock = <any> 'overflowBlock',
        OverflowInline = <any> 'overflowInline',
        Color = <any> 'color',
        ColorGamut = <any> 'colorGamut',
        ColorIndex = <any> 'colorIndex',
        DisplayMode = <any> 'displayMode',
        Monochrome = <any> 'monochrome',
        InvertedColors = <any> 'invertedColors',
        Pointer = <any> 'pointer',
        Hover = <any> 'hover',
        AnyPointer = <any> 'anyPointer',
        AnyHover = <any> 'anyHover',
        LightLevel = <any> 'lightLevel',
        PrefersReducedMotion = <any> 'prefersReducedMotion',
        PrefersReducedTransparency = <any> 'prefersReducedTransparency',
        PrefersContrast = <any> 'prefersContrast',
        PrefersColorScheme = <any> 'prefersColorScheme',
        Scripting = <any> 'scripting',
        DeviceWidth = <any> 'deviceWidth',
        DeviceHeight = <any> 'deviceHeight',
        DeviceAspectRatio = <any> 'deviceAspectRatio'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryFeatureTestOperation
 */
export interface UIXTypeMediaQueryFeatureTestOperation {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    type?: UIXTypeMediaQueryFeatureTestOperation.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    helpId?: UIXTypeMediaQueryFeatureTestOperation.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    defaultValue: UIXTypeMediaQueryFeatureTestOperation.DefaultValueEnum;
    /**
     * 
     * @type {Array<UIXSelectOption>}
     * @memberof UIXTypeMediaQueryFeatureTestOperation
     */
    options?: Array<UIXSelectOption>;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryFeatureTestOperation
 */
export namespace UIXTypeMediaQueryFeatureTestOperation {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultValueEnum {
        Width = <any> 'width',
        Height = <any> 'height',
        AspectRatio = <any> 'aspectRatio',
        Orientation = <any> 'orientation',
        Resolution = <any> 'resolution',
        Scan = <any> 'scan',
        Grid = <any> 'grid',
        Update = <any> 'update',
        OverflowBlock = <any> 'overflowBlock',
        OverflowInline = <any> 'overflowInline',
        Color = <any> 'color',
        ColorGamut = <any> 'colorGamut',
        ColorIndex = <any> 'colorIndex',
        DisplayMode = <any> 'displayMode',
        Monochrome = <any> 'monochrome',
        InvertedColors = <any> 'invertedColors',
        Pointer = <any> 'pointer',
        Hover = <any> 'hover',
        AnyPointer = <any> 'anyPointer',
        AnyHover = <any> 'anyHover',
        LightLevel = <any> 'lightLevel',
        PrefersReducedMotion = <any> 'prefersReducedMotion',
        PrefersReducedTransparency = <any> 'prefersReducedTransparency',
        PrefersContrast = <any> 'prefersContrast',
        PrefersColorScheme = <any> 'prefersColorScheme',
        Scripting = <any> 'scripting',
        DeviceWidth = <any> 'deviceWidth',
        DeviceHeight = <any> 'deviceHeight',
        DeviceAspectRatio = <any> 'deviceAspectRatio'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryFeatureTestValue
 */
export interface UIXTypeMediaQueryFeatureTestValue {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    type?: UIXTypeMediaQueryFeatureTestValue.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    helpId?: UIXTypeMediaQueryFeatureTestValue.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryFeatureTestValue
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryFeatureTestValue
 */
export namespace UIXTypeMediaQueryFeatureTestValue {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryMatchCustom
 */
export interface UIXTypeMediaQueryMatchCustom {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    type?: UIXTypeMediaQueryMatchCustom.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    helpId?: UIXTypeMediaQueryMatchCustom.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustom
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryMatchCustom
 */
export namespace UIXTypeMediaQueryMatchCustom {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryMatchCustomQuery
 */
export interface UIXTypeMediaQueryMatchCustomQuery {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    type?: UIXTypeMediaQueryMatchCustomQuery.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    helpId?: UIXTypeMediaQueryMatchCustomQuery.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryMatchCustomQuery
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryMatchCustomQuery
 */
export namespace UIXTypeMediaQueryMatchCustomQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaQueryNot
 */
export interface UIXTypeMediaQueryNot {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryNot
     */
    type?: UIXTypeMediaQueryNot.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryNot
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryNot
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryNot
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaQueryNot
     */
    helpId?: UIXTypeMediaQueryNot.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryNot
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryNot
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaQueryNot
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaQueryNot
 */
export namespace UIXTypeMediaQueryNot {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaSizeHeight
 */
export interface UIXTypeMediaSizeHeight {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    type?: UIXTypeMediaSizeHeight.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeHeight
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    helpId?: UIXTypeMediaSizeHeight.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeHeight
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeHeight
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeMediaSizeHeight
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeMediaSizeHeight
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeHeight
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeMediaSizeHeight
 */
export namespace UIXTypeMediaSizeHeight {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaSizeOptionalEnabled
 */
export interface UIXTypeMediaSizeOptionalEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    type?: UIXTypeMediaSizeOptionalEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    helpId?: UIXTypeMediaSizeOptionalEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeOptionalEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaSizeOptionalEnabled
 */
export namespace UIXTypeMediaSizeOptionalEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaSizeWidth
 */
export interface UIXTypeMediaSizeWidth {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    type?: UIXTypeMediaSizeWidth.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeWidth
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    helpId?: UIXTypeMediaSizeWidth.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeWidth
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaSizeWidth
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeMediaSizeWidth
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    minValueError: string;
    /**
     * 
     * @type {number}
     * @memberof UIXTypeMediaSizeWidth
     */
    maxValue: number;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    maxValueError: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaSizeWidth
     */
    formatError: string;
}

/**
 * @export
 * @namespace UIXTypeMediaSizeWidth
 */
export namespace UIXTypeMediaSizeWidth {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeBannerEnabled
 */
export interface UIXTypeMediaTypeBannerEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    type?: UIXTypeMediaTypeBannerEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    helpId?: UIXTypeMediaTypeBannerEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeBannerEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeBannerEnabled
 */
export namespace UIXTypeMediaTypeBannerEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeBodyRequired
 */
export interface UIXTypeMediaTypeNativeBodyRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    type?: UIXTypeMediaTypeNativeBodyRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    helpId?: UIXTypeMediaTypeNativeBodyRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeBodyRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeBodyRequired
 */
export namespace UIXTypeMediaTypeNativeBodyRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeClickUrlRequired
 */
export interface UIXTypeMediaTypeNativeClickUrlRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    type?: UIXTypeMediaTypeNativeClickUrlRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    helpId?: UIXTypeMediaTypeNativeClickUrlRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeClickUrlRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeClickUrlRequired
 */
export namespace UIXTypeMediaTypeNativeClickUrlRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeCtaRequired
 */
export interface UIXTypeMediaTypeNativeCtaRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    type?: UIXTypeMediaTypeNativeCtaRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    helpId?: UIXTypeMediaTypeNativeCtaRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeCtaRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeCtaRequired
 */
export namespace UIXTypeMediaTypeNativeCtaRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeEnabled
 */
export interface UIXTypeMediaTypeNativeEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    type?: UIXTypeMediaTypeNativeEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    helpId?: UIXTypeMediaTypeNativeEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeEnabled
 */
export namespace UIXTypeMediaTypeNativeEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeIconRequired
 */
export interface UIXTypeMediaTypeNativeIconRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    type?: UIXTypeMediaTypeNativeIconRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    helpId?: UIXTypeMediaTypeNativeIconRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeIconRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeIconRequired
 */
export namespace UIXTypeMediaTypeNativeIconRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeImageRequired
 */
export interface UIXTypeMediaTypeNativeImageRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    type?: UIXTypeMediaTypeNativeImageRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    helpId?: UIXTypeMediaTypeNativeImageRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeImageRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeImageRequired
 */
export namespace UIXTypeMediaTypeNativeImageRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeSponsoredByRequired
 */
export interface UIXTypeMediaTypeNativeSponsoredByRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    type?: UIXTypeMediaTypeNativeSponsoredByRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    helpId?: UIXTypeMediaTypeNativeSponsoredByRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeSponsoredByRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeSponsoredByRequired
 */
export namespace UIXTypeMediaTypeNativeSponsoredByRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeNativeTitleRequired
 */
export interface UIXTypeMediaTypeNativeTitleRequired {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    type?: UIXTypeMediaTypeNativeTitleRequired.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    helpId?: UIXTypeMediaTypeNativeTitleRequired.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeNativeTitleRequired
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeNativeTitleRequired
 */
export namespace UIXTypeMediaTypeNativeTitleRequired {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeMediaTypeVideoEnabled
 */
export interface UIXTypeMediaTypeVideoEnabled {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    type?: UIXTypeMediaTypeVideoEnabled.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    helpId?: UIXTypeMediaTypeVideoEnabled.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    suggestions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeMediaTypeVideoEnabled
     */
    defaultValue: boolean;
}

/**
 * @export
 * @namespace UIXTypeMediaTypeVideoEnabled
 */
export namespace UIXTypeMediaTypeVideoEnabled {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeSizeConfigLabel
 */
export interface UIXTypeSizeConfigLabel {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    type?: UIXTypeSizeConfigLabel.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeSizeConfigLabel
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    helpId?: UIXTypeSizeConfigLabel.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeSizeConfigLabel
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeSizeConfigLabel
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeSizeConfigLabel
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeSizeConfigLabel
 */
export namespace UIXTypeSizeConfigLabel {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeString
 */
export interface UIXTypeString {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    type?: UIXTypeString.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeString
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    helpId?: UIXTypeString.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeString
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeString
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeString
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeString
 */
export namespace UIXTypeString {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeVideoContext
 */
export interface UIXTypeVideoContext {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    type?: UIXTypeVideoContext.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoContext
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    helpId?: UIXTypeVideoContext.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoContext
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoContext
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    leftIcon: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    rightActionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoContext
     */
    defaultValue: UIXTypeVideoContext.DefaultValueEnum;
    /**
     * 
     * @type {Array<UIXSelectOption>}
     * @memberof UIXTypeVideoContext
     */
    options?: Array<UIXSelectOption>;
}

/**
 * @export
 * @namespace UIXTypeVideoContext
 */
export namespace UIXTypeVideoContext {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultValueEnum {
        Instream = <any> 'instream',
        Outstream = <any> 'outstream'
    }
}
/**
 * 
 * @export
 * @interface UIXTypeVideoMime
 */
export interface UIXTypeVideoMime {
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    type?: UIXTypeVideoMime.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoMime
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    requiredError?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    helpId?: UIXTypeVideoMime.HelpIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoMime
     */
    typeahead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIXTypeVideoMime
     */
    suggestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    leftIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    rightActionLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    regexpValidation?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXTypeVideoMime
     */
    regexpValidationError?: string;
}

/**
 * @export
 * @namespace UIXTypeVideoMime
 */
export namespace UIXTypeVideoMime {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BundleNume = <any> 'bundleNume',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum HelpIdEnum {
        BundleName = <any> 'bundleName',
        BundleDomain = <any> 'bundleDomain',
        PageLayoutId = <any> 'pageLayoutId',
        BundleCreateForm = <any> 'bundleCreateForm',
        BundleTags = <any> 'bundleTags',
        BundleDescription = <any> 'bundleDescription',
        BidderComments = <any> 'bidderComments',
        BidderDemo1Key = <any> 'bidderDemo1Key',
        BidderSequence = <any> 'bidderSequence',
        CookieSyncDelay = <any> 'cookieSyncDelay',
        AuctionTimeout = <any> 'auctionTimeout',
        Debug = <any> 'debug',
        SendAllBids = <any> 'sendAllBids',
        BundleUserSyncEnabled = <any> 'bundleUserSyncEnabled',
        BundleUserSyncIFrameEnabled = <any> 'bundleUserSyncIFrameEnabled',
        BundleUserSyncPixelEnabled = <any> 'bundleUserSyncPixelEnabled',
        BundleUserSyncSyncsPerBidderAll = <any> 'bundleUserSyncSyncsPerBidderAll',
        BundleUserSyncSyncsPerBidder = <any> 'bundleUserSyncSyncsPerBidder',
        BundleUserSyncSyncDelay = <any> 'bundleUserSyncSyncDelay',
        BundleUserSyncEnableOverride = <any> 'bundleUserSyncEnableOverride',
        BundleAdUnitCode = <any> 'bundleAdUnitCode',
        BundleAdUnitMediaSizeId = <any> 'bundleAdUnitMediaSizeId',
        BundleAdUnitMediaSizeName = <any> 'bundleAdUnitMediaSizeName',
        BundleAdUnitMediaSizeCategories = <any> 'bundleAdUnitMediaSizeCategories',
        BundleAdUnitMediaQueryId = <any> 'bundleAdUnitMediaQueryId',
        BundleAdUnitList = <any> 'bundleAdUnitList',
        BundleAdUnitMediaSizeList = <any> 'bundleAdUnitMediaSizeList',
        MediaQueryCustomQuery = <any> 'mediaQueryCustomQuery',
        MediaQueryFeatureTestFeature = <any> 'mediaQueryFeatureTestFeature',
        MediaQueryFeatureTestOperation = <any> 'mediaQueryFeatureTestOperation',
        AspectRatioMinWidth = <any> 'aspectRatioMinWidth',
        AspectRatioRatioHeigh = <any> 'aspectRatioRatioHeigh',
        AspectRatioRatioWidth = <any> 'aspectRatioRatioWidth',
        MediaTypeBannerName = <any> 'mediaTypeBannerName',
        MediaTypeNativeTitleLen = <any> 'mediaTypeNativeTitleLen',
        MediaTypeNativeBodyLen = <any> 'mediaTypeNativeBodyLen',
        MediaTypeNativeType = <any> 'mediaTypeNativeType',
        SizeConfigLabel = <any> 'sizeConfigLabel',
        VideoContext = <any> 'videoContext',
        VideoMime = <any> 'videoMime',
        MediaQueryCustom = <any> 'mediaQueryCustom',
        BidDemo1CompanyId = <any> 'bidDemo1CompanyId',
        BidAppnexusPlacementId = <any> 'bidAppnexusPlacementId',
        BundleAdUnitBidList = <any> 'bundleAdUnitBidList',
        RegistationEmail = <any> 'registationEmail',
        RegistationPassword = <any> 'registationPassword',
        RegistationReferralCode = <any> 'registationReferralCode'
    }
}
/**
 * 
 * @export
 * @interface UIXValueBoolean
 */
export interface UIXValueBoolean {
    /**
     * 
     * @type {string}
     * @memberof UIXValueBoolean
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueBoolean
     */
    errorLevel?: UIXValueBoolean.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIXValueBoolean
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UIXValueBoolean
     */
    value?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXValueBoolean
     */
    error?: string;
}

/**
 * @export
 * @namespace UIXValueBoolean
 */
export namespace UIXValueBoolean {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIXValueEnumBMBidderSequenceType
 */
export interface UIXValueEnumBMBidderSequenceType {
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBMBidderSequenceType
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBMBidderSequenceType
     */
    errorLevel?: UIXValueEnumBMBidderSequenceType.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIXValueEnumBMBidderSequenceType
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBMBidderSequenceType
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBMBidderSequenceType
     */
    error?: string;
}

/**
 * @export
 * @namespace UIXValueEnumBMBidderSequenceType
 */
export namespace UIXValueEnumBMBidderSequenceType {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIXValueEnumBillingPlan
 */
export interface UIXValueEnumBillingPlan {
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBillingPlan
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBillingPlan
     */
    errorLevel?: UIXValueEnumBillingPlan.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIXValueEnumBillingPlan
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBillingPlan
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueEnumBillingPlan
     */
    error?: string;
}

/**
 * @export
 * @namespace UIXValueEnumBillingPlan
 */
export namespace UIXValueEnumBillingPlan {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIXValueInteger
 */
export interface UIXValueInteger {
    /**
     * 
     * @type {string}
     * @memberof UIXValueInteger
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueInteger
     */
    errorLevel?: UIXValueInteger.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIXValueInteger
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIXValueInteger
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueInteger
     */
    error?: string;
}

/**
 * @export
 * @namespace UIXValueInteger
 */
export namespace UIXValueInteger {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UIXValueString
 */
export interface UIXValueString {
    /**
     * 
     * @type {string}
     * @memberof UIXValueString
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UIXValueString
     */
    errorLevel?: UIXValueString.ErrorLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UIXValueString
     */
    typeahead?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UIXValueString
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof UIXValueString
     */
    empty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIXValueString
     */
    error?: string;
}

/**
 * @export
 * @namespace UIXValueString
 */
export namespace UIXValueString {
    /**
     * @export
     * @enum {string}
     */
    export enum ErrorLevelEnum {
        Success = <any> 'success',
        Info = <any> 'info',
        Warning = <any> 'warning',
        Danger = <any> 'danger',
        Secondary = <any> 'secondary',
        Primary = <any> 'primary',
        Disabled = <any> 'disabled',
        Error = <any> 'error'
    }
}
/**
 * 
 * @export
 * @interface UTMChartData
 */
export interface UTMChartData {
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    usdRevenueByUTM: Array<UTMStatisticsBean>;
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    eurRevenueByUTM: Array<UTMStatisticsBean>;
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    usdEcpmByUTM: Array<UTMStatisticsBean>;
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    eurEcpmByUTM: Array<UTMStatisticsBean>;
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    winRateByUTM: Array<UTMStatisticsBean>;
    /**
     * 
     * @type {Array<UTMStatisticsBean>}
     * @memberof UTMChartData
     */
    imprsByUTM: Array<UTMStatisticsBean>;
}
/**
 * 
 * @export
 * @interface UTMStatisticsBean
 */
export interface UTMStatisticsBean {
    /**
     * 
     * @type {string}
     * @memberof UTMStatisticsBean
     */
    utmStatisticsParameter: string;
    /**
     * 
     * @type {number}
     * @memberof UTMStatisticsBean
     */
    metricValueByUTM: number;
}
/**
 * 
 * @export
 * @interface UpdateForm
 */
export interface UpdateForm {
    /**
     * 
     * @type {UIXValueString}
     * @memberof UpdateForm
     */
    workspace?: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UpdateForm
     */
    crmId?: UIXValueString;
    /**
     * 
     * @type {UIXValueString}
     * @memberof UpdateForm
     */
    name?: UIXValueString;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateForm
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateResponseAdmin
 */
export interface UpdateResponseAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResponseAdmin
     */
    success?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResponseAdmin
     */
    emailNotFound?: boolean;
    /**
     * 
     * @type {UpdateForm}
     * @memberof UpdateResponseAdmin
     */
    form?: UpdateForm;
    /**
     * 
     * @type {number}
     * @memberof UpdateResponseAdmin
     */
    accountId?: number;
}
/**
 * 
 * @export
 * @interface VersionChartData
 */
export interface VersionChartData {
    /**
     * 
     * @type {Array<VersionHistoryBean>}
     * @memberof VersionChartData
     */
    versionStats: Array<VersionHistoryBean>;
    /**
     * 
     * @type {Array<AxisLabel>}
     * @memberof VersionChartData
     */
    axisX: Array<AxisLabel>;
}
/**
 * 
 * @export
 * @interface VersionHistoryBean
 */
export interface VersionHistoryBean {
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    bidsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    winsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    impressionsHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    revenueHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    eCPMHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    bidPriceHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    winRateHistory: Array<StatValueSnapshot>;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryBean
     */
    version: string;
    /**
     * 
     * @type {Array<StatValueSnapshot>}
     * @memberof VersionHistoryBean
     */
    ecpmhistory?: Array<StatValueSnapshot>;
}
/**
 * 
 * @export
 * @interface XYChartData
 */
export interface XYChartData {
    /**
     * 
     * @type {Array<string>}
     * @memberof XYChartData
     */
    xlabels?: Array<string>;
    /**
     * 
     * @type {Array<YValues>}
     * @memberof XYChartData
     */
    yvalues?: Array<YValues>;
}
/**
 * 
 * @export
 * @interface YValues
 */
export interface YValues {
    /**
     * 
     * @type {Array<number>}
     * @memberof YValues
     */
    valuesByX: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof YValues
     */
    ylabel?: string;
}
/**
 * 
 * @export
 * @interface YValuesDouble
 */
export interface YValuesDouble {
    /**
     * 
     * @type {Array<number>}
     * @memberof YValuesDouble
     */
    valuesByX: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof YValuesDouble
     */
    ylabel?: string;
}
/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
export const AccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo1(options: any = {}): FetchArgs {
            const localVarPath = `/api/account/account-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount1(body: AccountBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editAccount1.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAccount1.');
            }
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters1(options: any = {}): FetchArgs {
            const localVarPath = `/api/account/account/custom-filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).accountInfo1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount1(body: AccountBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).editAccount1(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomFiltersBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).getCustomFilters1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo1(options?: any) {
            return AccountControllerApiFp(configuration).accountInfo1(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount1(body: AccountBean, id: number, options?: any) {
            return AccountControllerApiFp(configuration).editAccount1(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters1(options?: any) {
            return AccountControllerApiFp(configuration).getCustomFilters1(options)(fetch, basePath);
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountInfo1(options?: any) {
        return AccountControllerApiFp(this.configuration).accountInfo1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccountBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public editAccount1(body: AccountBean, id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).editAccount1(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getCustomFilters1(options?: any) {
        return AccountControllerApiFp(this.configuration).getCustomFilters1(options)(this.fetch, this.basePath);
    }

}
/**
 * AdUnitControllerApi - fetch parameter creator
 * @export
 */
export const AdUnitControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options: any = {}): FetchArgs {
            const localVarPath = `/api/adunit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitDictBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAdUnit.');
            }
            const localVarPath = `/api/adunit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdUnitDictBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdUnit.');
            }
            const localVarPath = `/api/adunit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdUnit(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAdUnit.');
            }
            const localVarPath = `/api/adunit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitDictBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAdUnit.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAdUnit.');
            }
            const localVarPath = `/api/adunit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdUnitDictBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdUnitControllerApi - functional programming interface
 * @export
 */
export const AdUnitControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdUnitDictBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).adUnitList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitDictBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdUnitDictBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).createAdUnit(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).deleteAdUnit(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdUnit(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdUnitDictBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).getAdUnit(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitDictBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdUnitDictBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).updateAdUnit(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdUnitControllerApi - factory interface
 * @export
 */
export const AdUnitControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options?: any) {
            return AdUnitControllerApiFp(configuration).adUnitList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitDictBean, options?: any) {
            return AdUnitControllerApiFp(configuration).createAdUnit(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).deleteAdUnit(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdUnit(id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).getAdUnit(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdUnitDictBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitDictBean, id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).updateAdUnit(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AdUnitControllerApi - object-oriented interface
 * @export
 * @class AdUnitControllerApi
 * @extends {BaseAPI}
 */
export class AdUnitControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public adUnitList(options?: any) {
        return AdUnitControllerApiFp(this.configuration).adUnitList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdUnitDictBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public createAdUnit(body: AdUnitDictBean, options?: any) {
        return AdUnitControllerApiFp(this.configuration).createAdUnit(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public deleteAdUnit(id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).deleteAdUnit(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public getAdUnit(id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).getAdUnit(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdUnitDictBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public updateAdUnit(body: AdUnitDictBean, id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).updateAdUnit(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminAccountControllerApi - fetch parameter creator
 * @export
 */
export const AdminAccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account1(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling account1.');
            }
            const localVarPath = `/api/admin/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByAdmin(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling blockStatByAdmin.');
            }
            const localVarPath = `/api/admin/customer/blockStat/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomersSearchQueryBean} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customers(query: CustomersSearchQueryBean, options: any = {}): FetchArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling customers.');
            }
            const localVarPath = `/api/admin/customers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAccount.');
            }
            const localVarPath = `/api/admin/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableCustomPayment(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling disableCustomPayment.');
            }
            const localVarPath = `/api/admin/customer/disableCustomPayment/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount(body: AccountBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editAccount.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAccount.');
            }
            const localVarPath = `/api/admin/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableCustomPayment(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling enableCustomPayment.');
            }
            const localVarPath = `/api/admin/customer/enableCustomPayment/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAdmin(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling loginAsPublisherByAdmin.');
            }
            const localVarPath = `/api/admin/customer/login-as/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBillingProblem(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling resetBillingProblem.');
            }
            const localVarPath = `/api/admin/customer/unblockStat/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAccountControllerApi - functional programming interface
 * @export
 */
export const AdminAccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account1(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).account1(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAccountBean> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).accounts(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByAdmin(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).blockStatByAdmin(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CustomersSearchQueryBean} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customers(query: CustomersSearchQueryBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseCustomerBean> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).customers(query, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).deleteAccount(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableCustomPayment(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).disableCustomPayment(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount(body: AccountBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).editAccount(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableCustomPayment(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).enableCustomPayment(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAdmin(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseString> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).loginAsPublisherByAdmin(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBillingProblem(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).resetBillingProblem(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminAccountControllerApi - factory interface
 * @export
 */
export const AdminAccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account1(id: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).account1(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(options?: any) {
            return AdminAccountControllerApiFp(configuration).accounts(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByAdmin(userId: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).blockStatByAdmin(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CustomersSearchQueryBean} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customers(query: CustomersSearchQueryBean, options?: any) {
            return AdminAccountControllerApiFp(configuration).customers(query, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).deleteAccount(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableCustomPayment(userId: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).disableCustomPayment(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccount(body: AccountBean, id: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).editAccount(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableCustomPayment(userId: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).enableCustomPayment(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAdmin(userId: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).loginAsPublisherByAdmin(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBillingProblem(userId: number, options?: any) {
            return AdminAccountControllerApiFp(configuration).resetBillingProblem(userId, options)(fetch, basePath);
        },
    };
};

/**
 * AdminAccountControllerApi - object-oriented interface
 * @export
 * @class AdminAccountControllerApi
 * @extends {BaseAPI}
 */
export class AdminAccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public account1(id: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).account1(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public accounts(options?: any) {
        return AdminAccountControllerApiFp(this.configuration).accounts(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public blockStatByAdmin(userId: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).blockStatByAdmin(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CustomersSearchQueryBean} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public customers(query: CustomersSearchQueryBean, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).customers(query, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public deleteAccount(id: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).deleteAccount(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public disableCustomPayment(userId: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).disableCustomPayment(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccountBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public editAccount(body: AccountBean, id: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).editAccount(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public enableCustomPayment(userId: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).enableCustomPayment(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public loginAsPublisherByAdmin(userId: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).loginAsPublisherByAdmin(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public resetBillingProblem(userId: number, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).resetBillingProblem(userId, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminAgencyControllerApi - fetch parameter creator
 * @export
 */
export const AdminAgencyControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgency(body: AgencyBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAgency.');
            }
            const localVarPath = `/api/admin/agency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AgencyBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAgency.');
            }
            const localVarPath = `/api/admin/agency/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAgency.');
            }
            const localVarPath = `/api/admin/agency/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgencies(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/agency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsManagerByAdmin(stringId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stringId' is not null or undefined
            if (stringId === null || stringId === undefined) {
                throw new RequiredError('stringId','Required parameter stringId was null or undefined when calling loginAsManagerByAdmin.');
            }
            const localVarPath = `/api/admin/agency/login-as/{stringId}`
                .replace(`{${"stringId"}}`, encodeURIComponent(String(stringId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgency(body: AgencyBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAgency.');
            }
            const localVarPath = `/api/admin/agency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AgencyBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAgencyControllerApi - functional programming interface
 * @export
 */
export const AdminAgencyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgency(body: AgencyBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAgencyBean> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).createAgency(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).deleteAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAgencyBean> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).getAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgencies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAgencyBean> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).getAllAgencies(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} stringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsManagerByAdmin(stringId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseString> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).loginAsManagerByAdmin(stringId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgency(body: AgencyBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAgencyBean> {
            const localVarFetchArgs = AdminAgencyControllerApiFetchParamCreator(configuration).updateAgency(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminAgencyControllerApi - factory interface
 * @export
 */
export const AdminAgencyControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgency(body: AgencyBean, options?: any) {
            return AdminAgencyControllerApiFp(configuration).createAgency(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgency(id: number, options?: any) {
            return AdminAgencyControllerApiFp(configuration).deleteAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgency(id: number, options?: any) {
            return AdminAgencyControllerApiFp(configuration).getAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgencies(options?: any) {
            return AdminAgencyControllerApiFp(configuration).getAllAgencies(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} stringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsManagerByAdmin(stringId: string, options?: any) {
            return AdminAgencyControllerApiFp(configuration).loginAsManagerByAdmin(stringId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AgencyBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgency(body: AgencyBean, options?: any) {
            return AdminAgencyControllerApiFp(configuration).updateAgency(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminAgencyControllerApi - object-oriented interface
 * @export
 * @class AdminAgencyControllerApi
 * @extends {BaseAPI}
 */
export class AdminAgencyControllerApi extends BaseAPI {
    /**
     * 
     * @param {AgencyBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public createAgency(body: AgencyBean, options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).createAgency(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public deleteAgency(id: number, options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).deleteAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public getAgency(id: number, options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).getAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public getAllAgencies(options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).getAllAgencies(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} stringId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public loginAsManagerByAdmin(stringId: string, options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).loginAsManagerByAdmin(stringId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AgencyBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyControllerApi
     */
    public updateAgency(body: AgencyBean, options?: any) {
        return AdminAgencyControllerApiFp(this.configuration).updateAgency(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminControllerApi - fetch parameter creator
 * @export
 */
export const AdminControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/accountInfo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admin(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling admin.');
            }
            const localVarPath = `/api/admin/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/admin-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(body: AdminBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAdmin.');
            }
            const localVarPath = `/api/admin/admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdmin.');
            }
            const localVarPath = `/api/admin/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdmin(body: AdminBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editAdmin.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAdmin.');
            }
            const localVarPath = `/api/admin/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).accountInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admin(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).admin(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).adminInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).admins(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(body: AdminBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).createAdmin(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deleteAdmin(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdmin(body: AdminBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAdminBean> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).editAdmin(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInfo(options?: any) {
            return AdminControllerApiFp(configuration).accountInfo(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admin(id: number, options?: any) {
            return AdminControllerApiFp(configuration).admin(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo(options?: any) {
            return AdminControllerApiFp(configuration).adminInfo(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(options?: any) {
            return AdminControllerApiFp(configuration).admins(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(body: AdminBean, options?: any) {
            return AdminControllerApiFp(configuration).createAdmin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin(id: number, options?: any) {
            return AdminControllerApiFp(configuration).deleteAdmin(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdminBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdmin(body: AdminBean, id: number, options?: any) {
            return AdminControllerApiFp(configuration).editAdmin(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public accountInfo(options?: any) {
        return AdminControllerApiFp(this.configuration).accountInfo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public admin(id: number, options?: any) {
        return AdminControllerApiFp(this.configuration).admin(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public adminInfo(options?: any) {
        return AdminControllerApiFp(this.configuration).adminInfo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public admins(options?: any) {
        return AdminControllerApiFp(this.configuration).admins(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdminBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createAdmin(body: AdminBean, options?: any) {
        return AdminControllerApiFp(this.configuration).createAdmin(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteAdmin(id: number, options?: any) {
        return AdminControllerApiFp(this.configuration).deleteAdmin(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdminBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public editAdmin(body: AdminBean, id: number, options?: any) {
        return AdminControllerApiFp(this.configuration).editAdmin(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminManagerControllerApi - fetch parameter creator
 * @export
 */
export const AdminManagerControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagerAdminApi(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteManagerAdminApi.');
            }
            const localVarPath = `/api/admin/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagersAdminApi(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/manager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminApi(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getManagerAdminApi.');
            }
            const localVarPath = `/api/admin/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagerAdminApi(body: ManagerBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateManagerAdminApi.');
            }
            const localVarPath = `/api/admin/manager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ManagerBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminManagerControllerApi - functional programming interface
 * @export
 */
export const AdminManagerControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagerAdminApi(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminManagerControllerApiFetchParamCreator(configuration).deleteManagerAdminApi(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagersAdminApi(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseManagerBean> {
            const localVarFetchArgs = AdminManagerControllerApiFetchParamCreator(configuration).getAllManagersAdminApi(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminApi(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseManagerBean> {
            const localVarFetchArgs = AdminManagerControllerApiFetchParamCreator(configuration).getManagerAdminApi(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagerAdminApi(body: ManagerBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseManagerBean> {
            const localVarFetchArgs = AdminManagerControllerApiFetchParamCreator(configuration).updateManagerAdminApi(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminManagerControllerApi - factory interface
 * @export
 */
export const AdminManagerControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagerAdminApi(id: number, options?: any) {
            return AdminManagerControllerApiFp(configuration).deleteManagerAdminApi(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagersAdminApi(options?: any) {
            return AdminManagerControllerApiFp(configuration).getAllManagersAdminApi(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminApi(id: number, options?: any) {
            return AdminManagerControllerApiFp(configuration).getManagerAdminApi(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagerAdminApi(body: ManagerBean, options?: any) {
            return AdminManagerControllerApiFp(configuration).updateManagerAdminApi(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminManagerControllerApi - object-oriented interface
 * @export
 * @class AdminManagerControllerApi
 * @extends {BaseAPI}
 */
export class AdminManagerControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerControllerApi
     */
    public deleteManagerAdminApi(id: number, options?: any) {
        return AdminManagerControllerApiFp(this.configuration).deleteManagerAdminApi(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerControllerApi
     */
    public getAllManagersAdminApi(options?: any) {
        return AdminManagerControllerApiFp(this.configuration).getAllManagersAdminApi(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerControllerApi
     */
    public getManagerAdminApi(id: number, options?: any) {
        return AdminManagerControllerApiFp(this.configuration).getManagerAdminApi(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ManagerBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerControllerApi
     */
    public updateManagerAdminApi(body: ManagerBean, options?: any) {
        return AdminManagerControllerApiFp(this.configuration).updateManagerAdminApi(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AgencyAccountControllerApi - fetch parameter creator
 * @export
 */
export const AgencyAccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountByAgency(body: AccountBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccountByAgency.');
            }
            const localVarPath = `/api/agency/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountByAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAccountByAgency.');
            }
            const localVarPath = `/api/agency/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccountByAgency(body: AccountBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editAccountByAgency.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAccountByAgency.');
            }
            const localVarPath = `/api/agency/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAccountByAgency.');
            }
            const localVarPath = `/api/agency/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [status] 
         * @param {string} [sortById] 
         * @param {string} [sortByStatus] 
         * @param {string} [sortByLastLoginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsPageByAgency(name?: string, email?: string, status?: string, sortById?: string, sortByStatus?: string, sortByLastLoginDate?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sortById !== undefined) {
                localVarQueryParameter['sortById'] = sortById;
            }

            if (sortByStatus !== undefined) {
                localVarQueryParameter['sortByStatus'] = sortByStatus;
            }

            if (sortByLastLoginDate !== undefined) {
                localVarQueryParameter['sortByLastLoginDate'] = sortByLastLoginDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAgency(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling loginAsPublisherByAgency.');
            }
            const localVarPath = `/api/agency/account/login-as/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyAccountControllerApi - functional programming interface
 * @export
 */
export const AgencyAccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountByAgency(body: AccountBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).createAccountByAgency(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountByAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).deleteAccountByAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccountByAgency(body: AccountBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).editAccountByAgency(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).getAccountByAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [status] 
         * @param {string} [sortById] 
         * @param {string} [sortByStatus] 
         * @param {string} [sortByLastLoginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsPageByAgency(name?: string, email?: string, status?: string, sortById?: string, sortByStatus?: string, sortByLastLoginDate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAccountBean> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).getAccountsPageByAgency(name, email, status, sortById, sortByStatus, sortByLastLoginDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAgency(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseString> {
            const localVarFetchArgs = AgencyAccountControllerApiFetchParamCreator(configuration).loginAsPublisherByAgency(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AgencyAccountControllerApi - factory interface
 * @export
 */
export const AgencyAccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountByAgency(body: AccountBean, options?: any) {
            return AgencyAccountControllerApiFp(configuration).createAccountByAgency(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountByAgency(id: number, options?: any) {
            return AgencyAccountControllerApiFp(configuration).deleteAccountByAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAccountByAgency(body: AccountBean, id: number, options?: any) {
            return AgencyAccountControllerApiFp(configuration).editAccountByAgency(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByAgency(id: number, options?: any) {
            return AgencyAccountControllerApiFp(configuration).getAccountByAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [status] 
         * @param {string} [sortById] 
         * @param {string} [sortByStatus] 
         * @param {string} [sortByLastLoginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsPageByAgency(name?: string, email?: string, status?: string, sortById?: string, sortByStatus?: string, sortByLastLoginDate?: string, options?: any) {
            return AgencyAccountControllerApiFp(configuration).getAccountsPageByAgency(name, email, status, sortById, sortByStatus, sortByLastLoginDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsPublisherByAgency(userId: number, options?: any) {
            return AgencyAccountControllerApiFp(configuration).loginAsPublisherByAgency(userId, options)(fetch, basePath);
        },
    };
};

/**
 * AgencyAccountControllerApi - object-oriented interface
 * @export
 * @class AgencyAccountControllerApi
 * @extends {BaseAPI}
 */
export class AgencyAccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {AccountBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public createAccountByAgency(body: AccountBean, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).createAccountByAgency(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public deleteAccountByAgency(id: number, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).deleteAccountByAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccountBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public editAccountByAgency(body: AccountBean, id: number, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).editAccountByAgency(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public getAccountByAgency(id: number, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).getAccountByAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {string} [status] 
     * @param {string} [sortById] 
     * @param {string} [sortByStatus] 
     * @param {string} [sortByLastLoginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public getAccountsPageByAgency(name?: string, email?: string, status?: string, sortById?: string, sortByStatus?: string, sortByLastLoginDate?: string, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).getAccountsPageByAgency(name, email, status, sortById, sortByStatus, sortByLastLoginDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyAccountControllerApi
     */
    public loginAsPublisherByAgency(userId: number, options?: any) {
        return AgencyAccountControllerApiFp(this.configuration).loginAsPublisherByAgency(userId, options)(this.fetch, this.basePath);
    }

}
/**
 * AgencyCustomFilterControllerApi - fetch parameter creator
 * @export
 */
export const AgencyCustomFilterControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFilterByAgency(body: CustomFilterBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCustomFilterByAgency.');
            }
            const localVarPath = `/api/agency/custom-filter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomFilterBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFilterByAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomFilterByAgency.');
            }
            const localVarPath = `/api/agency/custom-filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCustomFilterByAgency(body: CustomFilterBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editCustomFilterByAgency.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editCustomFilterByAgency.');
            }
            const localVarPath = `/api/agency/custom-filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomFilterBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomFiltersByAgency(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/custom-filter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilterByAgency(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCustomFilterByAgency.');
            }
            const localVarPath = `/api/agency/custom-filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFiltersPageByAgency(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/custom-filter/page`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyCustomFilterControllerApi - functional programming interface
 * @export
 */
export const AgencyCustomFilterControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFilterByAgency(body: CustomFilterBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseCustomFilterBean> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).createCustomFilterByAgency(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFilterByAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).deleteCustomFilterByAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCustomFilterByAgency(body: CustomFilterBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseCustomFilterBean> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).editCustomFilterByAgency(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomFiltersByAgency(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseCustomFilterBean> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).getAllCustomFiltersByAgency(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilterByAgency(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseCustomFilterBean> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).getCustomFilterByAgency(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFiltersPageByAgency(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseCustomFilterBean> {
            const localVarFetchArgs = AgencyCustomFilterControllerApiFetchParamCreator(configuration).getCustomFiltersPageByAgency(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AgencyCustomFilterControllerApi - factory interface
 * @export
 */
export const AgencyCustomFilterControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFilterByAgency(body: CustomFilterBean, options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).createCustomFilterByAgency(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFilterByAgency(id: number, options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).deleteCustomFilterByAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CustomFilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCustomFilterByAgency(body: CustomFilterBean, id: number, options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).editCustomFilterByAgency(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomFiltersByAgency(options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).getAllCustomFiltersByAgency(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilterByAgency(id: number, options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).getCustomFilterByAgency(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFiltersPageByAgency(options?: any) {
            return AgencyCustomFilterControllerApiFp(configuration).getCustomFiltersPageByAgency(options)(fetch, basePath);
        },
    };
};

/**
 * AgencyCustomFilterControllerApi - object-oriented interface
 * @export
 * @class AgencyCustomFilterControllerApi
 * @extends {BaseAPI}
 */
export class AgencyCustomFilterControllerApi extends BaseAPI {
    /**
     * 
     * @param {CustomFilterBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public createCustomFilterByAgency(body: CustomFilterBean, options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).createCustomFilterByAgency(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public deleteCustomFilterByAgency(id: number, options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).deleteCustomFilterByAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CustomFilterBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public editCustomFilterByAgency(body: CustomFilterBean, id: number, options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).editCustomFilterByAgency(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public getAllCustomFiltersByAgency(options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).getAllCustomFiltersByAgency(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public getCustomFilterByAgency(id: number, options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).getCustomFilterByAgency(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomFilterControllerApi
     */
    public getCustomFiltersPageByAgency(options?: any) {
        return AgencyCustomFilterControllerApiFp(this.configuration).getCustomFiltersPageByAgency(options)(this.fetch, this.basePath);
    }

}
/**
 * AgencyCustomizationControllerApi - fetch parameter creator
 * @export
 */
export const AgencyCustomizationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogo(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/custom/logo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomSettings(body: { [key: string]: any; }, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling uploadCustomSettings.');
            }
            const localVarPath = `/api/agency/custom/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(logo?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/custom/logo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (logo !== undefined) {
                localVarFormParams.set('logo', logo as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyCustomizationControllerApi - functional programming interface
 * @export
 */
export const AgencyCustomizationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyCustomizationControllerApiFetchParamCreator(configuration).deleteLogo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomSettings(body: { [key: string]: any; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyCustomizationControllerApiFetchParamCreator(configuration).uploadCustomSettings(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(logo?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyCustomizationControllerApiFetchParamCreator(configuration).uploadLogo(logo, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AgencyCustomizationControllerApi - factory interface
 * @export
 */
export const AgencyCustomizationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogo(options?: any) {
            return AgencyCustomizationControllerApiFp(configuration).deleteLogo(options)(fetch, basePath);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomSettings(body: { [key: string]: any; }, options?: any) {
            return AgencyCustomizationControllerApiFp(configuration).uploadCustomSettings(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(logo?: Blob, options?: any) {
            return AgencyCustomizationControllerApiFp(configuration).uploadLogo(logo, options)(fetch, basePath);
        },
    };
};

/**
 * AgencyCustomizationControllerApi - object-oriented interface
 * @export
 * @class AgencyCustomizationControllerApi
 * @extends {BaseAPI}
 */
export class AgencyCustomizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomizationControllerApi
     */
    public deleteLogo(options?: any) {
        return AgencyCustomizationControllerApiFp(this.configuration).deleteLogo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {{ [key: string]: any; }} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomizationControllerApi
     */
    public uploadCustomSettings(body: { [key: string]: any; }, options?: any) {
        return AgencyCustomizationControllerApiFp(this.configuration).uploadCustomSettings(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyCustomizationControllerApi
     */
    public uploadLogo(logo?: Blob, options?: any) {
        return AgencyCustomizationControllerApiFp(this.configuration).uploadLogo(logo, options)(this.fetch, this.basePath);
    }

}
/**
 * AgencyManagerControllerApi - fetch parameter creator
 * @export
 */
export const AgencyManagerControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager(body: ManagerBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createManager.');
            }
            const localVarPath = `/api/agency/manager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ManagerBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteManager.');
            }
            const localVarPath = `/api/agency/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/manager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManager(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getManager.');
            }
            const localVarPath = `/api/agency/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManager(body: ManagerBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateManager.');
            }
            const localVarPath = `/api/agency/manager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ManagerBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyManagerControllerApi - functional programming interface
 * @export
 */
export const AgencyManagerControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager(body: ManagerBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseManagerBean> {
            const localVarFetchArgs = AgencyManagerControllerApiFetchParamCreator(configuration).createManager(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyManagerControllerApiFetchParamCreator(configuration).deleteManager(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseManagerBean> {
            const localVarFetchArgs = AgencyManagerControllerApiFetchParamCreator(configuration).getAllManagers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManager(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseManagerBean> {
            const localVarFetchArgs = AgencyManagerControllerApiFetchParamCreator(configuration).getManager(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManager(body: ManagerBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseManagerBean> {
            const localVarFetchArgs = AgencyManagerControllerApiFetchParamCreator(configuration).updateManager(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AgencyManagerControllerApi - factory interface
 * @export
 */
export const AgencyManagerControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager(body: ManagerBean, options?: any) {
            return AgencyManagerControllerApiFp(configuration).createManager(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(id: number, options?: any) {
            return AgencyManagerControllerApiFp(configuration).deleteManager(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers(options?: any) {
            return AgencyManagerControllerApiFp(configuration).getAllManagers(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManager(id: number, options?: any) {
            return AgencyManagerControllerApiFp(configuration).getManager(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ManagerBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManager(body: ManagerBean, options?: any) {
            return AgencyManagerControllerApiFp(configuration).updateManager(body, options)(fetch, basePath);
        },
    };
};

/**
 * AgencyManagerControllerApi - object-oriented interface
 * @export
 * @class AgencyManagerControllerApi
 * @extends {BaseAPI}
 */
export class AgencyManagerControllerApi extends BaseAPI {
    /**
     * 
     * @param {ManagerBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyManagerControllerApi
     */
    public createManager(body: ManagerBean, options?: any) {
        return AgencyManagerControllerApiFp(this.configuration).createManager(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyManagerControllerApi
     */
    public deleteManager(id: number, options?: any) {
        return AgencyManagerControllerApiFp(this.configuration).deleteManager(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyManagerControllerApi
     */
    public getAllManagers(options?: any) {
        return AgencyManagerControllerApiFp(this.configuration).getAllManagers(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyManagerControllerApi
     */
    public getManager(id: number, options?: any) {
        return AgencyManagerControllerApiFp(this.configuration).getManager(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ManagerBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyManagerControllerApi
     */
    public updateManager(body: ManagerBean, options?: any) {
        return AgencyManagerControllerApiFp(this.configuration).updateManager(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AgencyReportControllerApi - fetch parameter creator
 * @export
 */
export const AgencyReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAgencyReport(body: ReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadAgencyReport.');
            }
            const localVarPath = `/api/agency/report/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/ad-unit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/bidder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyCustomFilters(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/custom`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySiteValues(search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/site`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/size`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersByAgency(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgencyJsonReport(body: ReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateAgencyJsonReport.');
            }
            const localVarPath = `/api/agency/report/json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportKeys(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportMetrics(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/report/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyReportControllerApi - functional programming interface
 * @export
 */
export const AgencyReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAgencyReport(body: ReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).downloadAgencyReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencyAdUnitValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencyBidderValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyCustomFilters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomFilterBean>> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencyCustomFilters(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySiteValues(search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencySiteValues(search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencySizeValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filteredAgencyVersionValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersByAgency(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatFilters> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).filtersByAgency(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgencyJsonReport(body: ReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportRecords> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).generateAgencyJsonReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FilterBean>> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).getAgencyReportKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportMetrics(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FilterBean>> {
            const localVarFetchArgs = AgencyReportControllerApiFetchParamCreator(configuration).getAgencyReportMetrics(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AgencyReportControllerApi - factory interface
 * @export
 */
export const AgencyReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAgencyReport(body: ReportQuery, options?: any) {
            return AgencyReportControllerApiFp(configuration).downloadAgencyReport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencyAdUnitValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencyBidderValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyCustomFilters(options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencyCustomFilters(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySiteValues(search?: string, page?: number, count?: number, options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencySiteValues(search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencySizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencySizeValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAgencyVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return AgencyReportControllerApiFp(configuration).filteredAgencyVersionValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersByAgency(options?: any) {
            return AgencyReportControllerApiFp(configuration).filtersByAgency(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgencyJsonReport(body: ReportQuery, options?: any) {
            return AgencyReportControllerApiFp(configuration).generateAgencyJsonReport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportKeys(options?: any) {
            return AgencyReportControllerApiFp(configuration).getAgencyReportKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyReportMetrics(options?: any) {
            return AgencyReportControllerApiFp(configuration).getAgencyReportMetrics(options)(fetch, basePath);
        },
    };
};

/**
 * AgencyReportControllerApi - object-oriented interface
 * @export
 * @class AgencyReportControllerApi
 * @extends {BaseAPI}
 */
export class AgencyReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {ReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public downloadAgencyReport(body: ReportQuery, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).downloadAgencyReport(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencyAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencyAdUnitValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencyBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencyBidderValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencyCustomFilters(options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencyCustomFilters(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencySiteValues(search?: string, page?: number, count?: number, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencySiteValues(search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencySizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencySizeValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filteredAgencyVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filteredAgencyVersionValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public filtersByAgency(options?: any) {
        return AgencyReportControllerApiFp(this.configuration).filtersByAgency(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public generateAgencyJsonReport(body: ReportQuery, options?: any) {
        return AgencyReportControllerApiFp(this.configuration).generateAgencyJsonReport(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public getAgencyReportKeys(options?: any) {
        return AgencyReportControllerApiFp(this.configuration).getAgencyReportKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyReportControllerApi
     */
    public getAgencyReportMetrics(options?: any) {
        return AgencyReportControllerApiFp(this.configuration).getAgencyReportMetrics(options)(this.fetch, this.basePath);
    }

}
/**
 * AlertConfigControllerApi - fetch parameter creator
 * @export
 */
export const AlertConfigControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertConfig(body: AlertConfigBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAlertConfig.');
            }
            const localVarPath = `/api/alert-config`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertConfigBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertConfig(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAlertConfig.');
            }
            const localVarPath = `/api/alert-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfig(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlertConfig.');
            }
            const localVarPath = `/api/alert-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfigList(options: any = {}): FetchArgs {
            const localVarPath = `/api/alert-config`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAlertConfig(options: any = {}): FetchArgs {
            const localVarPath = `/api/alert-config/configs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo(options: any = {}): FetchArgs {
            const localVarPath = `/api/alert-config/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertConfig(body: AlertConfigBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAlertConfig.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAlertConfig.');
            }
            const localVarPath = `/api/alert-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertConfigBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertConfigControllerApi - functional programming interface
 * @export
 */
export const AlertConfigControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertConfig(body: AlertConfigBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertConfigBean> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).createAlertConfig(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertConfig(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).deleteAlertConfig(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfig(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertConfigBean> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).getAlertConfig(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfigList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAlertConfigBean> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).getAlertConfigList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAlertConfig(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AlertConfigBean>> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).getAllAlertConfig(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any; }> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).getInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertConfig(body: AlertConfigBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertConfigBean> {
            const localVarFetchArgs = AlertConfigControllerApiFetchParamCreator(configuration).updateAlertConfig(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlertConfigControllerApi - factory interface
 * @export
 */
export const AlertConfigControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertConfig(body: AlertConfigBean, options?: any) {
            return AlertConfigControllerApiFp(configuration).createAlertConfig(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertConfig(id: number, options?: any) {
            return AlertConfigControllerApiFp(configuration).deleteAlertConfig(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfig(id: number, options?: any) {
            return AlertConfigControllerApiFp(configuration).getAlertConfig(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertConfigList(options?: any) {
            return AlertConfigControllerApiFp(configuration).getAlertConfigList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAlertConfig(options?: any) {
            return AlertConfigControllerApiFp(configuration).getAllAlertConfig(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo(options?: any) {
            return AlertConfigControllerApiFp(configuration).getInfo(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AlertConfigBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertConfig(body: AlertConfigBean, id: number, options?: any) {
            return AlertConfigControllerApiFp(configuration).updateAlertConfig(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AlertConfigControllerApi - object-oriented interface
 * @export
 * @class AlertConfigControllerApi
 * @extends {BaseAPI}
 */
export class AlertConfigControllerApi extends BaseAPI {
    /**
     * 
     * @param {AlertConfigBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public createAlertConfig(body: AlertConfigBean, options?: any) {
        return AlertConfigControllerApiFp(this.configuration).createAlertConfig(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public deleteAlertConfig(id: number, options?: any) {
        return AlertConfigControllerApiFp(this.configuration).deleteAlertConfig(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public getAlertConfig(id: number, options?: any) {
        return AlertConfigControllerApiFp(this.configuration).getAlertConfig(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public getAlertConfigList(options?: any) {
        return AlertConfigControllerApiFp(this.configuration).getAlertConfigList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public getAllAlertConfig(options?: any) {
        return AlertConfigControllerApiFp(this.configuration).getAllAlertConfig(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public getInfo(options?: any) {
        return AlertConfigControllerApiFp(this.configuration).getInfo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AlertConfigBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertConfigControllerApi
     */
    public updateAlertConfig(body: AlertConfigBean, id: number, options?: any) {
        return AlertConfigControllerApiFp(this.configuration).updateAlertConfig(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AlertControllerApi - fetch parameter creator
 * @export
 */
export const AlertControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PeriodQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertList(body: PeriodQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling alertList.');
            }
            const localVarPath = `/api/alert/alert-list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PeriodQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlert(body: AlertBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAlert.');
            }
            const localVarPath = `/api/alert`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAlert.');
            }
            const localVarPath = `/api/alert/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlert.');
            }
            const localVarPath = `/api/alert/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewAlertsCount(options: any = {}): FetchArgs {
            const localVarPath = `/api/alert/new-count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(body: AlertBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAlert.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAlert.');
            }
            const localVarPath = `/api/alert/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertControllerApi - functional programming interface
 * @export
 */
export const AlertControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PeriodQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertList(body: PeriodQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAlertBean> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).alertList(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlert(body: AlertBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertBean> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).createAlert(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).deleteAlert(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertBean> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).getAlert(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewAlertsCount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).getNewAlertsCount(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(body: AlertBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertBean> {
            const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).updateAlert(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlertControllerApi - factory interface
 * @export
 */
export const AlertControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PeriodQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertList(body: PeriodQuery, options?: any) {
            return AlertControllerApiFp(configuration).alertList(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlert(body: AlertBean, options?: any) {
            return AlertControllerApiFp(configuration).createAlert(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(id: number, options?: any) {
            return AlertControllerApiFp(configuration).deleteAlert(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(id: number, options?: any) {
            return AlertControllerApiFp(configuration).getAlert(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewAlertsCount(options?: any) {
            return AlertControllerApiFp(configuration).getNewAlertsCount(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AlertBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(body: AlertBean, id: number, options?: any) {
            return AlertControllerApiFp(configuration).updateAlert(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AlertControllerApi - object-oriented interface
 * @export
 * @class AlertControllerApi
 * @extends {BaseAPI}
 */
export class AlertControllerApi extends BaseAPI {
    /**
     * 
     * @param {PeriodQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public alertList(body: PeriodQuery, options?: any) {
        return AlertControllerApiFp(this.configuration).alertList(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AlertBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public createAlert(body: AlertBean, options?: any) {
        return AlertControllerApiFp(this.configuration).createAlert(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public deleteAlert(id: number, options?: any) {
        return AlertControllerApiFp(this.configuration).deleteAlert(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public getAlert(id: number, options?: any) {
        return AlertControllerApiFp(this.configuration).getAlert(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public getNewAlertsCount(options?: any) {
        return AlertControllerApiFp(this.configuration).getNewAlertsCount(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AlertBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertControllerApi
     */
    public updateAlert(body: AlertBean, id: number, options?: any) {
        return AlertControllerApiFp(this.configuration).updateAlert(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AlertNotificationControllerApi - fetch parameter creator
 * @export
 */
export const AlertNotificationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertNotification(body: AlertNotificationBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAlertNotification.');
            }
            const localVarPath = `/api/alert-notification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertNotificationBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertNotification(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAlertNotification.');
            }
            const localVarPath = `/api/alert-notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotification(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlertNotification.');
            }
            const localVarPath = `/api/alert-notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotificationList(options: any = {}): FetchArgs {
            const localVarPath = `/api/alert-notification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertNotification(body: AlertNotificationBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAlertNotification.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAlertNotification.');
            }
            const localVarPath = `/api/alert-notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AlertNotificationBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertNotificationControllerApi - functional programming interface
 * @export
 */
export const AlertNotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertNotification(body: AlertNotificationBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertNotificationBean> {
            const localVarFetchArgs = AlertNotificationControllerApiFetchParamCreator(configuration).createAlertNotification(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertNotification(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AlertNotificationControllerApiFetchParamCreator(configuration).deleteAlertNotification(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotification(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertNotificationBean> {
            const localVarFetchArgs = AlertNotificationControllerApiFetchParamCreator(configuration).getAlertNotification(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotificationList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAlertNotificationBean> {
            const localVarFetchArgs = AlertNotificationControllerApiFetchParamCreator(configuration).getAlertNotificationList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertNotification(body: AlertNotificationBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAlertNotificationBean> {
            const localVarFetchArgs = AlertNotificationControllerApiFetchParamCreator(configuration).updateAlertNotification(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlertNotificationControllerApi - factory interface
 * @export
 */
export const AlertNotificationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertNotification(body: AlertNotificationBean, options?: any) {
            return AlertNotificationControllerApiFp(configuration).createAlertNotification(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertNotification(id: number, options?: any) {
            return AlertNotificationControllerApiFp(configuration).deleteAlertNotification(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotification(id: number, options?: any) {
            return AlertNotificationControllerApiFp(configuration).getAlertNotification(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertNotificationList(options?: any) {
            return AlertNotificationControllerApiFp(configuration).getAlertNotificationList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AlertNotificationBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertNotification(body: AlertNotificationBean, id: number, options?: any) {
            return AlertNotificationControllerApiFp(configuration).updateAlertNotification(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AlertNotificationControllerApi - object-oriented interface
 * @export
 * @class AlertNotificationControllerApi
 * @extends {BaseAPI}
 */
export class AlertNotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {AlertNotificationBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertNotificationControllerApi
     */
    public createAlertNotification(body: AlertNotificationBean, options?: any) {
        return AlertNotificationControllerApiFp(this.configuration).createAlertNotification(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertNotificationControllerApi
     */
    public deleteAlertNotification(id: number, options?: any) {
        return AlertNotificationControllerApiFp(this.configuration).deleteAlertNotification(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertNotificationControllerApi
     */
    public getAlertNotification(id: number, options?: any) {
        return AlertNotificationControllerApiFp(this.configuration).getAlertNotification(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertNotificationControllerApi
     */
    public getAlertNotificationList(options?: any) {
        return AlertNotificationControllerApiFp(this.configuration).getAlertNotificationList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AlertNotificationBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertNotificationControllerApi
     */
    public updateAlertNotification(body: AlertNotificationBean, id: number, options?: any) {
        return AlertNotificationControllerApiFp(this.configuration).updateAlertNotification(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * BidderControllerApi - fetch parameter creator
 * @export
 */
export const BidderControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddBidderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBidder(body: AddBidderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addBidder.');
            }
            const localVarPath = `/api/bidder/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddBidderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bidderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEdit(bidderId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bidderId' is not null or undefined
            if (bidderId === null || bidderId === undefined) {
                throw new RequiredError('bidderId','Required parameter bidderId was null or undefined when calling initialEdit.');
            }
            const localVarPath = `/api/bidder/initialEdit/{bidderId}`
                .replace(`{${"bidderId"}}`, encodeURIComponent(String(bidderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: BidderListQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/api/bidder/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderListQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderEditForm} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: BidderEditForm, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update2.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update2.');
            }
            const localVarPath = `/api/bidder/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderEditForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidderControllerApi - functional programming interface
 * @export
 */
export const BidderControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddBidderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBidder(body: AddBidderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = BidderControllerApiFetchParamCreator(configuration).addBidder(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} bidderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEdit(bidderId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BidderEditResponse> {
            const localVarFetchArgs = BidderControllerApiFetchParamCreator(configuration).initialEdit(bidderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: BidderListQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BidderList> {
            const localVarFetchArgs = BidderControllerApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderEditForm} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: BidderEditForm, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BidderEditResponse> {
            const localVarFetchArgs = BidderControllerApiFetchParamCreator(configuration).update2(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BidderControllerApi - factory interface
 * @export
 */
export const BidderControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AddBidderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBidder(body: AddBidderRequest, options?: any) {
            return BidderControllerApiFp(configuration).addBidder(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} bidderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEdit(bidderId: number, options?: any) {
            return BidderControllerApiFp(configuration).initialEdit(bidderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: BidderListQuery, options?: any) {
            return BidderControllerApiFp(configuration).list(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderEditForm} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: BidderEditForm, id: number, options?: any) {
            return BidderControllerApiFp(configuration).update2(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * BidderControllerApi - object-oriented interface
 * @export
 * @class BidderControllerApi
 * @extends {BaseAPI}
 */
export class BidderControllerApi extends BaseAPI {
    /**
     * 
     * @param {AddBidderRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderControllerApi
     */
    public addBidder(body: AddBidderRequest, options?: any) {
        return BidderControllerApiFp(this.configuration).addBidder(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} bidderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderControllerApi
     */
    public initialEdit(bidderId: number, options?: any) {
        return BidderControllerApiFp(this.configuration).initialEdit(bidderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderListQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderControllerApi
     */
    public list(body: BidderListQuery, options?: any) {
        return BidderControllerApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderEditForm} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderControllerApi
     */
    public update2(body: BidderEditForm, id: number, options?: any) {
        return BidderControllerApiFp(this.configuration).update2(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * BillingControllerApi - fetch parameter creator
 * @export
 */
export const BillingControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePayments(date: string, options: any = {}): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling calculatePayments.');
            }
            const localVarPath = `/billing/calcPayments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        charge(date: string, options: any = {}): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling charge.');
            }
            const localVarPath = `/billing/charge`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingStart(accountId: string, date: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling setBillingStart.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling setBillingStart.');
            }
            const localVarPath = `/billing/setBillingStart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialCheck(date: string, options: any = {}): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling trialCheck.');
            }
            const localVarPath = `/billing/trial-check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warningTrialCheck(date: string, options: any = {}): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling warningTrialCheck.');
            }
            const localVarPath = `/billing/warning-trial-check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingControllerApi - functional programming interface
 * @export
 */
export const BillingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePayments(date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BillingControllerApiFetchParamCreator(configuration).calculatePayments(date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        charge(date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BillingControllerApiFetchParamCreator(configuration).charge(date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingStart(accountId: string, date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BillingControllerApiFetchParamCreator(configuration).setBillingStart(accountId, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialCheck(date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BillingControllerApiFetchParamCreator(configuration).trialCheck(date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warningTrialCheck(date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BillingControllerApiFetchParamCreator(configuration).warningTrialCheck(date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BillingControllerApi - factory interface
 * @export
 */
export const BillingControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePayments(date: string, options?: any) {
            return BillingControllerApiFp(configuration).calculatePayments(date, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        charge(date: string, options?: any) {
            return BillingControllerApiFp(configuration).charge(date, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingStart(accountId: string, date: string, options?: any) {
            return BillingControllerApiFp(configuration).setBillingStart(accountId, date, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialCheck(date: string, options?: any) {
            return BillingControllerApiFp(configuration).trialCheck(date, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warningTrialCheck(date: string, options?: any) {
            return BillingControllerApiFp(configuration).warningTrialCheck(date, options)(fetch, basePath);
        },
    };
};

/**
 * BillingControllerApi - object-oriented interface
 * @export
 * @class BillingControllerApi
 * @extends {BaseAPI}
 */
export class BillingControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingControllerApi
     */
    public calculatePayments(date: string, options?: any) {
        return BillingControllerApiFp(this.configuration).calculatePayments(date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingControllerApi
     */
    public charge(date: string, options?: any) {
        return BillingControllerApiFp(this.configuration).charge(date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} accountId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingControllerApi
     */
    public setBillingStart(accountId: string, date: string, options?: any) {
        return BillingControllerApiFp(this.configuration).setBillingStart(accountId, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingControllerApi
     */
    public trialCheck(date: string, options?: any) {
        return BillingControllerApiFp(this.configuration).trialCheck(date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingControllerApi
     */
    public warningTrialCheck(date: string, options?: any) {
        return BillingControllerApiFp(this.configuration).warningTrialCheck(date, options)(this.fetch, this.basePath);
    }

}
/**
 * BuildInfoControllerApi - fetch parameter creator
 * @export
 */
export const BuildInfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options: any = {}): FetchArgs {
            const localVarPath = `/build-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options: any = {}): FetchArgs {
            const localVarPath = `/build-info/prometheus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildInfoControllerApi - functional programming interface
 * @export
 */
export const BuildInfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any; }> {
            const localVarFetchArgs = BuildInfoControllerApiFetchParamCreator(configuration).buildInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BuildInfoControllerApiFetchParamCreator(configuration).buildInfoPrometheus(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BuildInfoControllerApi - factory interface
 * @export
 */
export const BuildInfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options?: any) {
            return BuildInfoControllerApiFp(configuration).buildInfo(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options?: any) {
            return BuildInfoControllerApiFp(configuration).buildInfoPrometheus(options)(fetch, basePath);
        },
    };
};

/**
 * BuildInfoControllerApi - object-oriented interface
 * @export
 * @class BuildInfoControllerApi
 * @extends {BaseAPI}
 */
export class BuildInfoControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildInfoControllerApi
     */
    public buildInfo(options?: any) {
        return BuildInfoControllerApiFp(this.configuration).buildInfo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildInfoControllerApi
     */
    public buildInfoPrometheus(options?: any) {
        return BuildInfoControllerApiFp(this.configuration).buildInfoPrometheus(options)(this.fetch, this.basePath);
    }

}
/**
 * BundleControllerApi - fetch parameter creator
 * @export
 */
export const BundleControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBundleByAccount(options: any = {}): FetchArgs {
            const localVarPath = `/api/bundle/bundle-list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(body: UIBundle, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analyze.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analyze.');
            }
            const localVarPath = `/api/bundle/analyze/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UIBundle" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(body: UIBundleViewQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling archive.');
            }
            const localVarPath = `/api/bundle/archive`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UIBundleViewQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive1(bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling archive1.');
            }
            const localVarPath = `/api/bundle/archive/{bundleId}`
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleList(options: any = {}): FetchArgs {
            const localVarPath = `/api/bundle`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [domain] 
         * @param {Array<string>} [status] 
         * @param {string} [orderBy] 
         * @param {string} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleListFiltering(name?: string, domain?: string, status?: Array<string>, orderBy?: string, orderDirection?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/bundle/filterList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['orderDirection'] = orderDirection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BundleListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexList(body: BundleListQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling complexList.');
            }
            const localVarPath = `/api/bundle/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BundleListQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: BundleCreateForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create1.');
            }
            const localVarPath = `/api/bundle`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BundleCreateForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling delete1.');
            }
            const localVarPath = `/api/bundle/{bundleId}`
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling download.');
            }
            const localVarPath = `/api/bundle/download/bundle-{id}.zip`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite1(bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling getSite1.');
            }
            const localVarPath = `/api/bundle/getSite/{bundleId}`
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialCreateBundle(options: any = {}): FetchArgs {
            const localVarPath = `/api/bundle/initialCreateBundle`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEditBundle(bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling initialEditBundle.');
            }
            const localVarPath = `/api/bundle/initialEditBundle/{bundleId}`
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} siteId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2(teamId: number, siteId: number, pageable: Pageable, options: any = {}): FetchArgs {
            // verify required parameter 'teamId' is not null or undefined
            if (teamId === null || teamId === undefined) {
                throw new RequiredError('teamId','Required parameter teamId was null or undefined when calling list2.');
            }
            // verify required parameter 'siteId' is not null or undefined
            if (siteId === null || siteId === undefined) {
                throw new RequiredError('siteId','Required parameter siteId was null or undefined when calling list2.');
            }
            // verify required parameter 'pageable' is not null or undefined
            if (pageable === null || pageable === undefined) {
                throw new RequiredError('pageable','Required parameter pageable was null or undefined when calling list2.');
            }
            const localVarPath = `/api/bundle/{teamId}/{siteId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(body: UIBundle, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling publish.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling publish.');
            }
            const localVarPath = `/api/bundle/publish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UIBundle" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SitesListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(body: SitesListQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling siteList.');
            }
            const localVarPath = `/api/bundle/sites`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SitesListQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml1(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling testHtml1.');
            }
            const localVarPath = `/api/bundle/test/test-{id}.html`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs1(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling testjs1.');
            }
            const localVarPath = `/api/bundle/test/bundle-{id}.js`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSite.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSite.');
            }
            const localVarPath = `/api/bundle/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SiteBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(body: UIBundleViewQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling view.');
            }
            const localVarPath = `/api/bundle/view`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UIBundleViewQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BundleControllerApi - functional programming interface
 * @export
 */
export const BundleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBundleByAccount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BundleBean>> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).allBundleByAccount(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(body: UIBundle, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleEditResponse> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).analyze(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(body: UIBundleViewQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseUIBundleView> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).archive(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive1(bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).archive1(bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseBundleBean> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).bundleList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [domain] 
         * @param {Array<string>} [status] 
         * @param {string} [orderBy] 
         * @param {string} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleListFiltering(name?: string, domain?: string, status?: Array<string>, orderBy?: string, orderDirection?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseBundleBean> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).bundleListFiltering(name, domain, status, orderBy, orderDirection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BundleListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexList(body: BundleListQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleList> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).complexList(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: BundleCreateForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleCreateResponse> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).create1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).delete1(bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).download(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite1(bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).getSite1(bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialCreateBundle(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleInitCreateForm> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).initialCreateBundle(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEditBundle(bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleEditResponse> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).initialEditBundle(bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} siteId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2(teamId: number, siteId: number, pageable: Pageable, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleList> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).list2(teamId, siteId, pageable, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(body: UIBundle, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleEditResponse> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).publish(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SitesListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(body: SitesListQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SitesList> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).siteList(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml1(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).testHtml1(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs1(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).testjs1(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).updateSite(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(body: UIBundleViewQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseUIBundleView> {
            const localVarFetchArgs = BundleControllerApiFetchParamCreator(configuration).view(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BundleControllerApi - factory interface
 * @export
 */
export const BundleControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBundleByAccount(options?: any) {
            return BundleControllerApiFp(configuration).allBundleByAccount(options)(fetch, basePath);
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(body: UIBundle, id: number, options?: any) {
            return BundleControllerApiFp(configuration).analyze(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(body: UIBundleViewQuery, options?: any) {
            return BundleControllerApiFp(configuration).archive(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive1(bundleId: number, options?: any) {
            return BundleControllerApiFp(configuration).archive1(bundleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleList(options?: any) {
            return BundleControllerApiFp(configuration).bundleList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [domain] 
         * @param {Array<string>} [status] 
         * @param {string} [orderBy] 
         * @param {string} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bundleListFiltering(name?: string, domain?: string, status?: Array<string>, orderBy?: string, orderDirection?: string, options?: any) {
            return BundleControllerApiFp(configuration).bundleListFiltering(name, domain, status, orderBy, orderDirection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BundleListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexList(body: BundleListQuery, options?: any) {
            return BundleControllerApiFp(configuration).complexList(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: BundleCreateForm, options?: any) {
            return BundleControllerApiFp(configuration).create1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(bundleId: number, options?: any) {
            return BundleControllerApiFp(configuration).delete1(bundleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, options?: any) {
            return BundleControllerApiFp(configuration).download(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite1(bundleId: number, options?: any) {
            return BundleControllerApiFp(configuration).getSite1(bundleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialCreateBundle(options?: any) {
            return BundleControllerApiFp(configuration).initialCreateBundle(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialEditBundle(bundleId: number, options?: any) {
            return BundleControllerApiFp(configuration).initialEditBundle(bundleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} siteId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2(teamId: number, siteId: number, pageable: Pageable, options?: any) {
            return BundleControllerApiFp(configuration).list2(teamId, siteId, pageable, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UIBundle} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(body: UIBundle, id: number, options?: any) {
            return BundleControllerApiFp(configuration).publish(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SitesListQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(body: SitesListQuery, options?: any) {
            return BundleControllerApiFp(configuration).siteList(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml1(id: number, options?: any) {
            return BundleControllerApiFp(configuration).testHtml1(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs1(id: number, options?: any) {
            return BundleControllerApiFp(configuration).testjs1(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options?: any) {
            return BundleControllerApiFp(configuration).updateSite(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UIBundleViewQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(body: UIBundleViewQuery, options?: any) {
            return BundleControllerApiFp(configuration).view(body, options)(fetch, basePath);
        },
    };
};

/**
 * BundleControllerApi - object-oriented interface
 * @export
 * @class BundleControllerApi
 * @extends {BaseAPI}
 */
export class BundleControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public allBundleByAccount(options?: any) {
        return BundleControllerApiFp(this.configuration).allBundleByAccount(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UIBundle} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public analyze(body: UIBundle, id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).analyze(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UIBundleViewQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public archive(body: UIBundleViewQuery, options?: any) {
        return BundleControllerApiFp(this.configuration).archive(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public archive1(bundleId: number, options?: any) {
        return BundleControllerApiFp(this.configuration).archive1(bundleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public bundleList(options?: any) {
        return BundleControllerApiFp(this.configuration).bundleList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [domain] 
     * @param {Array<string>} [status] 
     * @param {string} [orderBy] 
     * @param {string} [orderDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public bundleListFiltering(name?: string, domain?: string, status?: Array<string>, orderBy?: string, orderDirection?: string, options?: any) {
        return BundleControllerApiFp(this.configuration).bundleListFiltering(name, domain, status, orderBy, orderDirection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BundleListQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public complexList(body: BundleListQuery, options?: any) {
        return BundleControllerApiFp(this.configuration).complexList(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BundleCreateForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public create1(body: BundleCreateForm, options?: any) {
        return BundleControllerApiFp(this.configuration).create1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public delete1(bundleId: number, options?: any) {
        return BundleControllerApiFp(this.configuration).delete1(bundleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public download(id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).download(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public getSite1(bundleId: number, options?: any) {
        return BundleControllerApiFp(this.configuration).getSite1(bundleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public initialCreateBundle(options?: any) {
        return BundleControllerApiFp(this.configuration).initialCreateBundle(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public initialEditBundle(bundleId: number, options?: any) {
        return BundleControllerApiFp(this.configuration).initialEditBundle(bundleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} teamId 
     * @param {number} siteId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public list2(teamId: number, siteId: number, pageable: Pageable, options?: any) {
        return BundleControllerApiFp(this.configuration).list2(teamId, siteId, pageable, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UIBundle} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public publish(body: UIBundle, id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).publish(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SitesListQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public siteList(body: SitesListQuery, options?: any) {
        return BundleControllerApiFp(this.configuration).siteList(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public testHtml1(id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).testHtml1(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public testjs1(id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).testjs1(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SiteBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public updateSite(body: SiteBean, id: number, options?: any) {
        return BundleControllerApiFp(this.configuration).updateSite(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UIBundleViewQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerApi
     */
    public view(body: UIBundleViewQuery, options?: any) {
        return BundleControllerApiFp(this.configuration).view(body, options)(this.fetch, this.basePath);
    }

}
/**
 * BundleControllerTmpApi - fetch parameter creator
 * @export
 */
export const BundleControllerTmpApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling testHtml.');
            }
            const localVarPath = `/generator/test-{id}.html`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling testjs.');
            }
            const localVarPath = `/generator/bundle-{id}.js`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} accId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zip(id: number, accId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling zip.');
            }
            // verify required parameter 'accId' is not null or undefined
            if (accId === null || accId === undefined) {
                throw new RequiredError('accId','Required parameter accId was null or undefined when calling zip.');
            }
            const localVarPath = `/generator/{accId}/bundle-{id}.zip`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"accId"}}`, encodeURIComponent(String(accId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BundleControllerTmpApi - functional programming interface
 * @export
 */
export const BundleControllerTmpApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BundleControllerTmpApiFetchParamCreator(configuration).testHtml(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BundleControllerTmpApiFetchParamCreator(configuration).testjs(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} accId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zip(id: number, accId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = BundleControllerTmpApiFetchParamCreator(configuration).zip(id, accId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BundleControllerTmpApi - factory interface
 * @export
 */
export const BundleControllerTmpApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHtml(id: number, options?: any) {
            return BundleControllerTmpApiFp(configuration).testHtml(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testjs(id: number, options?: any) {
            return BundleControllerTmpApiFp(configuration).testjs(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} accId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zip(id: number, accId: number, options?: any) {
            return BundleControllerTmpApiFp(configuration).zip(id, accId, options)(fetch, basePath);
        },
    };
};

/**
 * BundleControllerTmpApi - object-oriented interface
 * @export
 * @class BundleControllerTmpApi
 * @extends {BaseAPI}
 */
export class BundleControllerTmpApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerTmpApi
     */
    public testHtml(id: number, options?: any) {
        return BundleControllerTmpApiFp(this.configuration).testHtml(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerTmpApi
     */
    public testjs(id: number, options?: any) {
        return BundleControllerTmpApiFp(this.configuration).testjs(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} accId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleControllerTmpApi
     */
    public zip(id: number, accId: number, options?: any) {
        return BundleControllerTmpApiFp(this.configuration).zip(id, accId, options)(this.fetch, this.basePath);
    }

}
/**
 * ChangeLogControllerApi - fetch parameter creator
 * @export
 */
export const ChangeLogControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} type 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changelogid(type: string, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling changelogid.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changelogid.');
            }
            const localVarPath = `/api/changelog/changelogid`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeLogControllerApi - functional programming interface
 * @export
 */
export const ChangeLogControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} type 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changelogid(type: string, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = ChangeLogControllerApiFetchParamCreator(configuration).changelogid(type, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ChangeLogControllerApi - factory interface
 * @export
 */
export const ChangeLogControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} type 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changelogid(type: string, id: number, options?: any) {
            return ChangeLogControllerApiFp(configuration).changelogid(type, id, options)(fetch, basePath);
        },
    };
};

/**
 * ChangeLogControllerApi - object-oriented interface
 * @export
 * @class ChangeLogControllerApi
 * @extends {BaseAPI}
 */
export class ChangeLogControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} type 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeLogControllerApi
     */
    public changelogid(type: string, id: number, options?: any) {
        return ChangeLogControllerApiFp(this.configuration).changelogid(type, id, options)(this.fetch, this.basePath);
    }

}
/**
 * CustomersStatControllerApi - fetch parameter creator
 * @export
 */
export const CustomersStatControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomersStatQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomersStat(body: CustomersStatQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling newCustomersStat.');
            }
            const localVarPath = `/api/admin/newCustomersStat`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomersStatQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalCustomersStat(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/totalCustomersStat`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersStatControllerApi - functional programming interface
 * @export
 */
export const CustomersStatControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomersStatQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomersStat(body: CustomersStatQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseCustomersStatBean> {
            const localVarFetchArgs = CustomersStatControllerApiFetchParamCreator(configuration).newCustomersStat(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalCustomersStat(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TotalCustomersStatBean> {
            const localVarFetchArgs = CustomersStatControllerApiFetchParamCreator(configuration).totalCustomersStat(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CustomersStatControllerApi - factory interface
 * @export
 */
export const CustomersStatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CustomersStatQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomersStat(body: CustomersStatQuery, options?: any) {
            return CustomersStatControllerApiFp(configuration).newCustomersStat(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalCustomersStat(options?: any) {
            return CustomersStatControllerApiFp(configuration).totalCustomersStat(options)(fetch, basePath);
        },
    };
};

/**
 * CustomersStatControllerApi - object-oriented interface
 * @export
 * @class CustomersStatControllerApi
 * @extends {BaseAPI}
 */
export class CustomersStatControllerApi extends BaseAPI {
    /**
     * 
     * @param {CustomersStatQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersStatControllerApi
     */
    public newCustomersStat(body: CustomersStatQuery, options?: any) {
        return CustomersStatControllerApiFp(this.configuration).newCustomersStat(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersStatControllerApi
     */
    public totalCustomersStat(options?: any) {
        return CustomersStatControllerApiFp(this.configuration).totalCustomersStat(options)(this.fetch, this.basePath);
    }

}
/**
 * DemoAccessControllerApi - fetch parameter creator
 * @export
 */
export const DemoAccessControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {{ [key: string]: string; }} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsDemo(key: string, params: { [key: string]: string; }, options: any = {}): FetchArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling loginAsDemo.');
            }
            // verify required parameter 'params' is not null or undefined
            if (params === null || params === undefined) {
                throw new RequiredError('params','Required parameter params was null or undefined when calling loginAsDemo.');
            }
            const localVarPath = `/demo/access/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoAccessControllerApi - functional programming interface
 * @export
 */
export const DemoAccessControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {{ [key: string]: string; }} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsDemo(key: string, params: { [key: string]: string; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RedirectView> {
            const localVarFetchArgs = DemoAccessControllerApiFetchParamCreator(configuration).loginAsDemo(key, params, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DemoAccessControllerApi - factory interface
 * @export
 */
export const DemoAccessControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {{ [key: string]: string; }} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsDemo(key: string, params: { [key: string]: string; }, options?: any) {
            return DemoAccessControllerApiFp(configuration).loginAsDemo(key, params, options)(fetch, basePath);
        },
    };
};

/**
 * DemoAccessControllerApi - object-oriented interface
 * @export
 * @class DemoAccessControllerApi
 * @extends {BaseAPI}
 */
export class DemoAccessControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} key 
     * @param {{ [key: string]: string; }} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoAccessControllerApi
     */
    public loginAsDemo(key: string, params: { [key: string]: string; }, options?: any) {
        return DemoAccessControllerApiFp(this.configuration).loginAsDemo(key, params, options)(this.fetch, this.basePath);
    }

}
/**
 * DomainControllerApi - fetch parameter creator
 * @export
 */
export const DomainControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomainsForValidBundles(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/create-domains-for-valid-bundles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainControllerApi - functional programming interface
 * @export
 */
export const DomainControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomainsForValidBundles(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DomainControllerApiFetchParamCreator(configuration).createDomainsForValidBundles(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DomainControllerApi - factory interface
 * @export
 */
export const DomainControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomainsForValidBundles(options?: any) {
            return DomainControllerApiFp(configuration).createDomainsForValidBundles(options)(fetch, basePath);
        },
    };
};

/**
 * DomainControllerApi - object-oriented interface
 * @export
 * @class DomainControllerApi
 * @extends {BaseAPI}
 */
export class DomainControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainControllerApi
     */
    public createDomainsForValidBundles(options?: any) {
        return DomainControllerApiFp(this.configuration).createDomainsForValidBundles(options)(this.fetch, this.basePath);
    }

}
/**
 * HealthControllerApi - fetch parameter creator
 * @export
 */
export const HealthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeReady(options: any = {}): FetchArgs {
            const localVarPath = `/mt/ready`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeUnready(options: any = {}): FetchArgs {
            const localVarPath = `/mt/unready`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthControllerApi - functional programming interface
 * @export
 */
export const HealthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeReady(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = HealthControllerApiFetchParamCreator(configuration).makeReady(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeUnready(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = HealthControllerApiFetchParamCreator(configuration).makeUnready(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HealthControllerApi - factory interface
 * @export
 */
export const HealthControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeReady(options?: any) {
            return HealthControllerApiFp(configuration).makeReady(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeUnready(options?: any) {
            return HealthControllerApiFp(configuration).makeUnready(options)(fetch, basePath);
        },
    };
};

/**
 * HealthControllerApi - object-oriented interface
 * @export
 * @class HealthControllerApi
 * @extends {BaseAPI}
 */
export class HealthControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthControllerApi
     */
    public makeReady(options?: any) {
        return HealthControllerApiFp(this.configuration).makeReady(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthControllerApi
     */
    public makeUnready(options?: any) {
        return HealthControllerApiFp(this.configuration).makeUnready(options)(this.fetch, this.basePath);
    }

}
/**
 * InfoControllerApi - fetch parameter creator
 * @export
 */
export const InfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managerIsLoggedIn(options: any = {}): FetchArgs {
            const localVarPath = `/api/agency/manager-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherIsLoggedIn(options: any = {}): FetchArgs {
            const localVarPath = `/api/publisher/publisher-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoControllerApi - functional programming interface
 * @export
 */
export const InfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managerIsLoggedIn(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ManagerInfoBean> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).managerIsLoggedIn(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherIsLoggedIn(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PublisherInfoBean> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).publisherIsLoggedIn(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoControllerApi - factory interface
 * @export
 */
export const InfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managerIsLoggedIn(options?: any) {
            return InfoControllerApiFp(configuration).managerIsLoggedIn(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherIsLoggedIn(options?: any) {
            return InfoControllerApiFp(configuration).publisherIsLoggedIn(options)(fetch, basePath);
        },
    };
};

/**
 * InfoControllerApi - object-oriented interface
 * @export
 * @class InfoControllerApi
 * @extends {BaseAPI}
 */
export class InfoControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public managerIsLoggedIn(options?: any) {
        return InfoControllerApiFp(this.configuration).managerIsLoggedIn(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public publisherIsLoggedIn(options?: any) {
        return InfoControllerApiFp(this.configuration).publisherIsLoggedIn(options)(this.fetch, this.basePath);
    }

}
/**
 * PartnerAccountControllerApi - fetch parameter creator
 * @export
 */
export const PartnerAccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSite(accountId: number, bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling archiveSite.');
            }
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling archiveSite.');
            }
            const localVarPath = `/api/partner/archive/{accountId}/{bundleId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: RegistrationForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPublisher.');
            }
            const localVarPath = `/api/partner/account/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegistrationForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: BundleCreateForm, accountId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSite.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling createSite.');
            }
            const localVarPath = `/api/partner/account/createSite/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BundleCreateForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(accountId: number, options: any = {}): FetchArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deletePublisher.');
            }
            const localVarPath = `/api/partner/account/delete/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters(options: any = {}): FetchArgs {
            const localVarPath = `/api/partner/account/custom-filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling loginAs.');
            }
            const localVarPath = `/api/partner/customer/login-as/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: UpdateForm, accountId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePublisher.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling updatePublisher.');
            }
            const localVarPath = `/api/partner/account/update/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerAccountControllerApi - functional programming interface
 * @export
 */
export const PartnerAccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSite(accountId: number, bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).archiveSite(accountId, bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: RegistrationForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegistrationResponseAdmin> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).createPublisher(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: BundleCreateForm, accountId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BundleCreateResponseAdmin> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).createSite(body, accountId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(accountId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).deletePublisher(accountId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomFiltersBean> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).getCustomFilters(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseString> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).loginAs(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: UpdateForm, accountId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UpdateResponseAdmin> {
            const localVarFetchArgs = PartnerAccountControllerApiFetchParamCreator(configuration).updatePublisher(body, accountId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PartnerAccountControllerApi - factory interface
 * @export
 */
export const PartnerAccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSite(accountId: number, bundleId: number, options?: any) {
            return PartnerAccountControllerApiFp(configuration).archiveSite(accountId, bundleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: RegistrationForm, options?: any) {
            return PartnerAccountControllerApiFp(configuration).createPublisher(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BundleCreateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: BundleCreateForm, accountId: number, options?: any) {
            return PartnerAccountControllerApiFp(configuration).createSite(body, accountId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(accountId: number, options?: any) {
            return PartnerAccountControllerApiFp(configuration).deletePublisher(accountId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFilters(options?: any) {
            return PartnerAccountControllerApiFp(configuration).getCustomFilters(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs(userId: number, options?: any) {
            return PartnerAccountControllerApiFp(configuration).loginAs(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateForm} body 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: UpdateForm, accountId: number, options?: any) {
            return PartnerAccountControllerApiFp(configuration).updatePublisher(body, accountId, options)(fetch, basePath);
        },
    };
};

/**
 * PartnerAccountControllerApi - object-oriented interface
 * @export
 * @class PartnerAccountControllerApi
 * @extends {BaseAPI}
 */
export class PartnerAccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public archiveSite(accountId: number, bundleId: number, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).archiveSite(accountId, bundleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegistrationForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public createPublisher(body: RegistrationForm, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).createPublisher(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BundleCreateForm} body 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public createSite(body: BundleCreateForm, accountId: number, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).createSite(body, accountId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public deletePublisher(accountId: number, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).deletePublisher(accountId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public getCustomFilters(options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).getCustomFilters(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public loginAs(userId: number, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).loginAs(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateForm} body 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountControllerApi
     */
    public updatePublisher(body: UpdateForm, accountId: number, options?: any) {
        return PartnerAccountControllerApiFp(this.configuration).updatePublisher(body, accountId, options)(this.fetch, this.basePath);
    }

}
/**
 * PartnerSiteControllerApi - fetch parameter creator
 * @export
 */
export const PartnerSiteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite(bundleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bundleId' is not null or undefined
            if (bundleId === null || bundleId === undefined) {
                throw new RequiredError('bundleId','Required parameter bundleId was null or undefined when calling getSite.');
            }
            const localVarPath = `/api/partner/site/getSite/{bundleId}`
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerSiteControllerApi - functional programming interface
 * @export
 */
export const PartnerSiteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite(bundleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = PartnerSiteControllerApiFetchParamCreator(configuration).getSite(bundleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PartnerSiteControllerApi - factory interface
 * @export
 */
export const PartnerSiteControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} bundleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite(bundleId: number, options?: any) {
            return PartnerSiteControllerApiFp(configuration).getSite(bundleId, options)(fetch, basePath);
        },
    };
};

/**
 * PartnerSiteControllerApi - object-oriented interface
 * @export
 * @class PartnerSiteControllerApi
 * @extends {BaseAPI}
 */
export class PartnerSiteControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} bundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerSiteControllerApi
     */
    public getSite(bundleId: number, options?: any) {
        return PartnerSiteControllerApiFp(this.configuration).getSite(bundleId, options)(this.fetch, this.basePath);
    }

}
/**
 * PartnerStatControllerApi - fetch parameter creator
 * @export
 */
export const PartnerStatControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype1(body: FieldChartQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adtype1.');
            }
            const localVarPath = `/api/partner/stat/adtype`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FieldChartQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard1(body: DashboardQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dashboard1.');
            }
            const localVarPath = `/api/partner/stat/dashboard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReportOld(body: BasicReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadAuctionsReportOld.');
            }
            const localVarPath = `/api/partner/stat/download/report/auctions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BasicReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters2(options: any = {}): FetchArgs {
            const localVarPath = `/api/partner/stat/filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportOld(body: BasicReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateAuctionsJsonReportOld.');
            }
            const localVarPath = `/api/partner/stat/generate/report/auctions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BasicReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart1(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBidderFunnelChart1.');
            }
            const localVarPath = `/api/partner/stat/bidders/funnel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime1(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBiddersMetricsByTime1.');
            }
            const localVarPath = `/api/partner/stat/bidders/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart1(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBiddersResponseTimeChart1.');
            }
            const localVarPath = `/api/partner/stat/bidders/responseTime`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart1(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadIncrementalRevenueChart1.');
            }
            const localVarPath = `/api/partner/stat/bidders/incrementalRevenue`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart1(body: DashboardQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadOverviewChart1.');
            }
            const localVarPath = `/api/partner/stat/dashboard/overview`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(body: FieldChartQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling version1.');
            }
            const localVarPath = `/api/partner/stat/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FieldChartQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerStatControllerApi - functional programming interface
 * @export
 */
export const PartnerStatControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype1(body: FieldChartQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdTypeChartData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).adtype1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard1(body: DashboardQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DashboardBean> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).dashboard1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReportOld(body: BasicReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).downloadAuctionsReportOld(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters2(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatFilters> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).filters2(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportOld(body: BasicReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JsonReport> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).generateAuctionsJsonReportOld(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart1(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FunnelChartData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).loadBidderFunnelChart1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime1(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BiddersMetricByTime> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).loadBiddersMetricsByTime1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart1(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<XYChartData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).loadBiddersResponseTimeChart1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart1(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncrementalRevenueChartData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).loadIncrementalRevenueChart1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart1(body: DashboardQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OverviewChartsData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).loadOverviewChart1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(body: FieldChartQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VersionChartData> {
            const localVarFetchArgs = PartnerStatControllerApiFetchParamCreator(configuration).version1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PartnerStatControllerApi - factory interface
 * @export
 */
export const PartnerStatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype1(body: FieldChartQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).adtype1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard1(body: DashboardQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).dashboard1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReportOld(body: BasicReportQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).downloadAuctionsReportOld(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters2(options?: any) {
            return PartnerStatControllerApiFp(configuration).filters2(options)(fetch, basePath);
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportOld(body: BasicReportQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).generateAuctionsJsonReportOld(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart1(body: BidderMetricsQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).loadBidderFunnelChart1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime1(body: BidderMetricsQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).loadBiddersMetricsByTime1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart1(body: BidderMetricsQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).loadBiddersResponseTimeChart1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart1(body: BidderMetricsQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).loadIncrementalRevenueChart1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart1(body: DashboardQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).loadOverviewChart1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(body: FieldChartQuery, options?: any) {
            return PartnerStatControllerApiFp(configuration).version1(body, options)(fetch, basePath);
        },
    };
};

/**
 * PartnerStatControllerApi - object-oriented interface
 * @export
 * @class PartnerStatControllerApi
 * @extends {BaseAPI}
 */
export class PartnerStatControllerApi extends BaseAPI {
    /**
     * 
     * @param {FieldChartQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public adtype1(body: FieldChartQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).adtype1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public dashboard1(body: DashboardQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).dashboard1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BasicReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public downloadAuctionsReportOld(body: BasicReportQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).downloadAuctionsReportOld(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public filters2(options?: any) {
        return PartnerStatControllerApiFp(this.configuration).filters2(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BasicReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public generateAuctionsJsonReportOld(body: BasicReportQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).generateAuctionsJsonReportOld(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public loadBidderFunnelChart1(body: BidderMetricsQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).loadBidderFunnelChart1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public loadBiddersMetricsByTime1(body: BidderMetricsQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).loadBiddersMetricsByTime1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public loadBiddersResponseTimeChart1(body: BidderMetricsQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).loadBiddersResponseTimeChart1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public loadIncrementalRevenueChart1(body: BidderMetricsQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).loadIncrementalRevenueChart1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public loadOverviewChart1(body: DashboardQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).loadOverviewChart1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {FieldChartQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerStatControllerApi
     */
    public version1(body: FieldChartQuery, options?: any) {
        return PartnerStatControllerApiFp(this.configuration).version1(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PaymentsControllerApi - fetch parameter creator
 * @export
 */
export const PaymentsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaymentCreateBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: PaymentCreateBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create2.');
            }
            const localVarPath = `/api/admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentCreateBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePayment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declinePayment.');
            }
            const localVarPath = `/api/admin/payment/decline/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [statuses] 
         * @param {string} [email] 
         * @param {boolean} [createdByAdmin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsByAdmin(statuses?: Array<string>, email?: string, createdByAdmin?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/payments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (createdByAdmin !== undefined) {
                localVarQueryParameter['createdByAdmin'] = createdByAdmin;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pausePayment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pausePayment.');
            }
            const localVarPath = `/api/admin/payment/pause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPausePayment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unPausePayment.');
            }
            const localVarPath = `/api/admin/payment/unpause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsControllerApi - functional programming interface
 * @export
 */
export const PaymentsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaymentCreateBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: PaymentCreateBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = PaymentsControllerApiFetchParamCreator(configuration).create2(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePayment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentsControllerApiFetchParamCreator(configuration).declinePayment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<string>} [statuses] 
         * @param {string} [email] 
         * @param {boolean} [createdByAdmin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsByAdmin(statuses?: Array<string>, email?: string, createdByAdmin?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseTransactionBean> {
            const localVarFetchArgs = PaymentsControllerApiFetchParamCreator(configuration).getPaymentsByAdmin(statuses, email, createdByAdmin, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pausePayment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentsControllerApiFetchParamCreator(configuration).pausePayment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPausePayment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentsControllerApiFetchParamCreator(configuration).unPausePayment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentsControllerApi - factory interface
 * @export
 */
export const PaymentsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PaymentCreateBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: PaymentCreateBean, options?: any) {
            return PaymentsControllerApiFp(configuration).create2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePayment(id: number, options?: any) {
            return PaymentsControllerApiFp(configuration).declinePayment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<string>} [statuses] 
         * @param {string} [email] 
         * @param {boolean} [createdByAdmin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsByAdmin(statuses?: Array<string>, email?: string, createdByAdmin?: boolean, options?: any) {
            return PaymentsControllerApiFp(configuration).getPaymentsByAdmin(statuses, email, createdByAdmin, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pausePayment(id: number, options?: any) {
            return PaymentsControllerApiFp(configuration).pausePayment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPausePayment(id: number, options?: any) {
            return PaymentsControllerApiFp(configuration).unPausePayment(id, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentsControllerApi - object-oriented interface
 * @export
 * @class PaymentsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentsControllerApi extends BaseAPI {
    /**
     * 
     * @param {PaymentCreateBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public create2(body: PaymentCreateBean, options?: any) {
        return PaymentsControllerApiFp(this.configuration).create2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public declinePayment(id: number, options?: any) {
        return PaymentsControllerApiFp(this.configuration).declinePayment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [statuses] 
     * @param {string} [email] 
     * @param {boolean} [createdByAdmin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public getPaymentsByAdmin(statuses?: Array<string>, email?: string, createdByAdmin?: boolean, options?: any) {
        return PaymentsControllerApiFp(this.configuration).getPaymentsByAdmin(statuses, email, createdByAdmin, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public pausePayment(id: number, options?: any) {
        return PaymentsControllerApiFp(this.configuration).pausePayment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public unPausePayment(id: number, options?: any) {
        return PaymentsControllerApiFp(this.configuration).unPausePayment(id, options)(this.fetch, this.basePath);
    }

}
/**
 * ProfileControllerApi - fetch parameter creator
 * @export
 */
export const ProfileControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(options: any = {}): FetchArgs {
            const localVarPath = `/api/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientBlockBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByClient(body: ClientBlockBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling blockStatByClient.');
            }
            const localVarPath = `/api/profile/blockStatByClient`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClientBlockBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(bean: ChangePasswordBean, options: any = {}): FetchArgs {
            // verify required parameter 'bean' is not null or undefined
            if (bean === null || bean === undefined) {
                throw new RequiredError('bean','Required parameter bean was null or undefined when calling changePassword.');
            }
            const localVarPath = `/api/changePassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bean !== undefined) {
                localVarQueryParameter['bean'] = bean;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForEdit(options: any = {}): FetchArgs {
            const localVarPath = `/api/profile/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payments(options: any = {}): FetchArgs {
            const localVarPath = `/api/payments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailConfirmationEmail(options: any = {}): FetchArgs {
            const localVarPath = `/api/requestEmailConfirmation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordResetBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(bean: RequestPasswordResetBean, options: any = {}): FetchArgs {
            // verify required parameter 'bean' is not null or undefined
            if (bean === null || bean === undefined) {
                throw new RequiredError('bean','Required parameter bean was null or undefined when calling requestPasswordReset.');
            }
            const localVarPath = `/api/requestPasswordReset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bean !== undefined) {
                localVarQueryParameter['bean'] = bean;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset1(bean: ResetPasswordBean, options: any = {}): FetchArgs {
            // verify required parameter 'bean' is not null or undefined
            if (bean === null || bean === undefined) {
                throw new RequiredError('bean','Required parameter bean was null or undefined when calling requestPasswordReset1.');
            }
            const localVarPath = `/api/passwordReset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bean !== undefined) {
                localVarQueryParameter['bean'] = bean;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationEmail(options: any = {}): FetchArgs {
            const localVarPath = `/api/profile/resendConfirmationEmail`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactSupportForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactSupportRequest(body: ContactSupportForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendContactSupportRequest.');
            }
            const localVarPath = `/api/support`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactSupportForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uix(options: any = {}): FetchArgs {
            const localVarPath = `/api/uix`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: ProfileForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update1.');
            }
            const localVarPath = `/api/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfileForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfileBean> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).account(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ClientBlockBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByClient(body: ClientBlockBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).blockStatByClient(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangePasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(bean: ChangePasswordBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).changePassword(bean, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForEdit(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfileResponse> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).getForEdit(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentsHistoryBean> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).payments(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailConfirmationEmail(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).requestEmailConfirmationEmail(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RequestPasswordResetBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(bean: RequestPasswordResetBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).requestPasswordReset(bean, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResetPasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset1(bean: ResetPasswordBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).requestPasswordReset1(bean, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationEmail(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).resendConfirmationEmail(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ContactSupportForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactSupportRequest(body: ContactSupportForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).sendContactSupportRequest(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uix(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UIXSettings> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).uix(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ProfileForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: ProfileForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfileResponse> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).update1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(options?: any) {
            return ProfileControllerApiFp(configuration).account(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ClientBlockBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockStatByClient(body: ClientBlockBean, options?: any) {
            return ProfileControllerApiFp(configuration).blockStatByClient(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangePasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(bean: ChangePasswordBean, options?: any) {
            return ProfileControllerApiFp(configuration).changePassword(bean, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForEdit(options?: any) {
            return ProfileControllerApiFp(configuration).getForEdit(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payments(options?: any) {
            return ProfileControllerApiFp(configuration).payments(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailConfirmationEmail(options?: any) {
            return ProfileControllerApiFp(configuration).requestEmailConfirmationEmail(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RequestPasswordResetBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(bean: RequestPasswordResetBean, options?: any) {
            return ProfileControllerApiFp(configuration).requestPasswordReset(bean, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResetPasswordBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset1(bean: ResetPasswordBean, options?: any) {
            return ProfileControllerApiFp(configuration).requestPasswordReset1(bean, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationEmail(options?: any) {
            return ProfileControllerApiFp(configuration).resendConfirmationEmail(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ContactSupportForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactSupportRequest(body: ContactSupportForm, options?: any) {
            return ProfileControllerApiFp(configuration).sendContactSupportRequest(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uix(options?: any) {
            return ProfileControllerApiFp(configuration).uix(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ProfileForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: ProfileForm, options?: any) {
            return ProfileControllerApiFp(configuration).update1(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public account(options?: any) {
        return ProfileControllerApiFp(this.configuration).account(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ClientBlockBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public blockStatByClient(body: ClientBlockBean, options?: any) {
        return ProfileControllerApiFp(this.configuration).blockStatByClient(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ChangePasswordBean} bean 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public changePassword(bean: ChangePasswordBean, options?: any) {
        return ProfileControllerApiFp(this.configuration).changePassword(bean, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public getForEdit(options?: any) {
        return ProfileControllerApiFp(this.configuration).getForEdit(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public payments(options?: any) {
        return ProfileControllerApiFp(this.configuration).payments(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public requestEmailConfirmationEmail(options?: any) {
        return ProfileControllerApiFp(this.configuration).requestEmailConfirmationEmail(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RequestPasswordResetBean} bean 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public requestPasswordReset(bean: RequestPasswordResetBean, options?: any) {
        return ProfileControllerApiFp(this.configuration).requestPasswordReset(bean, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResetPasswordBean} bean 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public requestPasswordReset1(bean: ResetPasswordBean, options?: any) {
        return ProfileControllerApiFp(this.configuration).requestPasswordReset1(bean, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public resendConfirmationEmail(options?: any) {
        return ProfileControllerApiFp(this.configuration).resendConfirmationEmail(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ContactSupportForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public sendContactSupportRequest(body: ContactSupportForm, options?: any) {
        return ProfileControllerApiFp(this.configuration).sendContactSupportRequest(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public uix(options?: any) {
        return ProfileControllerApiFp(this.configuration).uix(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ProfileForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public update1(body: ProfileForm, options?: any) {
        return ProfileControllerApiFp(this.configuration).update1(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PublicApiControllerApi - fetch parameter creator
 * @export
 */
export const PublicApiControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apiKey 
         * @param {number} accountId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adRequests(apiKey: string, accountId: number, startDate: string, endDate: string, options: any = {}): FetchArgs {
            // verify required parameter 'apiKey' is not null or undefined
            if (apiKey === null || apiKey === undefined) {
                throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling adRequests.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling adRequests.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling adRequests.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling adRequests.');
            }
            const localVarPath = `/public-api/adrequests`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiKey !== undefined) {
                localVarQueryParameter['apiKey'] = apiKey;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApiControllerApi - functional programming interface
 * @export
 */
export const PublicApiControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apiKey 
         * @param {number} accountId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adRequests(apiKey: string, accountId: number, startDate: string, endDate: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdRequestsBean> {
            const localVarFetchArgs = PublicApiControllerApiFetchParamCreator(configuration).adRequests(apiKey, accountId, startDate, endDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublicApiControllerApi - factory interface
 * @export
 */
export const PublicApiControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} apiKey 
         * @param {number} accountId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adRequests(apiKey: string, accountId: number, startDate: string, endDate: string, options?: any) {
            return PublicApiControllerApiFp(configuration).adRequests(apiKey, accountId, startDate, endDate, options)(fetch, basePath);
        },
    };
};

/**
 * PublicApiControllerApi - object-oriented interface
 * @export
 * @class PublicApiControllerApi
 * @extends {BaseAPI}
 */
export class PublicApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} apiKey 
     * @param {number} accountId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiControllerApi
     */
    public adRequests(apiKey: string, accountId: number, startDate: string, endDate: string, options?: any) {
        return PublicApiControllerApiFp(this.configuration).adRequests(apiKey, accountId, startDate, endDate, options)(this.fetch, this.basePath);
    }

}
/**
 * PublisherPublicControllerApi - fetch parameter creator
 * @export
 */
export const PublisherPublicControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherCustomFields(body: { [key: string]: string; }, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePublisherCustomFields.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePublisherCustomFields.');
            }
            const localVarPath = `/api/v1/publisher/custom-fields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"{ [key: string]: string; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherPublicControllerApi - functional programming interface
 * @export
 */
export const PublisherPublicControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherCustomFields(body: { [key: string]: string; }, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PublisherPublicControllerApiFetchParamCreator(configuration).updatePublisherCustomFields(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublisherPublicControllerApi - factory interface
 * @export
 */
export const PublisherPublicControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherCustomFields(body: { [key: string]: string; }, id: number, options?: any) {
            return PublisherPublicControllerApiFp(configuration).updatePublisherCustomFields(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * PublisherPublicControllerApi - object-oriented interface
 * @export
 * @class PublisherPublicControllerApi
 * @extends {BaseAPI}
 */
export class PublisherPublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: string; }} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublicControllerApi
     */
    public updatePublisherCustomFields(body: { [key: string]: string; }, id: number, options?: any) {
        return PublisherPublicControllerApiFp(this.configuration).updatePublisherCustomFields(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * RegControllerApi - fetch parameter creator
 * @export
 */
export const RegControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccount.');
            }
            const localVarPath = `/api/admin/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegControllerApi - functional programming interface
 * @export
 */
export const RegControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBean> {
            const localVarFetchArgs = RegControllerApiFetchParamCreator(configuration).createAccount(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RegControllerApi - factory interface
 * @export
 */
export const RegControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any) {
            return RegControllerApiFp(configuration).createAccount(body, options)(fetch, basePath);
        },
    };
};

/**
 * RegControllerApi - object-oriented interface
 * @export
 * @class RegControllerApi
 * @extends {BaseAPI}
 */
export class RegControllerApi extends BaseAPI {
    /**
     * 
     * @param {AccountBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegControllerApi
     */
    public createAccount(body: AccountBean, options?: any) {
        return RegControllerApiFp(this.configuration).createAccount(body, options)(this.fetch, this.basePath);
    }

}
/**
 * RegistrationControllerApi - fetch parameter creator
 * @export
 */
export const RegistrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmation(code: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling emailConfirmation.');
            }
            const localVarPath = `/public-api/emailConfirmation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegistrationForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling register.');
            }
            const localVarPath = `/public-api/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegistrationForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationControllerApi - functional programming interface
 * @export
 */
export const RegistrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmation(code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RedirectView> {
            const localVarFetchArgs = RegistrationControllerApiFetchParamCreator(configuration).emailConfirmation(code, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegistrationForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegistrationResponse> {
            const localVarFetchArgs = RegistrationControllerApiFetchParamCreator(configuration).register(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RegistrationControllerApi - factory interface
 * @export
 */
export const RegistrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmation(code: string, options?: any) {
            return RegistrationControllerApiFp(configuration).emailConfirmation(code, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegistrationForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegistrationForm, options?: any) {
            return RegistrationControllerApiFp(configuration).register(body, options)(fetch, basePath);
        },
    };
};

/**
 * RegistrationControllerApi - object-oriented interface
 * @export
 * @class RegistrationControllerApi
 * @extends {BaseAPI}
 */
export class RegistrationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationControllerApi
     */
    public emailConfirmation(code: string, options?: any) {
        return RegistrationControllerApiFp(this.configuration).emailConfirmation(code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegistrationForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationControllerApi
     */
    public register(body: RegistrationForm, options?: any) {
        return RegistrationControllerApiFp(this.configuration).register(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportControllerApi - fetch parameter creator
 * @export
 */
export const ReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsCsvReportNew(body: ReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadAuctionsCsvReportNew.');
            }
            const localVarPath = `/api/report/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters1(options: any = {}): FetchArgs {
            const localVarPath = `/api/report/filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportNew(body: ReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateAuctionsJsonReportNew.');
            }
            const localVarPath = `/api/report/json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsCsvReportNew(body: ReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).downloadAuctionsCsvReportNew(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatFilters> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).filters1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportNew(body: ReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportRecords> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).generateAuctionsJsonReportNew(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsCsvReportNew(body: ReportQuery, options?: any) {
            return ReportControllerApiFp(configuration).downloadAuctionsCsvReportNew(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters1(options?: any) {
            return ReportControllerApiFp(configuration).filters1(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReportNew(body: ReportQuery, options?: any) {
            return ReportControllerApiFp(configuration).generateAuctionsJsonReportNew(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {ReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public downloadAuctionsCsvReportNew(body: ReportQuery, options?: any) {
        return ReportControllerApiFp(this.configuration).downloadAuctionsCsvReportNew(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public filters1(options?: any) {
        return ReportControllerApiFp(this.configuration).filters1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public generateAuctionsJsonReportNew(body: ReportQuery, options?: any) {
        return ReportControllerApiFp(this.configuration).generateAuctionsJsonReportNew(body, options)(this.fetch, this.basePath);
    }

}
/**
 * RestorePasswordControllerApi - fetch parameter creator
 * @export
 */
export const RestorePasswordControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RestorePasswordActionForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordAction(body: RestorePasswordActionForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling restorePasswordAction.');
            }
            const localVarPath = `/public-api/restorePasswordAction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RestorePasswordActionForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestorePasswordRequestForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordRequest(body: RestorePasswordRequestForm, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling restorePasswordRequest.');
            }
            const localVarPath = `/public-api/restorePasswordRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RestorePasswordRequestForm" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestorePasswordControllerApi - functional programming interface
 * @export
 */
export const RestorePasswordControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RestorePasswordActionForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordAction(body: RestorePasswordActionForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RestorePasswordActionResponse> {
            const localVarFetchArgs = RestorePasswordControllerApiFetchParamCreator(configuration).restorePasswordAction(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RestorePasswordRequestForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordRequest(body: RestorePasswordRequestForm, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RestorePasswordRequestResponse> {
            const localVarFetchArgs = RestorePasswordControllerApiFetchParamCreator(configuration).restorePasswordRequest(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RestorePasswordControllerApi - factory interface
 * @export
 */
export const RestorePasswordControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {RestorePasswordActionForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordAction(body: RestorePasswordActionForm, options?: any) {
            return RestorePasswordControllerApiFp(configuration).restorePasswordAction(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RestorePasswordRequestForm} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePasswordRequest(body: RestorePasswordRequestForm, options?: any) {
            return RestorePasswordControllerApiFp(configuration).restorePasswordRequest(body, options)(fetch, basePath);
        },
    };
};

/**
 * RestorePasswordControllerApi - object-oriented interface
 * @export
 * @class RestorePasswordControllerApi
 * @extends {BaseAPI}
 */
export class RestorePasswordControllerApi extends BaseAPI {
    /**
     * 
     * @param {RestorePasswordActionForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorePasswordControllerApi
     */
    public restorePasswordAction(body: RestorePasswordActionForm, options?: any) {
        return RestorePasswordControllerApiFp(this.configuration).restorePasswordAction(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RestorePasswordRequestForm} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorePasswordControllerApi
     */
    public restorePasswordRequest(body: RestorePasswordRequestForm, options?: any) {
        return RestorePasswordControllerApiFp(this.configuration).restorePasswordRequest(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SitePublicControllerApi - fetch parameter creator
 * @export
 */
export const SitePublicControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCreateV1(body: SiteBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling siteCreateV1.');
            }
            const localVarPath = `/api/v1/site/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SiteBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteDeleteV1(siteId: number, options: any = {}): FetchArgs {
            // verify required parameter 'siteId' is not null or undefined
            if (siteId === null || siteId === undefined) {
                throw new RequiredError('siteId','Required parameter siteId was null or undefined when calling siteDeleteV1.');
            }
            const localVarPath = `/api/v1/site/delete/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByIdV1(siteId: number, options: any = {}): FetchArgs {
            // verify required parameter 'siteId' is not null or undefined
            if (siteId === null || siteId === undefined) {
                throw new RequiredError('siteId','Required parameter siteId was null or undefined when calling siteGetByIdV1.');
            }
            const localVarPath = `/api/v1/site/id/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByKeyV1(siteKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'siteKey' is not null or undefined
            if (siteKey === null || siteKey === undefined) {
                throw new RequiredError('siteKey','Required parameter siteKey was null or undefined when calling siteGetByKeyV1.');
            }
            const localVarPath = `/api/v1/site/key/{siteKey}`
                .replace(`{${"siteKey"}}`, encodeURIComponent(String(siteKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListV1(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/site/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdateV1(body: SiteBean, siteId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling siteUpdateV1.');
            }
            // verify required parameter 'siteId' is not null or undefined
            if (siteId === null || siteId === undefined) {
                throw new RequiredError('siteId','Required parameter siteId was null or undefined when calling siteUpdateV1.');
            }
            const localVarPath = `/api/v1/site/update/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SiteBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitePublicControllerApi - functional programming interface
 * @export
 */
export const SitePublicControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCreateV1(body: SiteBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteCreateV1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteDeleteV1(siteId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteDeleteV1(siteId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByIdV1(siteId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteGetByIdV1(siteId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} siteKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByKeyV1(siteKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteGetByKeyV1(siteKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListV1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SiteBean>> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteListV1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdateV1(body: SiteBean, siteId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SitePublicControllerApiFetchParamCreator(configuration).siteUpdateV1(body, siteId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SitePublicControllerApi - factory interface
 * @export
 */
export const SitePublicControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCreateV1(body: SiteBean, options?: any) {
            return SitePublicControllerApiFp(configuration).siteCreateV1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteDeleteV1(siteId: number, options?: any) {
            return SitePublicControllerApiFp(configuration).siteDeleteV1(siteId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByIdV1(siteId: number, options?: any) {
            return SitePublicControllerApiFp(configuration).siteGetByIdV1(siteId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} siteKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetByKeyV1(siteKey: string, options?: any) {
            return SitePublicControllerApiFp(configuration).siteGetByKeyV1(siteKey, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListV1(options?: any) {
            return SitePublicControllerApiFp(configuration).siteListV1(options)(fetch, basePath);
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdateV1(body: SiteBean, siteId: number, options?: any) {
            return SitePublicControllerApiFp(configuration).siteUpdateV1(body, siteId, options)(fetch, basePath);
        },
    };
};

/**
 * SitePublicControllerApi - object-oriented interface
 * @export
 * @class SitePublicControllerApi
 * @extends {BaseAPI}
 */
export class SitePublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {SiteBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteCreateV1(body: SiteBean, options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteCreateV1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteDeleteV1(siteId: number, options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteDeleteV1(siteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteGetByIdV1(siteId: number, options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteGetByIdV1(siteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} siteKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteGetByKeyV1(siteKey: string, options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteGetByKeyV1(siteKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteListV1(options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteListV1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SiteBean} body 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitePublicControllerApi
     */
    public siteUpdateV1(body: SiteBean, siteId: number, options?: any) {
        return SitePublicControllerApiFp(this.configuration).siteUpdateV1(body, siteId, options)(this.fetch, this.basePath);
    }

}
/**
 * StatControllerApi - fetch parameter creator
 * @export
 */
export const StatControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype(body: FieldChartQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adtype.');
            }
            const localVarPath = `/api/stat/adtype`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FieldChartQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard(body: DashboardQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dashboard.');
            }
            const localVarPath = `/api/stat/dashboard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReport(body: BasicReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadAuctionsReport.');
            }
            const localVarPath = `/api/stat/download/report/auctions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BasicReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters(options: any = {}): FetchArgs {
            const localVarPath = `/api/stat/filters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReport(body: BasicReportQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateAuctionsJsonReport.');
            }
            const localVarPath = `/api/stat/generate/report/auctions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BasicReportQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBidderFunnelChart.');
            }
            const localVarPath = `/api/stat/bidders/funnel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBiddersMetricsByTime.');
            }
            const localVarPath = `/api/stat/bidders/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadBiddersResponseTimeChart.');
            }
            const localVarPath = `/api/stat/bidders/responseTime`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadIncrementalRevenueChart.');
            }
            const localVarPath = `/api/stat/bidders/incrementalRevenue`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart(body: DashboardQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadOverviewChart.');
            }
            const localVarPath = `/api/stat/dashboard/overview`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMCampaignsData(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadUTMCampaignsData.');
            }
            const localVarPath = `/api/stat/utm/campaigns`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMChartData(body: BidderMetricsQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadUTMChartData.');
            }
            const localVarPath = `/api/stat/utm/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BidderMetricsQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(body: FieldChartQuery, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling version.');
            }
            const localVarPath = `/api/stat/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FieldChartQuery" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatControllerApi - functional programming interface
 * @export
 */
export const StatControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype(body: FieldChartQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdTypeChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).adtype(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard(body: DashboardQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DashboardBean> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).dashboard(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReport(body: BasicReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).downloadAuctionsReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatFilters> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).filters(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReport(body: BasicReportQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JsonReport> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).generateAuctionsJsonReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FunnelChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadBidderFunnelChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BiddersMetricByTime> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadBiddersMetricsByTime(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<XYChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadBiddersResponseTimeChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncrementalRevenueChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadIncrementalRevenueChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart(body: DashboardQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OverviewChartsData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadOverviewChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMCampaignsData(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UTMChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadUTMCampaignsData(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMChartData(body: BidderMetricsQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UTMChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).loadUTMChartData(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(body: FieldChartQuery, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VersionChartData> {
            const localVarFetchArgs = StatControllerApiFetchParamCreator(configuration).version(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatControllerApi - factory interface
 * @export
 */
export const StatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adtype(body: FieldChartQuery, options?: any) {
            return StatControllerApiFp(configuration).adtype(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboard(body: DashboardQuery, options?: any) {
            return StatControllerApiFp(configuration).dashboard(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuctionsReport(body: BasicReportQuery, options?: any) {
            return StatControllerApiFp(configuration).downloadAuctionsReport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filters(options?: any) {
            return StatControllerApiFp(configuration).filters(options)(fetch, basePath);
        },
        /**
         * 
         * @param {BasicReportQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAuctionsJsonReport(body: BasicReportQuery, options?: any) {
            return StatControllerApiFp(configuration).generateAuctionsJsonReport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBidderFunnelChart(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadBidderFunnelChart(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersMetricsByTime(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadBiddersMetricsByTime(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBiddersResponseTimeChart(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadBiddersResponseTimeChart(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncrementalRevenueChart(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadIncrementalRevenueChart(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOverviewChart(body: DashboardQuery, options?: any) {
            return StatControllerApiFp(configuration).loadOverviewChart(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMCampaignsData(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadUTMCampaignsData(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BidderMetricsQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUTMChartData(body: BidderMetricsQuery, options?: any) {
            return StatControllerApiFp(configuration).loadUTMChartData(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {FieldChartQuery} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(body: FieldChartQuery, options?: any) {
            return StatControllerApiFp(configuration).version(body, options)(fetch, basePath);
        },
    };
};

/**
 * StatControllerApi - object-oriented interface
 * @export
 * @class StatControllerApi
 * @extends {BaseAPI}
 */
export class StatControllerApi extends BaseAPI {
    /**
     * 
     * @param {FieldChartQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public adtype(body: FieldChartQuery, options?: any) {
        return StatControllerApiFp(this.configuration).adtype(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public dashboard(body: DashboardQuery, options?: any) {
        return StatControllerApiFp(this.configuration).dashboard(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BasicReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public downloadAuctionsReport(body: BasicReportQuery, options?: any) {
        return StatControllerApiFp(this.configuration).downloadAuctionsReport(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public filters(options?: any) {
        return StatControllerApiFp(this.configuration).filters(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BasicReportQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public generateAuctionsJsonReport(body: BasicReportQuery, options?: any) {
        return StatControllerApiFp(this.configuration).generateAuctionsJsonReport(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadBidderFunnelChart(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadBidderFunnelChart(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadBiddersMetricsByTime(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadBiddersMetricsByTime(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadBiddersResponseTimeChart(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadBiddersResponseTimeChart(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadIncrementalRevenueChart(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadIncrementalRevenueChart(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadOverviewChart(body: DashboardQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadOverviewChart(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadUTMCampaignsData(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadUTMCampaignsData(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BidderMetricsQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public loadUTMChartData(body: BidderMetricsQuery, options?: any) {
        return StatControllerApiFp(this.configuration).loadUTMChartData(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {FieldChartQuery} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    public version(body: FieldChartQuery, options?: any) {
        return StatControllerApiFp(this.configuration).version(body, options)(this.fetch, this.basePath);
    }

}
/**
 * StatFilterControllerApi - fetch parameter creator
 * @export
 */
export const StatFilterControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/ad-unit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/bidder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredCustomFilters(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/custom`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSiteValues(search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/site`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/size`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/filter/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['siteIds'] = siteIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatFilterControllerApi - functional programming interface
 * @export
 */
export const StatFilterControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredAdUnitValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredBidderValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredCustomFilters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomFilterBean>> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredCustomFilters(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSiteValues(search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredSiteValues(search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredSizeValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLabelValueBean> {
            const localVarFetchArgs = StatFilterControllerApiFetchParamCreator(configuration).filteredVersionValues(siteIds, search, page, count, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatFilterControllerApi - factory interface
 * @export
 */
export const StatFilterControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return StatFilterControllerApiFp(configuration).filteredAdUnitValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return StatFilterControllerApiFp(configuration).filteredBidderValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredCustomFilters(options?: any) {
            return StatFilterControllerApiFp(configuration).filteredCustomFilters(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSiteValues(search?: string, page?: number, count?: number, options?: any) {
            return StatFilterControllerApiFp(configuration).filteredSiteValues(search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredSizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return StatFilterControllerApiFp(configuration).filteredSizeValues(siteIds, search, page, count, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [siteIds] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
            return StatFilterControllerApiFp(configuration).filteredVersionValues(siteIds, search, page, count, options)(fetch, basePath);
        },
    };
};

/**
 * StatFilterControllerApi - object-oriented interface
 * @export
 * @class StatFilterControllerApi
 * @extends {BaseAPI}
 */
export class StatFilterControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredAdUnitValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredAdUnitValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredBidderValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredBidderValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredCustomFilters(options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredCustomFilters(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredSiteValues(search?: string, page?: number, count?: number, options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredSiteValues(search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredSizeValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredSizeValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [siteIds] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatFilterControllerApi
     */
    public filteredVersionValues(siteIds?: Array<number>, search?: string, page?: number, count?: number, options?: any) {
        return StatFilterControllerApiFp(this.configuration).filteredVersionValues(siteIds, search, page, count, options)(this.fetch, this.basePath);
    }

}
/**
 * StatReportControllerApi - fetch parameter creator
 * @export
 */
export const StatReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportKeys(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/report/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMetrics(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/report/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatReportControllerApi - functional programming interface
 * @export
 */
export const StatReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FilterBean>> {
            const localVarFetchArgs = StatReportControllerApiFetchParamCreator(configuration).getReportKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMetrics(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FilterBean>> {
            const localVarFetchArgs = StatReportControllerApiFetchParamCreator(configuration).getReportMetrics(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatReportControllerApi - factory interface
 * @export
 */
export const StatReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportKeys(options?: any) {
            return StatReportControllerApiFp(configuration).getReportKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMetrics(options?: any) {
            return StatReportControllerApiFp(configuration).getReportMetrics(options)(fetch, basePath);
        },
    };
};

/**
 * StatReportControllerApi - object-oriented interface
 * @export
 * @class StatReportControllerApi
 * @extends {BaseAPI}
 */
export class StatReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatReportControllerApi
     */
    public getReportKeys(options?: any) {
        return StatReportControllerApiFp(this.configuration).getReportKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatReportControllerApi
     */
    public getReportMetrics(options?: any) {
        return StatReportControllerApiFp(this.configuration).getReportMetrics(options)(this.fetch, this.basePath);
    }

}
/**
 * SupportControllerApi - fetch parameter creator
 * @export
 */
export const SupportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAgencyStringIdToAccounts(options: any = {}): FetchArgs {
            const localVarPath = `/support/migrate-string-id-to-accounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionCheck(options: any = {}): FetchArgs {
            const localVarPath = `/support/connection-check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionImpl(options: any = {}): FetchArgs {
            const localVarPath = `/support/connection-impl`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillAccountNames(options: any = {}): FetchArgs {
            const localVarPath = `/support/fill-account-names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyStringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdminsToManagers(agencyStringId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agencyStringId' is not null or undefined
            if (agencyStringId === null || agencyStringId === undefined) {
                throw new RequiredError('agencyStringId','Required parameter agencyStringId was null or undefined when calling migrateAdminsToManagers.');
            }
            const localVarPath = `/support/migrate-admins-to-managers/{agencyStringId}`
                .replace(`{${"agencyStringId"}}`, encodeURIComponent(String(agencyStringId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportControllerApi - functional programming interface
 * @export
 */
export const SupportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAgencyStringIdToAccounts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = SupportControllerApiFetchParamCreator(configuration).addAgencyStringIdToAccounts(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = SupportControllerApiFetchParamCreator(configuration).connectionCheck(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionImpl(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SupportControllerApiFetchParamCreator(configuration).connectionImpl(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillAccountNames(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = SupportControllerApiFetchParamCreator(configuration).fillAccountNames(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} agencyStringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdminsToManagers(agencyStringId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SupportControllerApiFetchParamCreator(configuration).migrateAdminsToManagers(agencyStringId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SupportControllerApi - factory interface
 * @export
 */
export const SupportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAgencyStringIdToAccounts(options?: any) {
            return SupportControllerApiFp(configuration).addAgencyStringIdToAccounts(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionCheck(options?: any) {
            return SupportControllerApiFp(configuration).connectionCheck(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionImpl(options?: any) {
            return SupportControllerApiFp(configuration).connectionImpl(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillAccountNames(options?: any) {
            return SupportControllerApiFp(configuration).fillAccountNames(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} agencyStringId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdminsToManagers(agencyStringId: string, options?: any) {
            return SupportControllerApiFp(configuration).migrateAdminsToManagers(agencyStringId, options)(fetch, basePath);
        },
    };
};

/**
 * SupportControllerApi - object-oriented interface
 * @export
 * @class SupportControllerApi
 * @extends {BaseAPI}
 */
export class SupportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApi
     */
    public addAgencyStringIdToAccounts(options?: any) {
        return SupportControllerApiFp(this.configuration).addAgencyStringIdToAccounts(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApi
     */
    public connectionCheck(options?: any) {
        return SupportControllerApiFp(this.configuration).connectionCheck(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApi
     */
    public connectionImpl(options?: any) {
        return SupportControllerApiFp(this.configuration).connectionImpl(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApi
     */
    public fillAccountNames(options?: any) {
        return SupportControllerApiFp(this.configuration).fillAccountNames(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} agencyStringId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApi
     */
    public migrateAdminsToManagers(agencyStringId: string, options?: any) {
        return SupportControllerApiFp(this.configuration).migrateAdminsToManagers(agencyStringId, options)(this.fetch, this.basePath);
    }

}
/**
 * TeamControllerApi - fetch parameter creator
 * @export
 */
export const TeamControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(teamId: number, options: any = {}): FetchArgs {
            // verify required parameter 'teamId' is not null or undefined
            if (teamId === null || teamId === undefined) {
                throw new RequiredError('teamId','Required parameter teamId was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/team/{teamId}`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(bean: TeamUpdateBean, options: any = {}): FetchArgs {
            // verify required parameter 'bean' is not null or undefined
            if (bean === null || bean === undefined) {
                throw new RequiredError('bean','Required parameter bean was null or undefined when calling create.');
            }
            const localVarPath = `/api/team`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bean !== undefined) {
                localVarQueryParameter['bean'] = bean;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(teamId: number, options: any = {}): FetchArgs {
            // verify required parameter 'teamId' is not null or undefined
            if (teamId === null || teamId === undefined) {
                throw new RequiredError('teamId','Required parameter teamId was null or undefined when calling get.');
            }
            const localVarPath = `/api/team/{teamId}`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(request: ListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling list1.');
            }
            const localVarPath = `/api/team`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (request !== undefined) {
                localVarQueryParameter['request'] = request;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(bean: TeamUpdateBean, options: any = {}): FetchArgs {
            // verify required parameter 'bean' is not null or undefined
            if (bean === null || bean === undefined) {
                throw new RequiredError('bean','Required parameter bean was null or undefined when calling update.');
            }
            const localVarPath = `/api/team/{teamId}`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bean !== undefined) {
                localVarQueryParameter['bean'] = bean;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(teamId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TeamControllerApiFetchParamCreator(configuration)._delete(teamId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(bean: TeamUpdateBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TeamControllerApiFetchParamCreator(configuration).create(bean, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(teamId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseTeamDetailsBean> {
            const localVarFetchArgs = TeamControllerApiFetchParamCreator(configuration).get(teamId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(request: ListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseTeamRowBean> {
            const localVarFetchArgs = TeamControllerApiFetchParamCreator(configuration).list1(request, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(bean: TeamUpdateBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TeamControllerApiFetchParamCreator(configuration).update(bean, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(teamId: number, options?: any) {
            return TeamControllerApiFp(configuration)._delete(teamId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(bean: TeamUpdateBean, options?: any) {
            return TeamControllerApiFp(configuration).create(bean, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(teamId: number, options?: any) {
            return TeamControllerApiFp(configuration).get(teamId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(request: ListRequest, options?: any) {
            return TeamControllerApiFp(configuration).list1(request, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TeamUpdateBean} bean 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(bean: TeamUpdateBean, options?: any) {
            return TeamControllerApiFp(configuration).update(bean, options)(fetch, basePath);
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public _delete(teamId: number, options?: any) {
        return TeamControllerApiFp(this.configuration)._delete(teamId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TeamUpdateBean} bean 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public create(bean: TeamUpdateBean, options?: any) {
        return TeamControllerApiFp(this.configuration).create(bean, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public get(teamId: number, options?: any) {
        return TeamControllerApiFp(this.configuration).get(teamId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ListRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public list1(request: ListRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).list1(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TeamUpdateBean} bean 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public update(bean: TeamUpdateBean, options?: any) {
        return TeamControllerApiFp(this.configuration).update(bean, options)(this.fetch, this.basePath);
    }

}
/**
 * TestDataControllerApi - fetch parameter creator
 * @export
 */
export const TestDataControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TestDataBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser(body: TestDataBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateTestDataForUser.');
            }
            const localVarPath = `/api/admin/generate-test-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TestDataBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} amountMin 
         * @param {number} amountMax 
         * @param {number} bidRateMin 
         * @param {number} bidRateMax 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser1(userId: number, amountMin: number, amountMax: number, bidRateMin: number, bidRateMax: number, dateStart: string, dateEnd: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'amountMin' is not null or undefined
            if (amountMin === null || amountMin === undefined) {
                throw new RequiredError('amountMin','Required parameter amountMin was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'amountMax' is not null or undefined
            if (amountMax === null || amountMax === undefined) {
                throw new RequiredError('amountMax','Required parameter amountMax was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'bidRateMin' is not null or undefined
            if (bidRateMin === null || bidRateMin === undefined) {
                throw new RequiredError('bidRateMin','Required parameter bidRateMin was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'bidRateMax' is not null or undefined
            if (bidRateMax === null || bidRateMax === undefined) {
                throw new RequiredError('bidRateMax','Required parameter bidRateMax was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'dateStart' is not null or undefined
            if (dateStart === null || dateStart === undefined) {
                throw new RequiredError('dateStart','Required parameter dateStart was null or undefined when calling generateTestDataForUser1.');
            }
            // verify required parameter 'dateEnd' is not null or undefined
            if (dateEnd === null || dateEnd === undefined) {
                throw new RequiredError('dateEnd','Required parameter dateEnd was null or undefined when calling generateTestDataForUser1.');
            }
            const localVarPath = `/api/admin/generate-demo-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (amountMin !== undefined) {
                localVarQueryParameter['amountMin'] = amountMin;
            }

            if (amountMax !== undefined) {
                localVarQueryParameter['amountMax'] = amountMax;
            }

            if (bidRateMin !== undefined) {
                localVarQueryParameter['bidRateMin'] = bidRateMin;
            }

            if (bidRateMax !== undefined) {
                localVarQueryParameter['bidRateMax'] = bidRateMax;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['dateEnd'] = dateEnd;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial(dateStart: string, dateEnd: string, options: any = {}): FetchArgs {
            // verify required parameter 'dateStart' is not null or undefined
            if (dateStart === null || dateStart === undefined) {
                throw new RequiredError('dateStart','Required parameter dateStart was null or undefined when calling generateTestDataPartial.');
            }
            // verify required parameter 'dateEnd' is not null or undefined
            if (dateEnd === null || dateEnd === undefined) {
                throw new RequiredError('dateEnd','Required parameter dateEnd was null or undefined when calling generateTestDataPartial.');
            }
            const localVarPath = `/api/admin/generate-demo-data-partial`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['dateEnd'] = dateEnd;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial1(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/generate-demo-data-now`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestDataControllerApi - functional programming interface
 * @export
 */
export const TestDataControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TestDataBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser(body: TestDataBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TestDataControllerApiFetchParamCreator(configuration).generateTestDataForUser(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} amountMin 
         * @param {number} amountMax 
         * @param {number} bidRateMin 
         * @param {number} bidRateMax 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser1(userId: number, amountMin: number, amountMax: number, bidRateMin: number, bidRateMax: number, dateStart: string, dateEnd: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TestDataControllerApiFetchParamCreator(configuration).generateTestDataForUser1(userId, amountMin, amountMax, bidRateMin, bidRateMax, dateStart, dateEnd, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial(dateStart: string, dateEnd: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TestDataControllerApiFetchParamCreator(configuration).generateTestDataPartial(dateStart, dateEnd, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TestDataControllerApiFetchParamCreator(configuration).generateTestDataPartial1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TestDataControllerApi - factory interface
 * @export
 */
export const TestDataControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {TestDataBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser(body: TestDataBean, options?: any) {
            return TestDataControllerApiFp(configuration).generateTestDataForUser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} amountMin 
         * @param {number} amountMax 
         * @param {number} bidRateMin 
         * @param {number} bidRateMax 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataForUser1(userId: number, amountMin: number, amountMax: number, bidRateMin: number, bidRateMax: number, dateStart: string, dateEnd: string, options?: any) {
            return TestDataControllerApiFp(configuration).generateTestDataForUser1(userId, amountMin, amountMax, bidRateMin, bidRateMax, dateStart, dateEnd, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} dateStart 
         * @param {string} dateEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial(dateStart: string, dateEnd: string, options?: any) {
            return TestDataControllerApiFp(configuration).generateTestDataPartial(dateStart, dateEnd, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestDataPartial1(options?: any) {
            return TestDataControllerApiFp(configuration).generateTestDataPartial1(options)(fetch, basePath);
        },
    };
};

/**
 * TestDataControllerApi - object-oriented interface
 * @export
 * @class TestDataControllerApi
 * @extends {BaseAPI}
 */
export class TestDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {TestDataBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestDataControllerApi
     */
    public generateTestDataForUser(body: TestDataBean, options?: any) {
        return TestDataControllerApiFp(this.configuration).generateTestDataForUser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} amountMin 
     * @param {number} amountMax 
     * @param {number} bidRateMin 
     * @param {number} bidRateMax 
     * @param {string} dateStart 
     * @param {string} dateEnd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestDataControllerApi
     */
    public generateTestDataForUser1(userId: number, amountMin: number, amountMax: number, bidRateMin: number, bidRateMax: number, dateStart: string, dateEnd: string, options?: any) {
        return TestDataControllerApiFp(this.configuration).generateTestDataForUser1(userId, amountMin, amountMax, bidRateMin, bidRateMax, dateStart, dateEnd, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} dateStart 
     * @param {string} dateEnd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestDataControllerApi
     */
    public generateTestDataPartial(dateStart: string, dateEnd: string, options?: any) {
        return TestDataControllerApiFp(this.configuration).generateTestDataPartial(dateStart, dateEnd, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestDataControllerApi
     */
    public generateTestDataPartial1(options?: any) {
        return TestDataControllerApiFp(this.configuration).generateTestDataPartial1(options)(this.fetch, this.basePath);
    }

}
